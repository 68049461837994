<template>
    <div class="validation">
                <h1>{{state.lang.account.validationTitle}}</h1>
                <div class="explanation">
                    <p>{{state.lang.account.validationHelp1}}</p>
                    <p>{{state.lang.account.validationHelp2}}</p>
                </div>
                <div class="explanation handwrite">
                    <p>{{state.lang.account.validationHandWrite1}}</p>
                    <p>{{state.lang.account.validationHandWrite2}}</p>
                    <p>{{state.lang.account.validationHandWrite3}}</p>
                    <p>{{state.lang.account.validationHandWrite4}}</p>
                </div>
                <div class="pics">
                    <img class="example" src="../../../assets/validation/validationexample.png" alt="">
                    <img class="example" src="../../../assets/validation/wrongvalidationpic.png" alt="">
                    <form
                        @submit.prevent="upload"
                        @dragover.prevent
                        @dragenter="hovered=!hovered"
                        @dragleave="hovered=!hovered"
                        @drop.stop.prevent="hovered=false;drop($event);"
                    >
                        <div class="upload " >
                            <!-- <img v-if="imgSell != ''" :src='imgSell' alt="" class="uploaded-img" > -->
                            <img v-if="path" class="cloud" :src="path" alt="">
                            <img v-else class="cloud" src="../../../assets/validation/cloud.png" alt="" >
                            <label v-if="!readonly" for="upload-file" class="upload-btn"> {{state.lang.account.picUploadBtn}}
                            </label>
                            <input 
                            id="uploa-file" 
                            name="picture"
                            @change="upload($event.target)"
                            :disabled="readonly"
                            v-if="!readonly"
                            type="file">
                            </div>
                        <!-- <div class="upload " >
                                <img v-if="imgSell != ''" :src='imgSell' alt="" class="uploaded-img" > 
                                <img v-if="path" class="cloud" :src="path" alt="">
                                <img class="cloud" src="../../../assets/validation/cloud.png" alt="" >
                                <label for="upload-file" class="upload-btn"> آپلود عکس  مدارک
                                </label>
                                <input 
                                    id="uploa-file" 
                                    name="picture"
                                    @change="upload($event.target)"
                                    :disabled="readonly"
                                    v-if="!readonly"
                                    type="file">
                            </div> -->
                    </form>
                </div>
            </div>
</template>

<script>
    export default {
        name:'Validation',
         props:['blanckspace','data'],
         data(){
             return{
                 path: '',
                loadedpicture: '',
                hovered: '',
             }
         },
         watch: {
            'data.image'() {
                if (this.data.image)
                    if (typeof this.data.image === 'string')
                        this.path = this.data.image
            }
        },
        computed: {
            readonly() {
                return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
            }
        },
        methods: {
            drop(e) {
                let dt = e.dataTransfer
                this.upload(dt)
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file)
            },
            hover() {
                this.hovered = true
            }
        }
    }
</script>

<style lang="scss" scoped>
form{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.validation{
    h1{

       color: rgba(64, 64, 64, 1);
    }
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
    .handwrite{
        color: var(--secondary-color) !important;
    }
    .explanation{
        width: 80%;
        color:rgba(63, 63, 63, 1);
    }
    .pics{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;


        .upload {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 8px;
        // background: #2F3239;
        // border-radius: 5px;
        // border: 1px dashed #FF9C07;
        position: relative;
        img{
            width: 100%;
        }
        
        .upload-btn {
        width: 100%;
        height: 40px;
        background: var(--secondary-color);
        border-radius: 4px;
        border: none;
        font-weight: 700;
        font-size: clamp(12px,1vw,14px);
        position: relative;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
        #uploa-file {
        opacity: 0;
        width: 100%;
        cursor: pointer;
        position: absolute;
        right: 0;
        height: 100%;
    }
    }
    }

}
@media only screen and(max-width:1200px){
    .pics{
       flex-direction: column !important;
       width: 30% !important;
       align-self: center;
       justify-content: center;
       row-gap: 16px;
       .upload{
           img{
               width: 100%;
           }
           width: 100% !important;
       }
    }
}
@media only screen and(max-width:900px){
    .pics{
       flex-direction: column !important;
       width: 40% !important;
    }
}
@media only screen and(max-width:700px){
    .pics{
       flex-direction: column !important;
       width: 70% !important;
    }
}
@media only screen and(max-width:1200px){
    .pics{
       flex-direction: column !important;
       width: 30% !important;
       align-self: center;
       justify-content: center;
       row-gap: 16px;
       .upload{
           img{
               width: 100%;
           }
           width: 100% !important;
       }
    }
}
</style>