<template>
    <div class="trades">
        <h1>{{state.lang.history.tomandespintsTitle}}</h1>
        <div class="filters">
            <div class="coin-type" style="zIndex:9">    
                        <div @click.self="headeritems.opration.selected=!headeritems.opration.selected" class="chose-coin">
                            <span>{{pages[3].name}}</span>
                            <img :class="{'selected':headeritems.opration.selected}" @click.self="headeritems.opration.selected=!headeritems.opration.selected" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="headeritems.opration.selected==true" class="option-container">    
                                <div @click="headeritems.opration.name=page.name,headeritems.opration.selected=false,chosePage(page.addres)" class="options" v-for="(page,index) in pages" :key="index">
                                    <span>{{page.name}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
            <div class="coin-type" style="zIndex:6">    
                        <div @click.self="despintDrop=!despintDrop" class="chose-coin">
                            <span>  {{selecteddespint==despintTypes[0]? state.lang.history.deposite : state.lang.history.withDrawal }}  </span>
                            <img :class="{'selected':despintDrop}" @click.self="despintDrop=!despintDrop" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="despintDrop==true" class="option-container">    
                                <div @click="changeDespint(index)" class="options" v-for="(despintType,index) in despintTypes" :key="index">
                                        <span> {{despintType==despintTypes[0]? state.lang.history.deposite : state.lang.history.withDrawal}} </span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <td> {{state.lang.despintTable.orderType}} </td>
                        <td> {{state.lang.despintTable.amount}} </td>
                        <td> {{state.lang.despintTable.followId}} </td>
                        <td> {{state.lang.despintTable.time}} </td>
                        <td> {{selecteddespint==despintTypes[1] ? state.lang.account.shebaCode : state.lang.account.cartNumber}} </td>
                        <td> {{state.lang.despintTable.situation}} </td>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="(order,index) in filterOpration" :key="index">
                        <td :class="selecteddespint==despintTypes[0] ? 'positive':'negetive'"> {{selecteddespint==despintTypes[0] ? state.lang.despintTable.deposite :state.lang.despintTable.withDrawal}} </td>
                        <td> {{$toLocal(order.amount,$decimal[order.relatedCoin])}} </td>
                        <td> {{order.hashCode}} </td>
                        <td> {{ state.lan=='Fa' ? $G2J(order.createdAtDateTime) : order.createdAtDateTime}} </td>
                        <td @click="copy(order.destinationWalletAddress)" class="walletaddress"> {{selecteddespint==despintTypes[1] ? order.destinationWalletAddress : order.bankAccount}} </td>
                        <td :class="[{'open':order.depositRequestStatus=='DONE'},{'ispending':order.depositRequestStatus=='PENDING'},{'finished':order.depositRequestStatus=='FAILED' || order.depositRequestStatus=='CANCELLED'}]" v-if="selecteddespint==despintTypes[0]"> {{ state.lan=='En' ? order.depositRequestStatus : situation[order.depositRequestStatus] }} </td>
                        <td :class="[{'open':order.transactionStatus=='ACCEPTED' || order.transactionStatus=='RAISED_BY_USER'},{'finished':order.transactionStatus=='CANCELLED_BY_ADMIN' || order.transactionStatus=='CANCELLED_BY_USER'}]" v-if="selecteddespint==despintTypes[1]"> {{ state.lan=='En' ? order.transactionStatus : situation[order.transactionStatus] }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Despint',
        data () {
            return {
                situation:{
                    "PENDING":'در حال بررسی',
                    "DONE":'انجام شده',
                    "FAILED":'ناموفق',
                    "CANCELLED":'لغو شده',
                    "ACCEPTED":'موفق',
                    "RAISED_BY_USER":'موفق',
                    "CANCELLED_BY_ADMIN":'لغو شده',
                    "CANCELLED_BY_USER":'لغو شده',
                },
                params:{
                    // marketType:'',
                    // market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    sourceCoin:'',
                    destinationCoin:'',
                    orderType:'',
                    type : 'EXCHANGE',
                    page: 1,
                    size: 20
                },
                filterCoin:'',
                withdraList:[],
                depositList:[],
                despintTypes:['deposite','withdrawal'],
                selecteddespint:'withdrawal',
                despintDrop:false,
                selected:false,
                oprations:['All Oprations','BUY','SELL'],
                selected_opration:'All Oprations',
                oprationDrop:false,
                markets:'All Markets',
                selectedMarket:'All Markets',
                marketDrop:false,
                marketType:this.state.lang.history.limit,
                selectedMarketType:'All Types',
                marketTypeDrop:false,
                marketTypes:['Limit','Market'],
                page:0,
                lastPage:1,
                coins:[],
                tableContents:[],
                headeritems:{
                    market:{
                        name:this.state.lang.history.tradesTitle,
                        selected:false,
                    },
                    opration:{
                        name:this.state.lang.history.exchangesTitle,
                        selected:false,
                        },
                    localmarket:{
                        name:this.state.lang.history.despintsTitle,
                        selected:false,
                    },
                    had:{
                        name:this.state.lang.history.loginsTitle,
                        selected:false,
                    },
                },
                pages:[
                    {
                        name:this.state.lang.history.tradesTitle,
                        addres:'Trades',
                    },
                    {
                        name:this.state.lang.history.exchangesTitle,
                        addres:'Exchange',
                    },
                    {
                        name:this.state.lang.history.coindespintsTitle,
                        addres:'Despint',
                    },
                    {
                        name:this.state.lang.history.tomandespintsTitle,
                        addres:'RialDespint',
                    },
                    {
                        name:this.state.lang.history.loginsTitle,
                        addres:'Login',
                    },
                ],

            }
        },
        computed:{
            filterOpration(){
                let filter
                    if (this.selecteddespint==this.despintTypes[0]) {
                        filter=this.depositList;
                    } else{
                        filter=this.withdraList;
                    }

                return filter
            }
        },
        methods : {
            copy(e){
                navigator.clipboard.writeText(e);
            },
            changeWithdraList(coin){
                if(coin=='All Markets'){
                    this.filterCoin=false
                }else{
                    this.filterCoin=coin;
                }
                this.marketDrop=false;
            },
            changeDepositList(coin){
                if(coin=='All Markets'){
                    this.filterCoin=false
                }else{
                    this.filterCoin=coin;
                }
                this.marketDrop=false;
            },
            changeDespint(e){
                this.selecteddespint=this.despintTypes[e];
                this.despintDrop=false
            },
            chosePage(addres) {
                this.$emit('sendcomp', addres)
            },
            async getWithdrawHistory() {
                    const address = 'wallets/withdrawal-requests'
                    // if (this.page <= this.lastPage) { //for scroll loading
                    //     this.page++ //for scroll loading
                        this.state.loading = true
                        const [res, ] = await this.$http.get(address, {
                            params: {
                                size: 1000,
                                // page: this.page
                            }
                        })
                        if (res) {
                            this.withdraList = [...this.withdraList, ...res.content]
                            this.withdraList=this.withdraList.filter(a => a.relatedCoin == 'TOMAN')
                            // this.lastPage = res.totalPages
                            console.log(this.withdraList);
                        }
                        this.state.loading = false
                    // }

                },
            async getDepositHistory() {

                    const address = 'wallets/deposit'
                    // if (this.page2 <= this.lastPage2) { //for scroll loading
                    //     this.page2++ //for scroll loading
                        this.state.loading = true
                        const [res, ] = await this.$http.get(address, {
                            params: {
                                size: 1000,
                                // page2: this.page2
                            }
                        })
                        if (res) {
                            this.depositList = [...this.depositList, ...res.content]
                            this.depositList=this.depositList.filter(a => a.relatedCoin == 'TOMAN')
                            // this.lastPage = res.totalPages
                            console.log(this.depositList);
                        }
                        this.state.loading = false

                    // }
                },
        },
        mounted(){
            this.getWithdrawHistory();
            this.getDepositHistory();
        },
        created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.chose-coin')) {
             this.headeritems.market.selected=false;
             this.headeritems.opration.selected=false;
             this.headeritems.localmarket.selected=false;
             this.oprationDrop=false;
             this.marketDrop=false;
             this.despintDrop=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.open{
    color: var(--positive-color);
    // text-align: end;
}
.ispending{
    color: var(--secondary-color);
    opacity: .7;
    // text-align: end;
}
.finished{
    color: var(--red-color);
    // text-align: end;
}
.coin{
    color: var(--secondary-color);
}
.walletaddress{
    cursor: pointer;
    max-width: 70px;
    overflow: scroll;
}
.disable{
    opacity: .5;
    &:hover{
        cursor: default;
    }
}
.order-channel{
    font-family: 'Yekan-en' !important;
}
.table{
    overflow: scroll;
}
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // justify-content: center;
}
    .coinname{
        color:var(--secondary-color);
    }
table{
    font-weight: 400;
    font-size: clamp(10px,1vw,14px);
    .cointype{
    display: flex;
    flex-direction: row;
    color: #3f3f3f7e;
    // justify-content: center;
    column-gap: 5px;
}
    width: 100%;
    tr{
        height: 36px;
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.coin-type{
        display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    width: 33%;
}
.option-container{
    top: 33px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
                font-weight: 300;
                font-size: clamp(9px,1vw,12px);
            }
    }
            img{
                position: absolute;
                right: 130px;
                top:-3px;
                width: 30px;
                height: 30px;

            }
}
.chose-coin{
    cursor: pointer;
    height: 30px;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    justify-content: space-between;
    width: 100%;
        position: relative;
    border: 0.2px solid #0077cc65;
    border-radius: 4px;
    span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
}
    .selected{
        transform: rotate(180deg);
    }
.trades{
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    row-gap: 16px;
    h1{
        text-align: start !important;
    }
}
.filters{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}
@media only screen and(max-width:850px){
    .filters{
        flex-direction: column;
        row-gap: 16px;
        .coin-type{
            width: 100%;
        }
    }
        .remove{
            display: none;
        }
}
@media only screen and(max-width:700px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove-three{
        display: none;
    }
}
@media only screen and(max-height:400px){
    .table{
        overflow: unset;
    }
}
</style>