<template>
    <div class="chatpage">
        <MessageModal :id="id" @close="modalMessage" v-if="messageModal" />
            <div class="title">
                <h1>{{state.lang.tickets.title}}</h1>
                <div class="btns">
                    <button @click="closeModale" class="submit">{{state.lang.tickets.back}}</button>
                    <button @click="messageModal=true" class="submit">{{state.lang.tickets.answer}}</button>
                </div>
            </div>  
                <div v-for="(text,index) in details" :key="index" :class="{'admin-chats':text.admin}" class="chats">
                    <div class="chat">
                        <div class="titles">
                            <span v-if="!text.admin"> {{state.userInfo.firstName}} {{state.userInfo.lastName}} </span>
                            <span v-if="text.admin"> Admin </span>
                            <!-- <span> {{}} </span> -->
                        </div>
                        <p class="text"> {{text.text}} </p>
                    </div>
                </div>
        </div>
</template>

<script>
import MessageModal from './MessageModal.vue'
    export default {
        name:'Message',
        components:{
            MessageModal
        },
        props:['id'],
        data(){
            return{
                selectedId:null,
                messageModal:false,
                chatPage:false,
                ticketModal:false,
                tickets:[],
                details:[],
            }
        },
        methods:{
            modalMessage(){
                this.messageModal=false
                this.showMessage();
            },
            closeModale() {
                this.$emit('close' , false)
            },
            async showMessage() {
                this.state.loading = true
                const res = await this.$axios.get('/tickets/message/' + this.id)
                this.details = res.pageDTO.content.reverse()
                console.log(this.details);
                // this.reload = !this.reload
                this.state.loading = false
            },
        },
        mounted(){
            window.scrollTo({
                bottom: 0,
                // left: 0,
                // behavior: 'smooth'
                });
            this.showMessage()
        },
    }
</script>

<style lang="scss" scoped>
.submit{
    max-height: 36px;
    width: 10%;
    
}
.btns{
    display: flex;
    width: 23%;
    flex-direction: row;
    column-gap: 16px;
    button{
        width: 50%;
    }
}
.chat{
    background: var(--primary-color);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .text{
        font-size: clamp(10px,1vw,12px);
        color: var(--secondary-color);
        opacity: .6;
    }
    .titles{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
}
.title{
    margin-top: 10px;
    z-index: 5;
            position: sticky;
        top: 0;
        background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between ;
}
.chatpage{
    .admin-chats{
        align-self: flex-end;
        width: 60%;
    }
    .chats{
            width: 60%;
    }
    
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

</style>