<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
              <img v-if="closeable" class="close" src="../../assets/icons/icons8-close.svg" alt="">
              <div class="content">
                <img @click="closeModale" :src=pic alt="">
                <p>{{text}}</p>
              </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Sellmodal',
  data() {
      return {
        
      }
  },
  props:{
      text:String,
      pic:String,
      closeable:Boolean,
      closetime:Number,
  },
  methods : {
      closeModale() {
        this.$emit('closeChargeModaleEmit' , false)
      },
  },
  mounted (){
    if(this.closetime){
      setTimeout(() => {this.closeModale()
      },this.closetime)
    }
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.options,.modal,.submit, .email , .order-btn ,.option')) {
              this.$emit('closeChargeModaleEmit' , false);
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    img{
        max-width: 59px;
        max-height: 43px;
    }
}
.close{
    align-self: flex-start;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 160px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>