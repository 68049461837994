<template>
        <div class="signup">
            <!-- <Simplemodal v-if="state.loading" :closeable="false" :text="'در حال انجام عملیات ...'" :pic="require('../../assets/icons/loading.svg')" /> -->
        <h1>{{state.lang.login.title}}</h1>
        <div class="forms">
            <form @submit.prevent="send"  action="">
                <label for="">
                    <input @focus="toggleFocus" v-model="data.email" @input="data.email=$p2e(data.email)" class="email" name="email" type="text" required>
                    <span :class="[{'focus':(blanckspace.email)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.login.userName}}</span>
                </label>
                <div v-if="emailError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{emailError}}</p>
                </div>
                <label class="passlabel" for="">
                    <input @focus="blanckspace.password = true" v-model="data.password" @input="data.password=$p2e(data.password)"  :type="passtype" class="password" name="password" required>
                    <span class="password" :class="[{'focus':(blanckspace.password)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.login.passWord}} </span>
                    <img v-if="passtype=='password'" :class="[state.lan=='En'?'img-en':'img-fa']" @click="passtype='text'"  src="../../assets/icons/noun_Eye_4382116.svg" alt="">
                    <img v-if="passtype=='text'" :class="[state.lan=='En'?'img-en':'img-fa']" @click="passtype='password'"  src="../../assets/icons/seepassword.svg" alt="">
                </label>
                <div v-if="passError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{passError}}</p>
                </div>
                <div class="recaptcha">
                    <a @click.prevent="$router.push({name:'Forgotpass'})" href="/Forgot-pass"> {{state.lang.login.forgetPass}} </a>
                     <!-- <img src="../../assets/forms/captcha-code2 1.png" alt=""> -->
                </div>
                <!-- <label class="passlabel" for="">
                    <input @focus="blanckspace.recaptcha = true" v-model="data.recaptcha" type="text" name="recaptcha">
                    <span :class="{'focus':(blanckspace.recaptcha)}"> کد امنیتی </span>
                    <img src="../../assets/icons/iconoir_refresh.svg" alt="">
                </label> -->
                <button :disabled="!data.email || !data.password"  class="submit"> {{state.lang.login.loginBtn}} </button>
                <button @click.prevent="googleSso" class="googlelink">{{state.lang.login.googleBtn}}</button>
                <p class="loginpage"> {{state.lang.login.signUpTitle}} <a href="/signup">{{state.lang.login.signUpLink}}</a>  </p>
            </form>
        </div>
        </div>
</template>

<script>
// import Simplemodal from '../../components/Tools/Simplemodal.vue'
const goto = {
        EMAIL: '/Login-otp-email',
        SMS: '/Login-otp-mobile',
        GOOGLE_AUTHENTICATOR: '/Login-otp-google',
    }
    export default {
        name : 'Login',
        // components:{
        //     Simplemodal,
        // },

        data () {
            return{
                loading:true,
                passtype : 'password' ,
                termscheck : false ,
                unfocus : true,
                blanckspace : {
                    email : false,
                    password : false,
                    invitecode : false,
                    recaptcha : false
                },
                data: {
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                },
                emailError: '',
                passError: '',

            }
        },
        methods : {
            toggleVisible () {
                this.visibality=!this.visibality
            },
            toggleCheck () {
                this.termscheck = !this.termscheck
            },
            handleSubmit() {
                },
            toggleFocus () {
                this.blanckspace.email=true ;
                this.unfocus=true;
            },
             async check() {
                this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/get-google-token?browser=${this.data.browser}&osType=${this.data.osType}&deviceType=${this.data.deviceType}&code=${this.$route.query.code}`)
                    this.$setToken(res.token)
                    await this.$router.push({name:'Dashboard'})
                } catch (err) {
                    if (err.response.data.message === "GOOGLE_FAILURE") {
                        await this.$error('خطای ورود، به صفحه قبل منتقل میشوید', '')
                        this.$router.push({name: 'Login'})

                    }
                }
            },
            googleSso() {
                window.location.href = this.$google_sso
            },
            async send() {
                await this.checkEmail();
                // await this.check2Pass();
                this.state.loading = true
                const res = await this.$axios.post('/users/login', this.data)
                if (res.message === 'login successful, otp is required') {
                    
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';

                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
                    // await this.$router.push('/Login-otp-email')

                }else if (res.token){
                    this.$setToken(res.token,this.state.userInfo.remember)
                    await this.$router.push({name: 'Validation'})
                }

            },
            async checkEmail() {
                if (!this.$validEmail(this.data.email) || !this.data.email) {
                    this.emailError = 'ایمیل صحیح وارد نمایید'
                    // this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    return Promise.reject('repeat failed : ' + this.data.email)
                }else{
                    this.emailError = ''
                }
            },
            async check2Pass() {
                if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                    this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                    this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    return Promise.reject('wrong pass : ' + this.data.password)
                }else{
                    this.passError = ''
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
        },
        mounted(){
            this.getData()
            if(this.$route.query.ref || localStorage.referralCode){
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        }
    }
</script>

<style lang="scss" scoped>

.loginpage{
    text-align: center;
    font-weight: 400;
    font-size: clamp(9px,1vw,13px);
    a{
        font-size: clamp(10px,1.5vw,12px);
    }
}
.googlelink{
    background: var(--primary-color);
    color: var(--secondary-color);
    border: 0.2px solid #0077cc59;
    font-weight: 700;
    font-size: clamp(10px,1vw,14px);
    cursor: pointer;
}
label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 5px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
.signup{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    
    // max-width: 336px;
    row-gap: 24px;
    width: 30%;
    height: 80%;
}

.recaptcha{
    display: flex;
    row-gap: 16px;
    align-items: center;
    flex-direction: column;
    a{
        width: 100%;
        text-align: end;
        margin-top: -8px;
        font-size: clamp(10px,1.5vw,12px);
        &:hover{
            transform: none;
        }
    }
}
.forms{

    form{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

}
.passlabel{
    img{
        position: absolute;
        bottom: 10px;
        left: 15px;
        max-width: 16px;
        max-height: 16px;
        cursor: pointer;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
// @media only screen and(max-width:900px){

//     .signup{
//         width: 40%;
        
//     }

// }
// @media only screen and(max-width:700px){
    

//     .signup{
//         width: 60%;
        
//     }

// }
@media only screen and(max-width:450px){

    .signup{
        width: 100% !important;
       padding: 0 20px;
        
    }

}
@media only screen and(max-width:850px){

    .signup{
        width: 70%;
       padding: 0 20px;
        
    }

}
    </style>