<template>
    <div style="height:100%;">
        <RemoveOrderConfirmModal :id="id" v-if="show" @close="show= false" @cancel="removeorder(id)" />
        <div class="activeorders">
            <div class="header">
                    <div class="coin-type">    
                        <img v-if="!allOrders" class="coin-icon" :src="'/coins/' +headercoin.coin+ '.png'" alt="">
                        <div @click="handleToggle" class="chose-coin">
                            <span v-if="allOrders"> {{headercoin.coin}} </span>
                            <span v-if="state.lan=='Fa' && !allOrders">{{$coinLabel[headercoin.coin]}}</span>
                            <span v-if="state.lan=='En' && !allOrders">{{headercoin.coin}}</span>
                            <img :class="{'selected':selected}" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="selected==true" class="option-container">    
                                <div @click="allGetActiveOrders" class="options">
                                    <span> {{state.lang.activeOrdersProMarektTable.allOrders}} </span>
                                </div>
                                <div @click="choseCoin(index)" class="options" v-for="(coin,index) in coins" :key="index">
                                    <span v-if="state.lan=='Fa'">{{coin.persianName}}</span>
                                    <span v-if="state.lan=='En'">{{coin.coin}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
                    <div v-if="!allOrders" class="changepricetype">
                        <button @click.prevent="changeCoinTo('TOMAN')" :class="[state.lan=='Fa' ? 'btn-left':'btn-right',{'submit':coinTo=='TOMAN'}]" class="pricebtn"> {{state.lang.activeOrdersProMarektTable.toman}} </button>
                        <button @click.prevent="changeCoinTo('TETHER')" :class="[state.lan=='Fa' ? 'btn-right':'btn-left',{'submit':coinTo=='TETHER'}]" class="pricebtn"> {{state.lang.activeOrdersProMarektTable.tether}} </button>
                    </div>
                <!-- <div class="coin-info">
                    <table>
                        <thead>
                            <tr class="header-item">
                                <td>{{state.lang.activeOrdersHeaderTable.amount}}</td>
                                <td>{{state.lang.activeOrdersHeaderTable.value}}</td>
                                <td class="remove-two">{{state.lang.activeOrdersHeaderTable.price}}</td>
                                <td class="positive">{{state.lang.activeOrdersHeaderTable.changes}}</td>
                                <td>{{state.lang.activeOrdersHeaderTable.inDeal}}</td>
                                <td class="remove">{{state.lang.activeOrdersHeaderTable.time}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{headercoin.remain}}</td>
                                <td>{{headercoin.value.toLocaleString()}}</td>
                                <td class="remove-two">{{headercoin.price.toLocaleString()}}</td>
                                <td v-if="headercoin.variant<0" class="negetive">{{headercoin.variant}}%-</td>
                                <td v-if="headercoin.variant>0" class="positive">{{headercoin.variant}}%+</td>
                                <td>{{headercoin.indeal}}</td>
                                <td class="remove">
                                    <div class="headertime">
                                        <span>{{headercoin.hours}}:{{headercoin.minute}}</span>
                                        <span>-</span>
                                        <span>{{headercoin.year}}/{{headercoin.mounth}}/{{headercoin.day}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>
            <div class="table">
                <!-- <div class="btn">
                    <button @click.prevent="p2p=true" :class="{'submit':p2p}">{{state.lang.activeOrderBtns.proMarket}}</button>
                    <button @click.prevent="p2p=false" :class="{'submit':!p2p}">{{state.lang.activeOrderBtns.market}}</button>
                </div> -->
                <div class="orders">
                    <table>
                        <!-- <thead> -->
                            <tr class="thead">
                                <td class="remove-three" style="textAlign:start;">{{state.lang.activeOrdersProMarektTable.coin}}</td>
                                <td>{{state.lang.activeOrdersProMarektTable.coins}}</td>
                                <td>{{state.lang.activeOrdersProMarektTable.price}}</td>
                                <!-- <td>{{state.lang.activeOrdersProMarektTable.time}}</td> -->
                                <td class="remove-two">{{state.lang.activeOrdersProMarektTable.total}} </td>
                                <td class="remove-three">{{state.lang.activeOrdersProMarektTable.done}}</td>
                                <td class="remove">{{state.lang.activeOrdersProMarektTable.amount}}</td>
                                <td class="remove-two">{{state.lang.activeOrdersProMarektTable.opration}}</td>
                            </tr>
                        <!-- </thead> -->
                        <tbody>
                            <tr v-for="(tableContent,index) in tableContents" :key="index">
                                <td v-if="state.lan=='En' && (tableContent.orderType=='EXCHANGE_SELL' || tableContent.orderType=='LIMITED_SELL')" class="remove-three" style="textAlign:start;"><span class="coinname">{{tableContent.sourceCoin}}</span> ({{tableContent.destinationCoin}})</td>
                                <td v-if="state.lan=='Fa' && (tableContent.orderType=='EXCHANGE_SELL' || tableContent.orderType=='LIMITED_SELL')"  class="remove-three" style="textAlign:start;"><span class="coinname">{{$coinLabel[tableContent.sourceCoin]}}</span> ({{$coinLabel[tableContent.destinationCoin]}})</td>
                                <td v-if="state.lan=='En' && (tableContent.orderType=='EXCHANGE_BUY' || tableContent.orderType=='LIMITED_BUY')" class="remove-three" style="textAlign:start;"><span class="coinname">{{tableContent.destinationCoin}}</span> ({{tableContent.sourceCoin}})</td>
                                <td v-if="state.lan=='Fa' && (tableContent.orderType=='EXCHANGE_BUY' || tableContent.orderType=='LIMITED_BUY')"  class="remove-three" style="textAlign:start;"><span class="coinname">{{$coinLabel[tableContent.destinationCoin]}}</span> ({{$coinLabel[tableContent.sourceCoin]}})</td>
                                <!-- <td :class="{'negetive' : tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL'), 'positive' : tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY') }">
                                    <span v-if="tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL')">
                                        فروش
                                    </span>
                                    <span v-if="tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY')">
                                        خرید
                                    </span>
                                </td> -->
                                <td v-if="tableContent.orderType=='MARKET_SELL'" :class="(tableContent.orderType=='MARKET_SELL' || tableContent.orderType=='LIMITED_SELL') ? 'negetive':'positive'"> {{state.lang.activeOrdersProMarektTable.MARKET_SELL}} </td>
                                <td v-if="tableContent.orderType=='MARKET_BUY'" :class="(tableContent.orderType=='MARKET_SELL' || tableContent.orderType=='LIMITED_SELL') ? 'negetive':'positive'"> {{state.lang.activeOrdersProMarektTable.MARKET_BUY}} </td>
                                <td v-if="tableContent.orderType=='LIMITED_SELL'" :class="(tableContent.orderType=='MARKET_SELL' || tableContent.orderType=='LIMITED_SELL') ? 'negetive':'positive'"> {{state.lang.activeOrdersProMarektTable.LIMITED_SELL}} </td>
                                <td v-if="tableContent.orderType=='LIMITED_BUY'" :class="(tableContent.orderType=='MARKET_SELL' || tableContent.orderType=='LIMITED_SELL') ? 'negetive':'positive'"> {{state.lang.activeOrdersProMarektTable.LIMITED_BUY}} </td>
                                <td class="price-td number-font">
                                    {{$toLocal(tableContent.unitPrice,precision)}}
                                </td>
                                <td class="total-td number-font amount">
                                    {{$toLocal(tableContent.overallValue,precision)}}
                                </td>
                                <td class="remove-three">{{$toLocal(tableContent.executedPercent,precision)}}%</td>
                                <td class="amount-td number-font">
                                    {{$toLocal(tableContent.amount,precision)}}
                                </td>
                                <!-- <td class="remove">
                                    <div class="time">
                                        {{$G2J(tableContent.createdAtDateTime) }}
                                    </div>
                                </td> -->
                                <td @click="openModal(tableContent.id)" class="secondary-color remove-two">{{state.lang.activeOrdersProMarektTable.cancel}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div v-if="!p2p" class="orders">
                    <table>
                        <thead>
                            <tr>
                                <td class="remove-three" style="textAlign:start;">{{state.lang.activeOrdersMarektTable.market}}</td>
                                <td class="remove-three">{{state.lang.activeOrdersMarektTable.coin}}</td>
                                <td>{{state.lang.activeOrdersMarektTable.price}}</td>
                                <td>{{state.lang.activeOrdersMarektTable.amount}}</td>
                                <td class="remove-two">{{state.lang.activeOrdersMarektTable.total}} </td>
                                <td class="remove-two">{{state.lang.activeOrdersMarektTable.channel}} </td>
                                <td>{{state.lang.activeOrdersMarektTable.time}}</td>
                                <td class="remove-two" style="textAlign:end;">{{state.lang.activeOrdersMarektTable.opration}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tableContent,index) in tableContents" :key="index">
                                <td v-if="state.lan=='En' && (tableContent.orderType=='EXCHANGE_SELL' || tableContent.orderType=='LIMITED_SELL')" class="remove-three" style="textAlign:start;"><span class="coinname">{{tableContent.sourceCoin}}</span> ({{tableContent.destinationCoin}})</td>
                                <td v-if="state.lan=='Fa' && (tableContent.orderType=='EXCHANGE_SELL' || tableContent.orderType=='LIMITED_SELL')"  class="remove-three" style="textAlign:start;"><span class="coinname">{{$coinLabel[tableContent.sourceCoin]}}</span> ({{$coinLabel[tableContent.destinationCoin]}})</td>
                                <td v-if="state.lan=='En' && (tableContent.orderType=='EXCHANGE_BUY' || tableContent.orderType=='LIMITED_BUY')" class="remove-three" style="textAlign:start;"><span class="coinname">{{tableContent.destinationCoin}}</span> ({{tableContent.sourceCoin}})</td>
                                <td v-if="state.lan=='Fa' && (tableContent.orderType=='EXCHANGE_BUY' || tableContent.orderType=='LIMITED_BUY')"  class="remove-three" style="textAlign:start;"><span class="coinname">{{$coinLabel[tableContent.destinationCoin]}}</span> ({{$coinLabel[tableContent.sourceCoin]}})</td>
                                delete
                                <td :class="{'negetive' : tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL'), 'positive' : tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY') }">
                                    <span v-if="tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL')">
                                        فروش
                                    </span>
                                    <span v-if="tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY')">
                                        خرید
                                    </span>
                                </td>
                                delelte
                                <td v-if="state.lan=='En'" :class="(tableContent.orderType=='EXCHANGE_SELL' || tableContent.orderType=='LIMITED_SELL') ? 'negetive':'positive'">{{tableContent.orderType}}</td>
                                <td class="price-td number-font">
                                    {{$toLocal(tableContent.unitPrice,precision)}}
                                </td>
                                <td class="total-td number-font amount">
                                    {{$toLocal(tableContent.overallValue,precision)}}
                                </td>
                                <td class="remove-three">{{$toLocal(tableContent.executedPercent,precision)}}%</td>
                                <td class="amount-td number-font">
                                    {{$toLocal(tableContent.amount,precision)}}
                                </td>
                                <td class="remove">
                                    <div class="time">
                                        {{$G2J(tableContent.createdAtDateTime) }}
                                    </div>
                                </td>
                                <td :class="{'disabled':!tableContent.stop}" class="secondary-color remove-two">{{state.lang.activeOrdersProMarektTable.cancel}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Crypto from '../../../library/Crypto';
import {tradeFrom,
        tradeTo,
        precision,
        // Loop
        } from "@/library/reuseableFunction";
import RemoveOrderConfirmModal from '../../Advancetrade/RemoveOrderConfirmModal.vue';
    export default {
        name : 'Activeorders',
        components:{
            RemoveOrderConfirmModal
        },
        data (){
         
            return{
                allOrders:false,
                id:'',
                show:false,
                selected : false,
                coins: Crypto,
                cointype:'',
                headercoin:null,
                p2p:false,
                dealtype:'خرید',
                coinTo:'TOMAN',
                tableContents:[],
                wallets:[],
            }
        },
        computed: {
            precision, tradeFrom, tradeTo
        },
        methods : {
            openModal(e){
                this.id=e;
                this.show=true;
            },
            closemodal() {
                this.modalshow = false
            },
            changeCoinTo(e) {
                this.coinTo=e;
                this.getActiveOrders();
            },
            async removeorder(e) {
                this.state.loading = true
                const [res,] = await this.$http.delete(`orders/${e}`)
                if (res) {
                    ++this.state.orderChanged
                    this.modalshow = false
                    this.completedModal = true
                    this.state.lan=='Fa' ? this.$error('سفارش شما با موفقیت حذف شد', '', 'success') : this.$error('Your Order Removed Successfuly', '', 'success')
                    if (this.state.loop) {
                        this.loop.start()
                    } else {
                        this.getActiveOrders()
                    }
                }
            },
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/coins/details?p2pSupported=true')
                this.coins = res.content
                this.state.loading = false
                console.log(this.coins);
            },
            async getActiveOrders() {
                this.state.loading = true
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        market: this.headercoin.coin + '_' + this.coinTo,
                        size:1000,
                    }
                })
                this.tableContents = res.content || [];
                this.$emit('update:activeLength', this.tableContents.length)
                this.state.loading = false
            },
            async allGetActiveOrders() {
                this.allOrders=true;
                this.headercoin.coin= this.state.lang.activeOrdersProMarektTable.allOrders
                this.state.loading = true
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        size:1000,
                    }
                })
                this.tableContents = res.content || [];
                this.$emit('update:activeLength', this.tableContents.length)
                this.state.loading = false
            },
            choseCoin (value) {
                this.headercoin=this.coins[value];
                this.getActiveOrders();
                this.selected=false;
            },
            handleToggle () {
                this.selected=!this.selected
            },
        },
        mounted () {
            this.getData();
            this.headercoin=Crypto[0]
            this.getActiveOrders()
            console.log(this.wallets);
        },
        created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.chose-coin')) {
              this.selected=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.thead{
    position: sticky;
    top: 0;
    background: white;
}
.btn-left{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.btn-right{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px ;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.changepricetype{
    width: 100%;
    max-width: 150px;
    button{
        cursor: pointer;
        color: #a4c6de;
        width: 50%;
    }
}
.cointype{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 5px;
}
    .coinname{
        color:var(--secondary-color) !important;
    }
.secondary-color{
    color: var(--secondary-color);
    cursor: pointer;
}
.disabled{
    opacity: .5;
    &:hover{
        cursor: default;
    }
}
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: center;
}
.headertime{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}
.table{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    border: 0.5px solid #a4c6de65;
    // border-top: none;
    border-radius: 4px;
    height: 90%;
    .orders{
        width: 100%;
        box-sizing: border-box;
        // padding: 16px;
        overflow: scroll;
        table{
            width: 100%;
            thead{
                color: #041836;
                font-weight: 400;
                font-size: clamp(11px,1vw,14px);
            }
            tbody{
                color: rgba(63, 63, 63, .8);
                font-weight: 400;
                font-size: clamp(10px,1vw,13px);
                tr{
                    height: 30px;
                }
            }
            td{
                text-align: center;
            }
        }
    }
    .btn{
        width: 100%;
        button{
            width: 50%;
            background: var(--primary-color);
            color: var(--secondary-color);
                font-weight: 700;
                font-size: clamp(10px,1vw,14px);
            &:hover{
                cursor: pointer;
            }
        }
    }
}
.coin-info{
    width: 70%;
    table{
        width: 100%;
        thead{
            td{
                font-weight: 300;
                font-size: clamp(9px,1vw,12px);
                color:rgba(4, 24, 54, 0.575);

            }
        }
        tbody{
            td{
                font-weight: 300;
                font-size: clamp(10px,1vw,13px);
                color:rgba(4, 24, 54, 1);
            }
        }
    }

}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
// .header-item{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }
.chose-coin{
    cursor: pointer;
    height: 30px;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    max-width: 160px;
    justify-content: space-between;
    width: 100%;
        position: relative;
    border: 0.2px solid #0077cc65;
    border-radius: 4px;
    span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
}
    .selected{
        transform: rotate(180deg);
    }
.activeorders{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    max-height: 665px;
    height: 100%;
}
.coin-type{
        display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    width: 25%;
    .coin-icon{
        padding: 4px;
        height: 100%;
        max-width: 30px;
        border-radius: 4px;

    }
}
.option-container{
    max-height: 260px;
    overflow: scroll;
    top: 33px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
    img{
        position: absolute;
        right: 130px;
        top:-3px;
                width: 30px;
        height: 30px;

    }
}
@media only screen and(max-width:800px){
    .remove{
        display: none;
    }
    .header{
        flex-direction: column;
        justify-content: center;
        .coin-type{
            width: 100%;
            justify-content: center;
        }
        .coin-info{
            width: 100%;
            text-align: center;
        }
    }
}
@media only screen and(max-width:700px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove-three{
        display: none;
    }
}
</style>