<template>
    <div class="finance-table">
        <div class="table-container">
             <table>
            <thead>
                <tr class="header">
                    <td></td>
                    <td>{{state.lang.walletTable.remain}}</td>
                    <td class="remove-three">{{state.lang.walletTable.value}}</td>
                    <td class="remove">{{state.lang.walletTable.inDeal}}</td>
                    <td>{{state.lang.walletTable.depositAble}}</td>
                </tr>
            </thead>
            <template v-for="(coin,index) in coins">
            <tr v-if="coin[1].credit>0" :key="index">
                <td class="name">
                    <img class="remove-icon" :src="'/coins/'+ coin[0]+ '.png'" alt="">
                    <span style="min-width: 65px;" v-if="state.lan=='En'">{{coin[0]}}</span>
                    <span style="min-width: 65px;" v-if="state.lan=='Fa'">{{$coinLabel[coin[0]]}}</span>
                </td>
                <td>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</td>
                <td class="remove-three"> {{$toLocal(coin[1].wholePriceOfCredit,0)}} </td>
                <td class="remove"> {{$toLocal(coin[1].blockedCredit,$decimal[coin[0]])}} </td>
                <td> {{$toLocal((coin[1].credit-coin[1].blockedCredit),$decimal[coin[0]])}} </td>
            </tr>
            </template>
        </table>
        </div>
        <div class="button-container">
        <button @click="reDirect">{{state.lang.walletTable.seeAll}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Financetable',
        props:['coins','p2p'],
        data () {
            return{
            
            }
        },
        methods:{
            reDirect(){
                if(this.p2p){
                    this.$router.push({name:'Wallet'})
                }else{
                    this.$router.push({name:'ExchangeWallet'})
                }
            }
        },
        mounted () {
            
        }
    }
</script>

<style lang="scss" scoped>
.finance-table{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.header{
    position: sticky;
    top: 0;
    background: white;
}
    .negetive{
        color: #FF005C;
        direction: ltr;
        // text-align: end;
    }
.name{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    color: black;
    font-style: normal;
    font-weight: 400;
        font-size: clamp(11px,1vw,13px) !important;
        img{
            // border: 1px solid #FFD43D;
            border-radius: 4px;
            max-height: 22px;
            max-width: 22px;
        }
}
.table-container{
    overflow: scroll;
    max-height: 110px;
}
table{
    width: 100%;
    tr{
        height: 20px;
    }
}
td{
    text-align: start;
    font-size: clamp(10px,1vw,12px);
    color: rgba(63, 63, 63, .8);
    font-weight: 300;
    span{
        align-self: center;
    }
    
}
thead{
    td{
        color: black;
    }
}
.button-container{
    width: 100%;
    // padding: 0px 10px;
    display: flex;
    justify-content: center;
}
button{
    background: var(--primary-color);
    color: var(--secondary-color);
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    align-items: center;
    max-width: 174px;
    &:hover{
        max-width: 200px;
    }
}
@media only screen and(max-width:700px){
    .button-container{
        width: 100%;
        padding: 0;
    }
}
@media only screen and(max-width:600px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:500px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:400px){
    .remove-three{
        display: none;
    }
}
</style>