<template>
    <div class="wallettable">
        <div class="header">
            <span class="span"></span>
            <span>{{state.lang.walletTable.remain}}</span>
            <!-- <span class="positive">{{state.lang.walletTable.changes}}</span> -->
            <span class="remove-three">{{state.lang.walletTable.value}}</span>
            <span class="remove">{{state.lang.walletTable.inDeal}}</span>
            <span>{{state.lang.walletTable.depositAble}}</span>
        </div>
        <div v-for="(item,index) in coins.filter(wallet => wallet[0] !=='TOMAN')" :key="index" :class="item.class" class="market">
            <div class="coin-container">
                <div class="item icon-container">
                    <img class="remove-icon" :src="'/coins/'+ item[0]+ '.png'" alt="">
                    <span style="min-width: 65px;" v-if="state.lan=='En'">{{item[0]}}</span>
                    <span style="min-width: 65px;" v-if="state.lan=='Fa'">{{$coinLabel[item[0]]}}</span>
                </div>
                <span class="item">{{$toLocal(item[1].credit,$decimal[item[0]])}}</span>
                <!-- <td v-if="item.variant<0 && state.lan=='En'" class="item negetive">{{item.variant}}%</td>
                <td v-if="item.variant<0 && state.lan=='Fa'" class="item negetive">{{Math.abs(item.variant)}}%-</td>
                <td v-if="item.variant>0 && state.lan=='En'" class="item positive">+{{item.variant}}%</td>
                <td v-if="item.variant>0 && state.lan=='Fa'" class="item positive">{{item.variant}}%+</td> -->
                <span class="item remove-three">{{$toLocal(item[1].wholePriceOfCredit,0)}}</span>
                <span class="item remove">{{$toLocal(item[1].blockedCredit,$decimal[item[0]])}}</span>
                <span class="item">{{$toLocal((item[1].credit-item[1].blockedCredit),$decimal[item[0]])}}</span>
            </div>
            <div class="btn-container">
                    <a @click.prevent="" class="disabled" href="">{{state.lang.wallet.opration}}</a>
                    <a class="buy" @click.prevent="openChargeModal(item[0])" href="">{{state.lang.wallet.deposit}}</a>
                    <a class="sell" @click.prevent="openSellModal(item[0])" href="">{{state.lang.wallet.withDrawal}}</a>
                    <a @click.prevent="$router.push('/Advancetrade/'+'TOMAN/'+item[0])" href="">{{state.lang.wallet.deal}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Wallettable',
        props:['coins'],
        data () {
            return{
                walletCoins:[],
            }
        },
        methods:{
            openSellModal(coin){
                this.$emit('sell')
                this.$emit('coinType',coin)
            },
            openChargeModal(coin){
                this.$emit('charge')
                this.$emit('coinType',coin)
            },
        },
        mounted(){
            this.walletCoins=this.coins.filter(wallet => wallet[0] !=='TOMAN')
        }
    }
</script>

<style lang="scss" scoped>
// .wallettable{
//     width: 100%;
// }
.market:last-child{
    border-bottom: none;
}
// .remain{
//     width: 45px;
//     text-align: start;
// }

.item{
    width: 80px;
    text-align: start ;

}
.disable{
    color: rgba(63, 63, 63, 1);
}
.no-border{
    border-bottom: none !important;
}
.btn-container{
    font-weight: 400;
    font-size: clamp(8px,3vw,12px) !important;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    background: var(--primary-color);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 5px;
    margin-right: 22px;
    .disabled{
        opacity: .5;
        transform: translateY(-20px) scale(0.8,0.8) !important;
        background: linear-gradient(0deg,var(--primary-color) 40%,transparent 70%) !important;
        z-index: 1 !important;     
        cursor: default !important;
    }
    a:hover{
        transform: none;
    }
}
.market{
    border-bottom: 0.4px solid #0077cc52;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    box-sizing: border-box;
    padding: 8px 0;
}
    .negetive{
        color: #FF005C !important;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
}
.icon-container{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    width: 80px;
    span{
        margin-top: 3px;
        font-weight: 400;
        font-size: clamp(10px,1vw,13px);
        color: rgba(63, 63, 63, 1) !important;
    }
    img{
        // border:1px solid var(--banner-primary);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1px;
        width: 22px;
        height: 22px;
    }
}
.coin-container{
    span{
        color:  #3f3f3f94;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    font-weight: 300;
    font-size: clamp(9px,1vw,12px);
}
.span{
    width: 100px;
}
.header{
    // max-width: 557px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    font-weight: 300;
    font-size: clamp(8px,1vw,12px);
    span{
        width: 80px;
        text-align: start;
    }
}
@media only screen and(max-width:700px){
    .btn-container{
        width: 100%;
        margin-right: 0;
    }
    .remove-icon{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:500px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:450px){
    .remove-three{
        display: none;

    }
}
</style>