<template>
    <transition name="fade">
      <ConfirmModal @close="closeModale" :data="data" v-if="confirmModal" :type="state.userInfo.towStepAuthenticationType" :twoStep="state.userInfo.towStepAuthenticationType" />
        <div class="modal-backdrop" @click.self="closeModale" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
                <h3> {{state.lang.walletSellModal.title}} </h3>
                <label for="">
                    <input @input="data.amount = $toLocal(data.amount,0)" @focus="toggleFocus" v-model="data.amount" class="email" name="email" type="text" >
                    <span :class="[{'focus':(blanckspace)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.walletSellModal.priceType}} </span>
                </label>
                <div class="money">
                        <p> {{state.lang.walletSellModal.balance}} </p>
                        <p>{{$toLocal(info[1].credit,0)}} {{state.lang.walletSellModal.toman}}</p>
                </div>
                <div class="coin-type">    
                        <div @click.self="cardToggle" class="chose-coin">
                            <span @click.self="cardToggle" v-if="!data.destinationWalletAddress">{{this.state.lang.walletSellModal.cardNum,}}</span>
                            <span @click.self="cardToggle" v-else>{{data.destinationWalletAddress}}</span>
                            <img @click.self="cardToggle" :class="{'selected':selected}" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="selected==true" class="option-container">    
                                <div @click="choseCard(card.cardNumber)" class="options" v-for="(card,index) in cards" :key="index">
                                    <span>{{card.cardNumber}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                        <a @click.prevent="" href=""> {{state.lang.walletSellModal.addCard}} </a>
                </div>
                <div class="notes">
                    <div class="note">
                        <img src="../../../assets/icons/error.svg" alt="">
                        <p> {{state.lang.walletSellModal.warning1}} </p>
                    </div>
                    <div class="note">
                        <img src="../../../assets/icons/error.svg" alt="">
                        <p> {{state.lang.walletSellModal.warning2}} </p>
                    </div>
                </div>
                <button @click.prevent="confirmModal=true" :disabled="disable" class="submit"> {{disable ? disable : state.lang.walletSellModal.btn}} </button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
import ConfirmModal from './ConfirmModal.vue';
export default {
  name: 'Sellmodal',
  components:{
    ConfirmModal
  },
  props:['info'],
  data() {
      return {
        data:{
          amount:'',
          destinationWalletAddress:'',
          relatedCoin:'TOMAN',
          otp:'',
        },
        confirmModal:false,
        close : false,
        cards:this.state.userInfo.bankAccounts,
        blanckspace:false,
        money:5728361,
        unfocus : true,
        selected:false,
        topcardnumber:'',
        cardnumbers:[],
      }
  },
  computed:{
    disable(){
      let errors =''
      if(!this.$S2N(this.data.amount)){
        errors=this.state.lang.walletSellModal.emptyPrice
      } else if(!this.data.destinationWalletAddress){
        errors=this.state.lang.walletSellModal.emptyCard
      }else if (this.info[1].credit < this.$S2N(this.data.amount) ){
        errors=this.state.lang.walletSellModal.lowBalance
      } else {
        errors=false;
      }
      return errors
    }
  },
  methods : {
    test(){
      console.log(this.$S2N(this.data.amount))
    },
      closeModale() {
        this.$emit('close' , this.close)
      },
      toggleFocus () {
                this.blanckspace=true ;
                this.unfocus=true;
            },
      cardToggle(){
        this.selected=!this.selected
      },
      choseCard(value){
        this.data.destinationWalletAddress=value;
        this.selected=false;
      }
  },
  mounted () {
    console.log(this.toman);
      // setTimeout(this.closeModale,3000);
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.coin-type')) {
              this.selected=false;
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .close{
     cursor: pointer;
   }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
   .note{
       display: flex;
       flex-direction: row;
       column-gap: 5px;
    //    align-items: center;
       color: var(--red-color);
       img{
           align-self: flex-start;
       }
   }
   .money{
       color: rgba(4, 24, 54, 0.342);
       font-weight: 300;
       font-size: clamp(10px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    z-index: 2;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     row-gap: 8px;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       cursor: pointer;
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h3{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: start;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>