<template>
    <form class="exchange">
        <Dashboardnavbar />
        <div class="content">
            <ShopModal v-if="openModal" @close="openModal = false" :unitPrice="data.unitPrice" :totalPrice="data.totalPrice"
                    :amount="data.amount" :type="buyClick ? 'BUY':'SELL'" :date="null" :limit="buyClick" @confirm="buy" :tradeTo="'TOMAN'" :tradeFrom="destCoinSymbol" />
                <transition name="fade"> 
            <div class="exchange-container">
                <div class="btns">
                    <button class="topright" @click.prevent="setBuyClick(true)" :class="{'active':buyClick==true}"> {{state.lang.exchange.buy}} </button>
                    <button class="topleft" @click.prevent="setBuyClick(false)" :class="{'active':buyClick==false}"> {{state.lang.exchange.sell}} </button>
                </div>
                <div class="filters">
                        <div class="coin-type">    
                                <div @click="handleToggle" class="chose-coin">
                                    <span v-if="state.lan=='Fa'">{{persianName}}</span>
                                    <span v-if="state.lan=='En'">{{destCoinSymbol}}</span>
                                    <img :class="{'selected':selected}"   src="../../assets/icons/listicon.svg" alt="">
                                    <transition name="fade">
                                    <div v-if="selected==true" class="option-container">    
                                        <div @click="choseCoin(index)" class="options" v-for="(coin,index) in coin" :key="index">
                                            <span v-if="state.lan=='En'">{{coin.coin}}</span>
                                            <span v-if="state.lan=='Fa'">{{coin.persianName}}</span>
                                        </div>
                                    </div>
                                    </transition>
                                </div>
                                <div class="positive">
                                    <span>{{state.lang.exchange.price}} :   </span>
                                    <span>{{$toLocal(data.unitPrice,0)}}  {{state.lang.exchange.priceType}}</span>
                                </div>
                        </div>
                        <div class="remain">
                            <div style="cursor:pointer" @click="allIn">
                                    <span>{{state.lang.exchange.balance}} :</span>
                                    <span v-if="state.userInfo.supervisor">{{balance}} {{buyClick ? this.state.lang.exchange.priceType:''}}</span>
                                    <span v-else>{{$toLocal(balance,buyClick ? 0:$decimal[destCoinSymbol])}} {{buyClick ? this.state.lang.exchange.priceType:''}} </span>
                            </div>
                            <a @click.prevent="$router.push({name : 'Wallet'})" href="">{{state.lang.exchange.raiseBalance}}</a>
                        </div>
                        <div class="inputs">
                            <label :style="!buyClick ? 'width:100%' : ''" for="">
                            <input @input="inputsFun('amount')" v-model="data.amount" @focus="blanckspace.bitcoin=true" class="email" name="email" type="text">
                            <span v-if="state.lan=='En'" :class="[{'focus':(blanckspace.bitcoin) || data.amount},state.lan=='En'?'span-en':'span-fa']">{{state.lang.exchange.amount}} {{destCoinSymbol}} </span>
                            <span v-if="state.lan=='Fa'" :class="[{'focus':(blanckspace.bitcoin) || data.amount},state.lan=='En'?'span-en':'span-fa']">{{state.lang.exchange.amount}} {{$coinLabel[destCoinSymbol]}} </span>
                            </label>
                            <span v-if="buyClick" class="line"></span>
                            <label for="" v-if="buyClick">
                                <input @input="inputsFun('s')" v-model="data.totalPrice" @focus="blanckspace.toman=true" class="email" name="email" type="text">
                                <span :class="[{'focus':(blanckspace.toman) || data.totalPrice},state.lan=='En'?'span-en':'span-fa']">{{state.lang.exchange.priceIn}} {{state.lang.exchange.priceType}}</span>
                            </label>
                        </div>
                        <div class="maxAndMin">
                            <p>{{state.lang.exchange.dealVolume}}</p>
                            <div class="nums">
                                <span> {{state.lang.exchange.min}} </span>
                                <span> {{minAmountExchange}} </span>
                                <span> {{code}} </span>
                                <span> - </span>
                                <span> {{state.lang.exchange.max}} </span>
                                <span> {{maxAmountExchange}} </span>
                                <span>  {{code}}  </span>
                            </div>
                        </div>
                        <div v-if="buyClick" class="btns">
                        <button class="topright" @click.prevent="togglewallet=true" :class="{'active':togglewallet==true}">{{state.lang.exchange.toMainWallet}}</button>
                        <button class="topleft" @click.prevent="togglewallet=false" :class="{'active':togglewallet==false}">{{state.lang.exchange.toOtherWallet}}</button>
                        </div>
                        <div v-if="togglewallet==false && buyClick" class="walletaddres">
                            <div class="walletaddres-container">
                                <label for="">
                                <input @focus="blanckspace.walletAddres=true" v-model="data.destinationWallet" class="email" name="email" type="text">
                                <span :class="[{'focus':(blanckspace.walletAddres)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.exchange.walletAddres}}</span>
                                </label>
                                <div class="channel">
                                    <div @click="netDrop = !netDrop" class="selected-channel">
                                        <span v-if="selected_net">{{selected_net}}</span>
                                        <span v-else>انتخاب شبکه</span>
                                    </div>
                                    <div class="select-channel" v-if="netDrop">
                                        <div class="channel-item" v-for="(net,index) in nets" :key="index" @click="selectFun(index)">
                                            <span>{{net.tokenType}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert">
                                <img src="../../assets/icons/error.svg" alt="">
                                <p>{{state.lang.exchange.warning}}</p>
                            </div>
                        </div>
                        <div class="remain" v-if="!buyClick">
                                    <span>{{state.lang.exchange.total}} :</span>
                                    <span>{{$toLocal($S2N(data.amount) * $S2N(data.unitPrice),0)}}  {{state.lang.exchange.priceType}}</span>
                        </div>
                        <button v-if="buyClick" :disabled="checkBtn" @click.prevent="openModal=true" class="buy">{{checkBtn?checkBtn:state.lang.exchange.buy}}</button>
                        <button v-if="!buyClick" :disabled="checkBtn" @click.prevent="openModal=true" class="sell">{{checkBtn?checkBtn:state.lang.exchange.sell}}</button>
                </div>
            </div>
                </transition>
            <img class="banner" src="../../assets/cards/exchangecard.png" alt="">
        </div>
        <Swingbtn />
        <Footer />
    </form>
</template>

<script>
import {
    Loop
} from "@/library/reuseableFunction";
// const enToFa = {
//     Buy:'خرید',
//     Sell:'فروش'
// };
import Dashboardnavbar from '../Profile/Dashboardnavbar.vue';
import Footer from '../public/Footer.vue';
import Swingbtn from '../public/Swingbtn.vue';
import Crypto from '../../library/Crypto';
import ShopModal from '../Tools/ShopModal.vue';
    export default {
        name:'Exchange',
    components : {
        Dashboardnavbar,
        Footer,
        Swingbtn,
        ShopModal,
    },
    data () {
        return{
            temptData: {
                amount: null,
                totalPrice: null,
                destinationCoin: null,
                unitPrice: null,
                destinationWallet: '',
                tokenType: null,
            },
            data: {
                price:'',
                marketType: this.destCoinSymbol + '_TOMAN',
                amount: null,
                sourceCoin: "TOMAN",
                destinationCoin: this.destCoinSymbol,
                orderType:"EXCHANGE_BUY",
                unitPrice: this.price,
                totalPrice: null,
                destinationWallet: '',
                tokenType: null,
            },
            openModal:false,
            netDrop:false,
            error:'',
            waiting:false,
            price:'',
            balance:'',
            loop:undefined,
            buyClick:true,
            destCoinSymbol:'BITCOIN',
            persianName:'بیت کوین',
            code:'BTC',
            openmodal:false,
            togglewallet:true,
            selected : false,
            coin: [],
            nets: [],
            minAmountExchange:'',
            maxAmountExchange:'',
            // headercoin:[],
            headercoin:{
                        coin: '',
                        persianName:'',
                        code:'',
                        balance:'',
                        minAmount:'',
                        maxAmount:'',
                        price:Crypto[0].price,
                        value:Crypto[0].value,
                },
            blanckspace : {
                    bitcoin : false,
                    toman : false,
                    walletAddres:false,
                },
        }
    },
    computed:{
        checkBtn(){
                let errors = ''
                if(this.buyClick && !this.$S2N(this.data.unitPrice)){
                        errors= this.state.lang.advanceTrade.errors.emptyPrice
                }
                else if(!this.$S2N(this.data.amount)){
                        errors= this.state.lang.advanceTrade.errors.emptyAmount
                }
                else if(this.$S2N(this.data.amount) < this.minAmountExchange){
                    errors= this.state.lang.advanceTrade.errors.lowerThanMin
                }
                else if(this.$S2N(this.data.amount) > this.maxAmountExchange){
                    errors= this.state.lang.advanceTrade.errors.higherThanMax
                }
                else if((this.$S2N(this.data.totalPrice) > this.balance) && this.buyClick){
                        errors= this.state.lang.advanceTrade.errors.lowBalance
                }
                else if((this.$S2N(this.data.amount) > this.balance) && !this.buyClick){
                        errors= this.state.lang.advanceTrade.errors.lowBalance
                }
                 else{
                    errors=false;
                }
                return errors ;
            },
    },
    methods : {
            allIn(){
                if(this.buyClick){
                    this.data.totalPrice=this.balance;
                    this.inputsFun('s')
                } else {
                    this.data.amount=this.balance;
                    // this.inputsFun('amount')
                }
            },
            setBuyClick(e){
                if(e){
                    this.buyClick=e
                    this.data.orderType = "EXCHANGE_BUY";
                    this.tomanTetherWallet();
                } else {
                    this.buyClick=false
                    this.data.orderType = "EXCHANGE_SELL",
                    this.coinBalance();
                }
            },
            async buy() {
                console.log(this.netDrop);
                this.state.loading = true
                this.temptData = JSON.parse(JSON.stringify(this.data))
                this.temptData.amount = this.$S2N(this.data.amount)
                this.temptData.totalPrice = this.$S2N(this.data.totalPrice)
                this.temptData.destinationCoin = this.data.destinationCoin
                this.temptData.unitPrice = this.$S2N(this.data.unitPrice)
                this.temptData.destinationWallet = this.data.destinationWallet
                this.temptData.tokenType = this.selected_net
                if (!this.netDrop) {
                    delete this.temptData.destinationWallet
                    delete this.temptData.tokenType

                    this.state.loading = true
                    if(!this.buyClick){
                        const res=await this.$axios.post(
                            '/exchange/sell', this.temptData
                        )
                        res && this.state.lan=='Fa' ? await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success') :  await this.$error('', res.message, 'success')

                    }else{
                         await this.$axios.post(
                        '/exchange/buy', this.temptData
                        )
                    }
                } else {

                    this.state.loading = true
                     await this.$axios.post(
                        '/exchange/buy', this.temptData
                        )
                    // this.success = true

                    this.$emit('openSuccess', this.temptData)

                }

                this.state.BuyModal = false
                this.state.loading = false;
                this.setBuyClick(true);

            },
            choseCoin (index) {
                this.state.loading=true
                this.destCoinSymbol=this.coin[index].coin;
                this.data.destinationCoin=this.destCoinSymbol
                this.data.marketType=this.destCoinSymbol + '_TOMAN'
                this.persianName=this.coin[index].persianName
                this.code=this.coin[index].code
                this.getPrice();
                this.MinMaxAmount();
                this.nets = this.coin.find((a) => a.coin === this.destCoinSymbol).tokenTypeDetails
                this.selected_net=false;
                this.selectFun(0);              
                this.selected=false;
                this.state.loading=true
                if(!this.buyClick) this.coinBalance();
                this.data.amount='',
                this.data.totalPrice=''
            },
            handleToggle () {
                this.selected=!this.selected
            },
            async tomanTetherWallet() {
                if (this.state.token) {
                    this.loading = true
                    const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + 'TOMAN')
                    if (res2) {
                        this.balance = res2['TOMAN'].credit;
                        }
                    this.loading = false
                }
            },
            async coinBalance() {
            if (this.state.token) {
                this.loading = true
                const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.destCoinSymbol)
                if (res2) {
                    this.balance = res2[this.destCoinSymbol].credit
                }
                this.loading = false
            }

        },
            async getPrice() {
                const res = await this.$axios.get('coins/exchange-price/' + 'usd' + '?includeWeeklyChange=false&destCoinSymbol=' + this.destCoinSymbol)
                this.data.unitPrice = res.find(a => a.destCoinSymbol == this.destCoinSymbol).buyPrice;
                // this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal['TOMAN'])
            },
            selectFun(index) {
                this.selected_net = this.nets[index].tokenType
                this.txIdIsNeeded = this.nets[index].txIdIsNeeded
                this.data.tokenType = this.nets[index].tokenType
                this.netDrop = false

            },
            async inputsFun(e) {
                // this.waiting = 
                // this.state.loading = true
                await this.getPrice()
                    if (e === 'amount') {
                        console.log(this.destCoinSymbol);
                    this.data.amount = this.$toLocal(this.data.amount,this.$decimal[this.destCoinSymbol])
                    this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice),0)
                } else {
                    this.data.totalPrice = this.$toLocal(this.data.totalPrice,0)
                    this.data.amount = this.$toLocal(this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice), this.$decimal[this.destCoinSymbol])
                    // console.log(this.data.totalPrice);
                    // console.log(this.$toLocal(this.data.unitPrice));
                    // console.log(this.$toLocal(this.data.totalPrice/this.$S2N(this.$toLocal(this.data.unitPrice)),this.$decimal[this.destCoinSymbol]));
                    // this.data.amount = this.data.totalPrice/this.data.unitPrice
                }
                
                this.waiting = false
                // this.state.loading = false
            },
            MinMaxAmount() {
                this.minAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).minAmountExchange
                this.maxAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).maxAmountExchange
            },
            async getCoin() {
                this.state.loading = true
                const res = await this.$axios.get('/coins/details')
                this.coin = res.content
                this.nets = this.coin.find((a) => a.coin === this.destCoinSymbol).tokenTypeDetails
            },
            beforeLoop() {
                if (this.state.loop) {
                    this.loop = new Loop(this.getPrice, 5000, 'realPriceBuy' + 'Toman')
                    this.loop.start()
                } else {
                    this.getPrice()
                }
        }
            
        },
        async mounted() {
            this.state.loading = true ;
            this.beforeLoop()
            await this.tomanTetherWallet()
            await this.getCoin()
            this.MinMaxAmount()
            this.choseCoin(0);
            this.state.loading = false
        },
        beforeDestroy() {
                if (this.loop) {
                    this.loop.stop()
                }
        },
        created: function () {
            window.addEventListener("click",(event)=>{
                if(!event.target.closest('.chose-coin')) {
                this.selected =false;
                    } else {
                        return true
                }
                if(!event.target.closest('.channel')) {
                this.netDrop =false;
                    } else {
                        return true
                }
            })
  },
    }
</script>

<style lang="scss" scoped>
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background:linear-gradient(0deg,white 40%,var(--primary-color) 50%);
        z-index: 1;   
    }
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.walletaddres-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 32px;
    label{
        width: 70%;
    }
    .channel{
        z-index: 20;
        cursor: pointer;
        position: relative;
        width: 20%;
        background: white;
        padding: 8px 16px;
        border: 0.4px solid #A4C6DE;
        border-radius: 4px;
        max-height: 32px !important;
        .selected-channel{
            font-size: clamp(10px,1vw,12px);
            color: var(--secondary-color);
        }
        .select-channel{
            position: absolute;
            right: 0;
            top: 25px;
            border-radius: 4px;
            font-size: clamp(14px,1vw,16px);
            color: var(--secondary-color);
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            width: 100%;
            .channel-item{
                padding: 5px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            span{
                background: var(--primary-color);
                box-sizing: border-box;
                padding: 5px;
                border-radius: 4px;
                width: 90%;
                text-align: center;
            }
        }
    }
}
.channel-input{
    background: white !important;
}
button{
    cursor: pointer;
}
.buy{
    width: 100%;
    background: var(--positive-color);
    color: white;
}
.sell{
    width: 100%;
    background: var(--red-color);
    color: white;

}
.alert{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    img{
        align-self: flex-start;
        justify-self: start;
    }
}
.maxAndMin{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    color: rgba(57, 74, 109, .5);
    font-weight: 300;
    font-size: clamp(10px,1vw,12px);
}
.walletaddres{
    row-gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    p{
        color: var(--red-color);
    }

}
label{
    width: 45%;
    position: relative;
    background: white;
    max-height: 32px;
    input{
        height: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
    }
    span {
        cursor: text;
    transition: 1s;
    border-radius: 4px;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
.remain{
    width: 100%;
    align-items: flex-end;
    font-weight: 300;
    font-size: clamp(9px,1vw,13px);
    color: rgba(4, 24, 54, .7);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a:hover{
        transform: none;
    }
}
.inputs{
    .line{
        border-right: 0.5px solid #0077CC;
    }
    width: 100%;
    row-gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .input{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
}
.filters{
    display: flex;
flex-direction: column;
align-items: center;
box-sizing: border-box;
row-gap: 16px;
padding: 16px;
width: 100%;
}
.coin-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .coin-icon{
        height: 32px;
        width: 32px;
        border: 1px solid var(--banner-primary);
        border-radius: 4px;

    }
}
.option-container{
    top: 33px;
    max-height: 250px;
    overflow: scroll;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
    img{
        position: absolute;
        right: 130px;
        top:-3px;
                width: 30px;
        height: 30px;

    }
}
.chose-coin{
    height: 30px;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    max-width: 160px;
    justify-content: space-between;
    width: 100%;
        position: relative;
    border: 0.2px solid #0077cc65;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
}
    .selected{
        transform: rotate(180deg);
    }
.topright{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
        border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.topleft{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.active{
background: var(--secondary-color) !important;
color: white !important;
}
.btns{
    display: flex;
    flex-direction: row;
    width: 100%;
        border-radius: 4px;
    button{
        cursor: pointer;
        color: #A4C6DE;
        width: 50%;
        background: transparent;
    }
}
.exchange{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.exchange-container{
    // max-height: 450px;
    max-width: 596px;
    background: var(--primary-color);
    width: 100%;
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
}
.content{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 16px;
    box-sizing: border-box;
    padding: 120px;
    .banner{
        max-width: 700px;
        // max-height: 375px;
        width: 100%;
    }
}
@media only screen and(max-width:1200px){
    .content{
        flex-direction: column;
        row-gap: 30px;
    }
}
@media only screen and(max-width:600px){
    .coin-type,.remain,.inputs{
        flex-direction: column;
    }
    .inputs{
        label{
            width: 100%;
        }
    }
    .content{
        padding: 60px;
    }
}
@media only screen and(max-width:400px){
    .content{
        padding: 20px;
    }
}
</style>