<template>
        <div @click="reDirect" class="support">
            <img src="../../assets/swingbtn/ChatOnline.svg" alt="">
        </div>
</template>

<script>
    export default {
        name : 'Swingbtn',
        methods: {
            reDirect () {
                this.$router.push({name : 'Contactus'})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .support{
        position: fixed;
        width: 50px;
        height: 50px;
        right: 40px;
        top: 671px;
        cursor: pointer;
    }

</style>