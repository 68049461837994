<template>
    <div class="tables-container">
        <div class="tables">
            <div class="btn-table">
                <div class="btn">
                    <button @click.prevent="tab('TOMAN')" :class="{'submit':$route.params.tradeTo=='TOMAN'}">{{state.lang.advanceTrade.marketNumbers.toman}}</button>
                    <button @click.prevent="tab('TETHER')" :class="{'submit':$route.params.tradeTo=='TETHER'}">{{state.lang.advanceTrade.marketNumbers.tether}}</button>
                </div>
                <div class="table">
                <table>
                    <thead>
                        <tr>
                            <td>{{state.lang.advanceTrade.marketNumbers.coin}}</td>
                            <td style="text-align:center">{{state.lang.advanceTrade.marketNumbers.changes}}</td>
                            <td style="text-align:end">{{state.lang.advanceTrade.marketNumbers.price}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="coin" v-for="(coin,index) in coins" :key="coin.price" @click="goto(index)">  <!-- v-for -->
                            <td v-if="state.lan=='En'">{{$coinUnit[coin.destCoinSymbol]}}</td>
                            <td v-if="state.lan=='Fa'">{{$coinLabel[coin.destCoinSymbol]}}</td>
                            <td style="text-align:center" class="positive " v-if="coin.price24hrChangePercent>0 && state.lan=='Fa'">{{$toLocal(coin.price24hrChangePercent,2)}}%+</td>
                            <td style="text-align:center" class="negetive " v-if="coin.price24hrChangePercent<0 && state.lan=='Fa'">{{$toLocal(Math.abs(coin.price24hrChangePercent),2)}}%-</td>
                            <td style="text-align:center" class="positive " v-if="coin.price24hrChangePercent>0 && state.lan=='En'">+{{$toLocal(coin.price24hrChangePercent,2)}}%</td>
                            <td style="text-align:center" class="negetive " v-if="coin.price24hrChangePercent<0 && state.lan=='En'">-{{$toLocal(Math.abs(coin.price24hrChangePercent),2)}}%</td>
                            <td style="text-align:end">{{$toLocal(coin.price,$decimal[$route.params.tradeTo])}}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="btn-table">    
                <div style="height:100%" class="table">
                    <table>
                        <thead>
                            <tr>
                                <td>{{state.lang.advanceTrade.marketNumbers.price}}</td>
                                <td>{{state.lang.advanceTrade.marketNumbers.amount}}</td>
                                <td>{{state.lang.advanceTrade.marketNumbers.time}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(deal,index) in LastTrades" :key="index"> 
                                <td v-if="$route.params.tradeTo=='TETHER'" :class="deal.orderType=='MARKET_BUY' || deal.orderType=='LIMITED_BUY' ? 'positive' :'negetive'">{{$toLocal(deal.wholePrice,$decimal[$route.params.tradeTo])}}</td>
                                <td v-if="$route.params.tradeTo=='TOMAN'" :class="deal.orderType=='MARKET_BUY' || deal.orderType=='LIMITED_BUY' ? 'positive' :'negetive'">{{$toLocal(deal.calculatedPriceInTomanMarket,$decimal[$route.params.tradeTo])}}</td>
                                <td :class="deal.orderType=='MARKET_BUY' || deal.orderType=='LIMITED_BUY' ? 'positive' :'negetive'">{{$toLocal(deal.amount,$decimal[$route.params.tradeFrom])}}</td>
                                <td :class="deal.orderType=='MARKET_BUY' || deal.orderType=='LIMITED_BUY' ? 'positive' :'negetive'">
                                    <span>{{$G2J(deal.createdAtDateTime,'time')}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
             
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import {tradeTo, tradeFrom, Loop} from "@/library/reuseableFunction";
    export default {
        name:'Marketnumbers',
        data () {
            return{
                priceType:this.state.lan=='En' ? 'TETHER': 'TOMAN',
                toman:this.$route.params.tradeTo=='TOMAN' ?  true :  false ,
                coins:[],
                loop:undefined,
                LastTrades:[],
                deals:[
                    {
                        price:123456340000,
                        volume:0.134,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.034,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.034,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.034,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.034,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.834,
                        hours:12,
                        minutes:34
                    },
                    {
                        price:123456340000,
                        volume:0.034,
                        hours:12,
                        minutes:34
                    },
                ]
            }
        },
        computed: {
            tradeTo, tradeFrom
        },
        methods:{
            async getLastTrades() {
                const res = await this.$axios.get('/trades/last-trades', {
                    params: {
                        marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        size: 20,
                        type: 'P2P'
                    }
                })
                this.LastTrades = res.content
                this.loading = false
            },
            changeCoin(coin){
                this.$router.push('/Advancetrade/'+this.$route.params.tradeTo+'/'+coin)
            },
            goto(e){
                console.log(this.coins[e].destCoinSymbol);
                let coin = this.coins[e].destCoinSymbol.replace(/-/ig, '_')
                this.$router.push(`/advancetrade/${this.$route.params.tradeTo}/${coin}`)
            },
            async getPrice() {
                const link = this.toman ? '/coins/price/usd' : '/coins/price/usdt' ;
                let [res,] = await this.$http.get(link, {
                    params: {
                        include24hrChange: true,
                        includeWeeklyChange: false
                    }
                })
                this.loading = false
                if (res) {
                    this.coins = res;
                    this.sort()
                }
            },
            sort() {
                this.coins.sort((a, b) => this.$sortCoins.indexOf(a.destCoinSymbol) - this.$sortCoins.indexOf(b.destCoinSymbol))
            },
            tab(type) {
                (this.loop  ? this.loop.stop() : '');
                this.toman= type=='TOMAN' ? true : false ;
                this.$router.push('/Advancetrade/'+type+'/'+this.$route.params.tradeFrom)
                this.coins = []
                this.getPrice()
                this.getLastTrades()
                // if (this.state.loop) {
                //     this.beforeLoop()
                // } else {
                //     console.log();
                // }
            },
            beforeLoop() {
                this.loading = true
                if (this.state.loop) {
                    this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.$route.params.tradeTo=='TOMAN' ? 'TOMAN' : 'TEHTER'))
                    this.loop.start()
                } else {
                    this.getPrice()
                }
            },
            // async loop() {
            //     // this.state.loading = true
            //     this.loading = true

            //     while (this.alive) {
            //         this.loopIsAlive = true
            //         await this.getPrice()
            //         this.loading = false
            //         await this.$sleep(5000)
            //     }
            //     this.loopIsAlive = false
            // },
        },
        async mounted() {
            this.beforeLoop()
            // this.alive = true
            // this.loading = true
            // if (this.state.loop) {
            //     this.loop()
            // } else {
            //     await this.getPrice()
            //     this.loading = false
            // }
            // if (this.state.loop) {
            //     this.loop = new Loop(this.getLastTrades,5000,'lastTrade')
            //     this.Loop.start()
            // } else {
                this.getLastTrades()
            // }
        },
        beforeDestroy() {
            this.loop  ? this.loop.stop() : '';
        }
    }
</script>

<style lang="scss" scoped>
.coin{
    cursor: pointer;
}
thead{
    td{
        font-weight: 300;
        color: rgba(63, 63, 63, .9);
        font-size: clamp(10px,2vw,13px);
    }
}
tbody{
    td{
        font-weight: 300;
        font-size: clamp(8px,2vw,12px);
        color: rgba(63, 63, 63, .7);
    }
}
.table{
    box-sizing: border-box;
    padding: 10px;
    height: 90%;
    // max-height: 285px;
    overflow-y: scroll;
    overflow-x: hidden;
    table{
        overflow: hidden;
    }

}
.tables-container{
    border-radius: 4px;
    z-index: 5;
    width: 100%;
    max-width: 238px;
}
.tables{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;
}
.btn-table{
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    height: 50%;
    overflow: hidden;
}
.table{
    display: flex;
    flex-direction: column;
}
.btn{
        width: 100%;
        button{
            width: 50%;
            background: var(--primary-color);
            color: var(--secondary-color);
                font-weight: 700;
                font-size: clamp(10px,1vw,14px);
            &:hover{
                cursor: pointer;
            }
        }
    }
    @media only screen and(max-width:850px){
    .tables-container{
        order: 3;
        max-width: none;
    }
}
</style>