<template>
    <div class="sidebar-size"> 
            <div class="sidebar-container">   
                <div class="sidebar-item">    
                    <div style="" @mouseover="item.hover = true , parenthover = true" @mouseleave="item.hover = false , parenthover = false" @click.prevent="push(item.addres)" :class="{'active' : $route.name == item.routername}" class="item-container" v-for="(item,index) in sidebar" :key="index" >
                        <img :src=item.img alt="">
                    </div>
                </div>
                <div class="exit">
                        <img @click.prevent="push('/')"  @mouseover="exithover = true , parenthover = true" @mouseleave="exithover = false , parenthover = false"  src="../../assets/icons/exit.svg" alt="">
                </div>
            </div>
            <div v-if="parenthover == true" :class="{'sidebar-link-container-en':state.lan=='En'}" class="sidebar-link-container">   
                <div class="sidebar-item">    
                    <div :class="{'active' : $route.name == item.routername}"  class="item-container" v-for="(item,index) in sidebar" :key="index" >
                        <transition name="fade">
                        <a @click.prevent="" v-if="item.hover==true"  @mouseover="item.hover = true" @mouseleave="item.hover = false" href="">{{item.title}}</a>
                        </transition>
                    </div>
                </div>
                <div class="exit">
                    <transition name="fade">
                        <a @mouseover="exithover = true" @mouseleave="exithover = false" v-if="exithover==true"  href="">خروج از حساب </a>
                    </transition>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name : 'Sidebar',
        data () {
            return{
                parenthover:false,
                exithover:false,
            sidebar : [
                {
                    img : require('../../assets/icons/dashboard.svg'),
                    title : this.state.lang.sidebar.dashboard,
                    addres: '/Dashboard',
                    routername: 'Dashboard',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/activeorders.svg'),
                    title : this.state.lang.sidebar.activeOrders,
                    addres: '/dashboard/Activeorders',
                    routername: 'Activeorders',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/account.svg'),
                    title : this.state.lang.sidebar.account,
                    addres: '/dashboard/Account',
                    routername: 'Account',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/wallet.svg'),
                    title : this.state.lang.sidebar.wallet,
                    addres: '/Dashboard/Wallet',
                    routername: 'Wallet',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/history.svg'),
                    title : this.state.lang.sidebar.history,
                    addres: '/Dashboard/History',
                    routername: 'History',
                    hover:false,
                },
                {
                    img : require('../../assets/icons//setting.svg'),
                    title : this.state.lang.sidebar.setting,
                    addres: '/Dashboard/Setting',
                    routername: 'Setting',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/notification.svg'),
                    title : this.state.lang.sidebar.notifications,
                    addres: '/Dashboard/Notifications',
                    routername: 'Notifications',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/invite-friends.svg'),
                    title : this.state.lang.sidebar.inviteFriends,
                    addres: '/Dashboard/Invitefriends',
                    routername: 'Invitefriends',
                    hover:false,
                },
                {
                    img : require('../../assets/icons/ticket.svg'),
                    title : this.state.lang.sidebar.tickets,
                    addres: '/Dashboard/Tickets',
                    routername: 'Tickets',
                    hover:false,
                },
            ]
            }
        },
        methods : {
            push (value) {
                this.$router.push({ path :value});
            }
        }
    }
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0 !important;
}
.sidebar-size{
    overflow: scroll;
}
.active{
    background: var(--secondary-color);
    img{
        background: white;
    }
    a{
        font-size: clamp(14px,2vw,18px);
        color: white;
        max-height: 48px;
    }
}
    img{
        background: var(--primary-color);
        border-radius: 4px;
        width: 25px;
        height: 30px;
    }
.sidebar-size{
    order: 0;
    position: relative;
    overflow-y: scroll;
    height: 100%;
    width: 10%;
    max-width: 48px;
    z-index: 15;
    }
.sidebar-container{
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    position: fixed;
    max-width: 48px;
    height: 84%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // overflow: scroll;
    background: white;
    z-index: 16;
}
.sidebar-link-container-en{
    right: unset !important;
    left: 40px !important;
}
.sidebar-link-container{
    
    // box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    position: fixed;
    height: 80%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // overflow: scroll;
    right: 40px;
    width: 100%;
    max-width: 162px;
    .sidebar-item{
        height: 100%;
    }
    a{
        // opacity: .5;
        height: 30px;
        box-sizing: border-box;
    padding: 5px;
    z-index: 75;
    }
}
    a{
        // opacity: .5;
        height: 30px;
        box-sizing: border-box;
    padding: 5px;
    z-index: 75;
    }

a{
    font-weight: 400;
    font-size: clamp(10px,1.5vw,14px);
    background: #A4C6DE;
    position: absolute;
    max-width: 162px;
    width: 100%;
    color: white;
    border-radius: 4px;
    &:hover{
        transform: none;
    }
}
.sidebar-item{
    display: flex;
    flex-direction: column;
    img{
        cursor: pointer;

    }
}
.exit{
    display: flex;
    flex-direction: row;
    padding: 15px 19px;
    box-sizing: border-box;
    justify-content: center;
    img{
        cursor: pointer;
    }
    a{
        text-align: center;
        color: white;
    }
}
.item-container{
    // position: relative;
    box-sizing: border-box;
    padding: 15px 16px;
    display: flex;
    flex-direction: row;
    z-index: 15;
    column-gap: 5px;
    justify-content: center;
    // align-items: center;
    height: 57px;

}
@media only screen and(max-width:700px){

    .sidebar-container{
        width: 55px;
    }
    .sidebar-size{
        width: 55px;
    }
    a{
            display:  none;
    
    }
    .active{

        a{
            font-size: clamp(10px,1vw,14px);
    
        }
    }
    
}
</style>