<template>
    <transition name="fade">
        <div class="modal-backdrop" @click.self="$emit('closeChargeModaleEmit')">
          <div class="modal">
              <img @click="$emit('closeChargeModaleEmit')" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
              <div class="title">
                  <h1>{{state.lang.changePasswordTitle.title}}</h1>
                  <p>{{state.lang.changePasswordTitle.body}}</p>
              </div>
              <form @submit.prevent="submit" class="content">
                <label @click="blanckspace.expassword = true" class="passlabel" for="">
                    <input @focus="blanckspace.expassword = true" v-model="data.expassword" class="password" name="password" :type="expasstype" >
                    <span class=" password" :class="[{'focus':(blanckspace.expassword)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.changePasswordTitle.oldPassword}}</span>
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="expasstype=='password'" class="password" @click="expasstype='text'"  src="../../../assets/icons/noun_Eye_4382116.svg" alt="">
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="expasstype=='text'" @click="expasstype='password'"  src="../../../assets/icons/seepassword.svg" alt="">
                </label>
                <label @click="blanckspace.password = true" class="passlabel" for="">
                    <VuePassword
                            v-model="data.password"
                            id="password1"
                            @focus="blanckspace.password = true"
                            @input="updateStrength(data.password)"
                            :strength="strength"
                            :type="passtype"
                        />
                        <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passtype=='password'" class="pass" @click="passtype='text'"  src="../../../assets/icons/noun_Eye_4382116.svg" alt="">
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passtype=='text'" @click="passtype='password'" class="pass"  src="../../../assets/icons/seepassword.svg" alt="">
                    <span class="pass-span password" :class="[state.lan=='Fa' ? 'pass-span-fa' : 'pass-span-en',{'focus':(blanckspace.password)}]">{{state.lang.changePasswordTitle.newPassword}}</span>
                </label>
                <label @click="blanckspace.passwordrepeat = true" class="passlabel" for="">
                    <input @focus="blanckspace.passwordrepeat = true" v-model="data.passwordrepeat" class="password" name="password" :type="passrepeattype" >
                    <span class="password" :class="[{'focus':(blanckspace.passwordrepeat)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.changePasswordTitle.repeatPassword}}</span>
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passrepeattype=='password'" class="password" @click="passrepeattype='text'"  src="../../../assets/icons/noun_Eye_4382116.svg" alt="">
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passrepeattype=='text'" @click="passrepeattype='password'"  src="../../../assets/icons/seepassword.svg" alt="">
                </label>
             <button :disabled="!data.expassword || !data.password || !data.passwordrepeat" class="submit">{{state.lang.changePasswordTitle.btn}}</button>
              </form>
          </div>
        </div>
    </transition>
</template>

<script>
import VuePassword from 'vue-password';
export default {
  name: 'Sellmodal',
  components:{
    VuePassword  
  },
  data() {
      return{
                strength:0,
                expasstype:'password',
                passtype:'password',
                passrepeattype:'password',
                close : true ,
                blanckspace : {
                    expassword : false,
                    password : false,
                    passwordrepeat:false,
                },
                data:{
                    expassword:'',
                    password:'',
                    passwordrepeat:'',
                },
                expasswordError:'',
                passwordError:'',
                passwordRepeatError:'',
            }
  },
  props:{
      text:String,
      pic:String,
  },
  methods : {
            checkPass() {
                if (!this.data.password) {
                    this.passwordError = 'رمز اجباری';
                } else {
                    if (this.data.password.length < 8) {
                        this.passwordError = 'حداقل 8 کاراکتر';
                    } else if (!this.$validPass(this.data.password)) {
                        this.passwordError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else if(this.data.password!==this.data.passwordrepeat){
                        this.passwordRepeatError = 'تکرار رمز عبور اشتباه است';
                        return false;
                    } else {
                        this.passwordError = ''
                    }
                }
                if (!this.passwordError.length) {
                    return true;
                }
            },
            async submit() {
                
                let passSent = {
                    oldPassword: this.data.expassword,
                    password: this.data.password
                }
                if (this.data.password && this.data.passwordrepeat){
                if (this.checkPass())
                    this.state.loading = true
                    try {
                        const res = await this.$axios.post(`/users/change-password`, passSent)
                        if (res.message === "password changed successfully") {
                            this.$error('رمز شما تغییر یافت','','success')
                            this.data.expassword = ''
                            this.data.password = ''
                            this.data.passwordrepeat = ''
                        }
                    } catch (error) {
                        if (error.response.data.message === 'Old Password Is Incorrect') {
                            this.passError = 'رمز قبلی اشتباه است.'
                        }
                    }
                }
            },
            updateStrength(pass){
                if (pass.match(/[a-z]+/) || pass.match(/[0-9]+/) || pass.match(/[A-Z]+/)){
                    this.strength=1;
                    console.log(this.passtype);
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[0-9]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/))){
                    this.strength=2;
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/))){
                    this.strength=3;
                }
                if (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)){
                    this.strength=4

                    }
                    if(pass==''){
                        this.strength=0
                    }
            },
            closeModale() {
                this.$emit('closeChargeModaleEmit' , false)
            },
  },
  }
</script>

<style lang="scss" scoped>
.passlabel{
    img{
        position: absolute;
        bottom: 10px;
        left: 15px;
        cursor: pointer;
        max-height: 16px !important;
        max-width: 16px !important;
    }
}
.pass{
    bottom: 22px !important;
}
.close{
    cursor: pointer;
}
.submit{
    width: 100%;
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
label{
    position: relative;
    cursor: text;
    width: 100%;
    span {
    transition: 1s;
        // z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
h1{
    color: var(--secondary-color);
    font-weight: 700;
    font-size: clamp(20px,1vw,24px);
}
.title{
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    align-items: center;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    box-sizing: border-box;
    padding: 16px;
    justify-content: center;
    align-items: center;
    img{
        max-width: 59px;
        max-height: 43px;
    }
}
.close{
    align-self: flex-start;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 160px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>