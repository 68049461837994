<template>
    <div class="otpemail">
        <form @submit.prevent="send" action="">
                <h1> {{state.lang.forgotPass.title}} </h1>
                <p>{{state.lang.forgotPass.body}}</p>
                <label for="">
                    <input @focus="toggleFocus" v-model="email" name="email" type="text" >
                    <span :class="[{'focus':(blanckspace.email)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.forgotPass.userName}}</span>
                </label>
                 <div v-if="emailError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{emailError}}</p>
                </div>
                <button :disabled="!email" class="submit">{{state.lang.forgotPass.btn}}</button>
        </form>
    </div>
</template>

<script>
    const goto = {
        EMAIL: '/Forgot-pass-otp-email',
        SMS: '/Forgot-pass-otp-sms',
        GOOGLE_ATHENTICATOR:'/Forgot-pass-otp-googleathenticator'
    }
    export default {
        name : 'Forgotpass',
        close : true ,
        data () {
            return{
                email: '',
                emailError: '',
                unfocus : true,
                blanckspace : {
                    email : false,
                    password : false,
                    invitecode : false,
                    recaptcha : false
                },
            }
        },
        methods: {
            checkEmail() {
                if (!this.email) {
                    this.emailError = this.state.lang.formsError.blanckEmail;
                } else if (!this.validEmail(this.email)) {
                    this.emailError = this.state.lang.formsError.truemail;
                } else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            handleSubmit () {
                 this.$router.push('/Forgot-pass-otp')
            },
            toggleFocus () {
                this.blanckspace.email=true ;
                this.unfocus=true;
            },
            async send() {
                if (this.checkEmail(this.email)) {
                    await this.checkEmail()
                    this.state.loading = true
                    const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                    this.$cookies.set('username', this.email)
                    this.state.userInfo.email = this.email
                    if (res.baseDTO) {
                        this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                        this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                    } else {
                        this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                    }
                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
.otpemail{
    // max-width: 336px;
    width: 35%;
    box-sizing: border-box;
    
}
.submit{
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 206px;
}
  @media only screen and (max-width:900px){


      .otpemail{
          width: 70%;
        padding: 0 20px;
      }
}
  @media only screen and (max-width:550px){

      .otpemail{
          width: 100%;
        padding: 0 20px;
      }
}
</style>