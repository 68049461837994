<template>
    <div class="otpemail">
        <Simplemodal v-if="loading" :text="state.lang.otpEmail.modal" :pic="require('../../assets/icons/loading.svg')" />
            <form @submit.prevent="submit" action="">
                <h1>{{state.lang.otpEmail.title}}</h1>
                <p>{{state.lang.otpEmail.body}}</p>

                <CodeInput 
                    :loading="false" 
                    class="verif-code" 
                    v-on:change="onChange" 
                    v-on:complete="submit" 
                    @complete="submit"
                     />

                <button :disabled="!password" class="submit">{{state.lang.otpEmail.btn}}</button>
            </form>

    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import Simplemodal from '../../components/Tools/Simplemodal.vue'
 import {setPlaceholder,reset,paste} from "@/library/reuseableFunction";


    export default {
        name : 'Otpemail',
        components : {
            CodeInput,
            Simplemodal,
        },
        data() {
            return{
                
                close : true ,
                error: '',
                code: '',
                loading:false,
                otp: {
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username')
                }
            }
        },
        methods: {
            onChange(v) {
                this.otp.code = v
            },
            async submit() {
                if (!this.disabled) {
                    this.loading = true
                    const res = await this.$axios.post('/users/check-otp-code', this.otp).catch(()=>{})
                    this.reset()
                    this.loading = false
                    if (res.token) {
                        await this.$setToken(res.token)
                        this.$emit('closeModaleEmit' , this.close)
                        await this.$router.push({name: 'Validation'})
                    }
                }
            },
            setPlaceholder,
            reset,
            paste,
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted (){
            this.setPlaceholder()
            if (!this.otp.email) {
                this.$router.push('/login')
            }
        }
    }
</script>

<style lang="scss" scoped>
.otpemail{
    // max-width: 336px;
    width: 35%;
    box-sizing: border-box;
    
}
.submit{
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 206px;
}
  @media only screen and (max-width:900px){


      .otpemail{
          width: 70%;
        padding: 0 20px;
      }
}
  @media only screen and (max-width:550px){

      .otpemail{
          width: 100%;
        padding: 0 20px;
      }
}
</style>