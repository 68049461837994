<template>
    <div class="mainpage">
        <div class="info">
            <div class="market">
                <div class="btns">
                    <button @click="changeInfo(false)" :class="{'submit':!p2p}" class="change-market"> {{state.lang.navbar.exchange}} </button>
                    <button @click="changeInfo(true)" :class="{'submit':p2p}" class="change-market"> {{state.lang.navbar.p2p}} </button>
                </div>
                <div class="content">    
                    <div class="view">
                        <WalletChart :key="p2p" :marketType="p2p" />
                        <Cards />
                    </div>
                    <div class="market-container">
                        <Financetable :p2p="p2p" :coins="wallets" />
                    </div>
                </div>
            </div>
            <div class="access">
                <div class="profile">
                    <img v-if="status==false" class="profilepic" src="../../../assets/icons/noprofile.png" alt="">
                    <img v-if="status==true" class="profilepic" src="../../../assets/icons/comleteprof.png" alt="">
                    <p class="email">{{state.userInfo.email}}</p>
                    <!-- <div class="error-type" v-if="status==false">
                        <img src="../../../assets/icons/error.svg" alt="">
                        <p class="error" v-if="notcompleted==errors.bankinfo.type">{{errors.bankinfo.text}}</p>
                        <p class="error" v-if="notcompleted==errors.accountinfo.type">{{errors.accountinfo.text}}</p>
                    </div> -->
                    <button @click="$router.push({name :'Account'})" v-if="!status" class="submit"> {{state.lang.dashboardAccess.accountLevel}}: {{$level[state.userInfo.userAccountLevel]}}</button>
                </div>
                <div class="friends">
                    <p class="title">{{state.lang.dashboardAccess.friendsTitle}}</p>
                    <a @click.prevent="" href=""> {{inviteInfo.countInvitees}}<p>{{state.lang.dashboardAccess.personNum}}</p></a>
                    <h6>{{state.lang.dashboardAccess.totalBenefits}}</h6>
                    <h3 class="positive" >{{$toLocal(inviteInfo.profit,0)}}<h3>{{state.lang.dashboardAccess.coinType}}</h3></h3>
                    <button @click="$router.push({name :'Invitefriends'})" class="submit">{{state.lang.dashboardAccess.friendsBtn}}</button>
                </div>
            </div>
        </div>
        <div class="orders">
            <Activeorders />
            <Orderhistory />
        </div>
    </div>
</template>

<script>
import Activeorders from './Activeorders.vue';
import Cards from '../Cards.vue';
import Financetable from './Financetable.vue';
import Orderhistory from './Orderhistory.vue';
import WalletChart from '../WalletChart.vue';
    export default {
        name : 'Mainpage',
  components: {
       WalletChart,
       Cards,
    Financetable,
    Activeorders,
    Orderhistory,
  
  }, 
    data ( ) {
        return{
            update:false,
            p2p:true,
            params:{
                walletType:this.p2p?'P2P':'EXCHANGE'
            },
            wallets:[],
            inviteInfo:[],
            status:false ,
            errors: {
                bankinfo:{
                type:'bankinfo',   
                text:this.state.lang.dashboardAccess.accountBankError,
                    
                },
                accountinfo:{   
                    type:'accountinfo:',
                    text:this.state.lang.dashboardAccess.accountError,
                }
            },

        }
    },
    methods:{
        changeInfo(e){
            this.p2p=e;
            this.params.walletType=this.p2p?'P2P':'EXCHANGE';
            this.getWallets()
        },
        async getInfo(){
            this.state.loading=true;
            const res = await this.$axios.get('/users/invites')
            this.inviteInfo = res.baseDTO
            this.state.loading=false;
        },
        async getWallets() {
            // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
            const res = await this.$axios.get('/wallets/customer-addresses',{params:this.params})
            let entireRes = Object.entries(res)
            const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.wallets = entireRes
            this.update=!this.update
            console.log(this.wallets);
        },
    },
    mounted(){
        this.getWallets();
        this.getInfo();
    }
    }
</script>

<style lang="scss" scoped>
.btns{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    .change-market{
        width: 50%;
        cursor: pointer;
    }
}
.signedup{
    background: var(--banner-primary);
}
.error-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}
.access{
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 16px;
    // height: 100%;
    height: 35%;
}
.profile{
    padding: 18px 16px !important;
    .email{
        font-weight: 700;
        font-size: clamp(10px,1vw,14px);
    }
    .error{
        color: rgba(255, 0, 92, 1);
        font-weight: 300;
        font-size: clamp(10px,1vw,12px);
    }
    img{
        max-height: 89px;
    }
}
.friends,.profile{
        box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        box-sizing: border-box;
        padding: 18px 16px;
        width: 50%;
        a,.positive{
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 5px;
            align-items: center;
        }
        h3{
                        font-size: clamp(18px,3vw,24px);
            font-weight: 700;
        }
        h6{
            font-size: clamp(14px,1vw,18px);
            font-weight: 700;
        }
        .title{
            font-weight: 700;
            font-size: clamp(10xpx,1vw,14px);
        }
        .profilepic{
            max-width: 89px;
            max-height: 89px;
            align-self: center;
        }
    }
    .friends{
            row-gap: 8px;
    }
.mainpage{
    // max-width: 596px;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    // flex-wrap: wrap;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    height: 100%;
    // &::-webkit-scrollbar{
    //     display: none;
    // }
    .market-container{
        
        width: 100%;
    }
.info{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 98%;
    row-gap: 16px;
    justify-content: space-between;
    .market{   
        display: flex;
        box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px;
        width: 100%;
        // overflow-y: scroll;
        min-height: 422px;
        height: 60%;
        flex-grow: 1;
        // justify-content: space-between;
        row-gap: 10px;
        .content{
            overflow: hidden;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }

}
}
    .view{
        box-sizing: border-box;
        // padding: 1px 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        height: 100%;
        align-items: center;
    }
.orders{
            display: flex;
            flex-direction: column;
            // height: 100%;
            row-gap: 16px;
            justify-content: space-between;
            width: 40%;
            height: 98%;
}
}
@media only screen and(max-width:1150px){
    .mainpage{
        flex-direction: column !important;
        height: unset;
        justify-content: center;
        row-gap: 16px;
    }
    .info,.orders{
        width: 100% !important;
    }
    
}
@media only screen and(max-width:1150px){
        .view{
            flex-direction: column !important;
        justify-content: space-between;
        row-gap: 10px;
        align-items: center;
        width: 100%;
        }
    
    
}
// @media only screen and(max-width:1270px){
//     .mainpage{
//         // flex-direction: column;
//         // row-gap: 16px;
//     }
// }
// @media only screen and(max-width:1100px){
//     .view{
//         // flex-direction: column !important;
//         // align-items: center;
//     }
// }
@media only screen and(max-width:700px){
        .profile{
            row-gap:8px ;
        }
    
}
@media only screen and(max-width:500px){
           .profile{
            row-gap:16px ;
        }
    .access{
        flex-direction: column;
        row-gap: 16px;
        div{
            width: 100%;
        }
    }
}
</style>