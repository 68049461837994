<template>
    <div class="navbar">
        <div class="navbar-container">
            <div class="sidebar inwidth">
                <img v-if="!navbartoggle" @click="handleToggle" class="icon"  src="../../assets/icons/icons8-menu-48.png" alt="">
                <transition :name="state.lan=='Fa' ? 'fade' :'fadeEn'">
                <div v-if="navbartoggle" :class=" state.lan=='Fa' ? 'sidebar-items' :'sidebar-items-en'">
                    <img @click="handleToggle" class="icon" :class="{'outopacity':!navbartoggle}" src="../../assets/icons/icons8-close-40.png" alt="">
                    <div class="item-container">
                    <a @click.prevent="reDirect(item.addres)"  v-for="(item,index) in navbaritems" :key="index" :href="item.addres">{{item.title}}</a>
                    <span @click="state.changelanmodal=true" class="change-lan">{{this.state.lang.navbar.changeLang}}</span>
                    </div>
                </div>
                </transition>
            </div>
            <div class="navbar-logo">
                <img @click="$router.push({name:'Homepage'})" src="../../assets/Logo/Zeen-Currency.svg" alt="">
            </div>
            <div class="navbar-items outwidth">
                <a @click.prevent="reDirect(item.addres)"  v-for="(item,index) in navbaritems" :key="index" :href="item.addres">{{item.title}}</a>
                <span @click="state.changelanmodal=true" class="change-lan">{{this.state.lang.navbar.changeLang}}</span>
            </div>
            <div class="navbar-signup">
                <div class="notifs-container submit">
                    <img @click="showbtn=!showbtn" src="../../assets/icons/whitenotificon.svg" alt="">
                    <div v-if="showbtn==true" :class="{'notifs-en':state.lan=='En'}" class="notifs">
                        <div class="btns">
                            <button @click="notifbtn=false" :class="[{'submit' : notifbtn==false},{'info':state.lan=='FA'}]">{{this.state.lang.navbar.tickets}}</button>
                            <button @click="notifbtn=true" :class="[{'submit' : notifbtn==true},{'tickets':state.lan=='FA'}]">{{this.state.lang.navbar.notifications}}</button>
                        </div>
                        <div  class="content">
                                <div v-for="(notif,index) in notifications" :key="index" class="notif">
                                    <div class="header">
                                        <div class="title">
                                            <span @click="notif.checked=!notif.checked" :class="{'checked': notif.checked}" class="checkbox" name="terms"></span>
                                            <span>{{notif.title}}</span>
                                        </div>
                                        <div class="time">
                                            <span>{{notif.year}}/{{notif.mounth}}/{{notif.day}}</span>
                                            <span>-</span>
                                            <span>{{notif.hours}}:{{notif.minute}}</span>
                                        </div>
                                    </div>
                                    <p>{{notif.text}}</p>
                                    <div class="border-bottom"></div>
                                </div>
                        </div>
                    </div>
                </div>
                <div @click="dropdownbtn=!dropdownbtn" class="user-btn submit">
                <span>{{username}}</span>
                <img src="../../assets/icons/noprofile.svg" alt="">
                <div v-if="dropdownbtn==true"  class="dropdown">
                    <div @click.prevent="$router.push({name:'Dashboard'})"  class="menu-items">
                        <a href="">{{this.state.lang.navbar.dashboard}}</a>
                        <img :class="{'en-rotate':state.lan=='En'}" src="../../assets/icons/listicon.svg" alt="">
                    </div>
                    <div @click.prevent="$router.push({name:'History'})" class="menu-items">
                        <a href="">{{this.state.lang.navbar.account}}</a>
                        <img :class="{'en-rotate':state.lan=='En'}" src="../../assets/icons/listicon.svg" alt="">
                    </div>
                    <div @click.prevent="$router.push({name:'Account'})" class="menu-items">
                        <a href="">{{this.state.lang.navbar.validation}}</a>
                        <img :class="{'en-rotate':state.lan=='En'}"  src="../../assets/icons/listicon.svg" alt="">
                    </div>
                    <div @click="Exit" class="menu-items">
                        <a @click.prevent="" href="">{{this.state.lang.navbar.exit}}</a>
                        <img :class="{'en-rotate':state.lan=='En'}" src="../../assets/icons/listicon.svg" alt="">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Dashboardnavbar',
        data () {
            return {
                dropdownbtn:false,
                showbtn:false,
                notifbtn:false,
                username: this.state.userInfo.firstName ? this.state.userInfo.firstName+' '+this.state.userInfo.lastName : this.$cookies.get('username'),
                notifications:[
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:false,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:false,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:false,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:false,
                    },
                ],
                 navbaritems : [
                {
                    title : this.state.lang.navbar.exchange,
                    addres : 'Exchange'
                },
                {
                    title : this.state.lang.navbar.p2p,
                    addres : 'Advancetrade',
                },
                {
                    title : this.state.lang.navbar.market,
                    addres : 'Market'
                },
                {
                    title : this.state.lang.navbar.fee,
                    addres : 'Fees'
                },
                {
                    title : this.state.lang.navbar.support,
                    addres : 'Contactus'
                },
                {
                    title : this.state.lang.navbar.blog,
                    addres : ''
                },
            ],
            navbartoggle : false ,
        
            }
        },
        methods : {
            handleToggle (){
                this.navbartoggle=!this.navbartoggle
            },
            Exit(){
                this.$emit('close');
            },
            reDirect(addres){
                if(addres=='Advancetrade'){
                    console.log('fuck off');
                    this.$router.push('/Advancetrade');
                } else {
                    this.$router.push({ name : addres});
                }
                console.log(addres);
            },
            async check() {
                this.state.loading = true;
                const res = await this.$axios("/users/account",{params:{'image':true}})
                this.state.userInfo = res.baseDTO
                this.state.userInfo.fullName=`${this.state.userInfo.firstName }`+' '+`${this.state.userInfo.lastName}`
            },
        },
        created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.user-btn,.notifs-container')) {
              this.dropdownbtn=false;
              this.showbtn=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style scoped lang="scss">
.en-rotate{
    transform: rotate(-90deg) !important;
}
.change-lan{
    cursor: pointer;
    font-size: clamp(10px,1vw,14px);
      font-weight: 700;
      color: rgba(0, 119, 204, 1);
      text-align: center;
      transition: 500ms !important;
  &:hover{
    transform: scale(1.1,1.1);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: margin-right 0.5s ease;
}

.fade-enter, .fade-leave-to {
  margin-right: -200px;
}
.fadeEn-enter-active, .fadeEn-leave-active {
  transition: margin-left 0.5s ease;
}

.fadeEn-enter, .fadeEn-leave-to {
  margin-left: -200px !important;
}
.login:hover{
    background: var(--secondary-color);
    color: var(--primary-color);

}
.dropdown{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px;
    // box-shadow: 4px 4px 24px rgba(0, 119, 204, 0.2);
    background: var(--primary-color);
    width: 100%;
    position: absolute;
    border-radius: 4px;

    top: 40px;
    .menu-items{
        img{
            transform: rotate(90deg);
        }
        background: white;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
    .sidebar-items-en{
        margin-left: 0;
        left: 0;
        overflow: scroll;
        width: 150px;
        position: fixed;
        bottom: 0;
        height: 100vh;
        padding-bottom: 20px ;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background:var(--primary-color) ;
            box-shadow: -6px 0 15px 10px rgba(202, 202, 202, 0.685);
            z-index: 10000000;
        height: 100%;
        img{
            padding:15px ;
        }
    }



.checkbox{
    cursor: pointer;
    width: 16px;
        height: 16px;
        border-radius:4px ;
}
.checked{
        background: var(--banner-primary);
    border: none !important;
    width: 18px;
    height: 18px;

    }
.notifs-en{
    right: 100px !important;
    left: unset !important;
}
.notifs{
    background: white;
    box-shadow: 4px 4px 24px rgba(0, 119, 204, 0.2);
    border-radius: 3px;
    color: rgba(61, 73, 204, 1);
    position: absolute;
    left: 0px;
    width: 150%;
    top: 50px;
    height: max-content;
        .border-bottom{
            width: 80%;
            margin-top: 8px;
            justify-self: center;
            align-self: center;
            // height: 4px;
            border-bottom: 0.4px solid #0077cc7e;
        }
    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px;
        .notif{
            .title{
                // width: 10%;
                display: flex;
                flex-direction: row;
                column-gap: 5px;
            }
            box-sizing: border-box;
            // padding: 16px;
                display: flex;
    flex-direction: column;
    p{
        font-weight: 100;
                font-size: clamp(9px,1vw,13px);
    }
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 900;
                font-size: clamp(10px,1vw,14px);

            .time{
                font-weight: 300;
                font-size: clamp(9px,1vw,13px);
                display: flex;
                flex-direction: row;
                column-gap: 5px;
            }
        }
    }
    .btns{
        width: 100%;
        .info{
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
        .tickets{
            border-top-right-radius:0 !important;
            border-bottom-right-radius: 0 !important;
        }
        button{
            width: 50%;
            cursor: pointer;
        }
    }
}
    .navbar-signup{
        position: relative;
        width: 17%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 6px;
        // justify-content: ;
        border-radius: 4px;
        column-gap: 16px;
        height: 32px;
        .user-btn{
            max-width: 180px;
            column-gap: 10px;
            width: 80%;
            border-radius: 4px;
            height: 32px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
        }
        .notifs-container {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        span{
            margin-top: 5px;
        }
    img{
        height: 18px;
        width: 18px;
        background: transparent;
    }
    }   
.navbar-logo{
    img{
        cursor: pointer;
    }
}
.submit{
    width: 100%;
}
    .sidebar{
            width: 10%;
    }
    .sidebar-items{
        right: 0;
        overflow: scroll;
        width: 150px;
        position: fixed;
        bottom: 0;
        height: 100vh;
        padding-bottom: 20px ;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background:var(--primary-color) ;
            box-shadow: -6px 0 15px 10px rgba(202, 202, 202, 0.685);
            z-index: 10000000;
        height: 100%;
        img{
            padding:15px ;
        }
    }
    .navbar {
        position: sticky;
        z-index: 30;
        top:0;
        box-sizing: border-box;
        // padding: 2px 10px;
        max-height: 81px;
        display: flex;
        justify-content: center;
        flex-direction: row ; 
        width: 100%;
        align-items: center;
        background:var(--primary-color) ;
        // min-height: 75px;
    }
      .item-container{
        display: flex;
        flex-direction: column;
        row-gap: 45px;
        height: 100%;
        padding-top: 32px;

        }
        .navbar-items{

        width: 40%;
        justify-content: space-between;
        display: flex;
        // text-align: center;
    }
    .navbar-container{
             display: flex;
             max-width: 1440px;
        flex-direction: row;
        width: 100%;
        padding: 0 10px;
        justify-content: space-between;
        // padding: 20px 0;
        box-sizing: border-box;
        align-items: center;
    }
    @media only screen and (max-width:850px){
        .navbar-logo{
            img{
                width: 100%;
            }
        }
        .navbar-signup{
            width: 50%;
        }

    }
        @media only screen and (max-width:460px){
            .user-btn{
                padding: 0 !important;
            }
            .dropdown{
                width: 110% !important;
            }
          .navbar-signup{
      a{

          padding: 3px 8px;
      }
        }
    }
            @media only screen and (max-width:340px){

          .navbar-signup{
      a{

          padding: 3px 3px;
      }
        }
        .navbar-logo{
            width: 50%;
        }
    }
              @media only screen and (max-width:300px){

          .navbar-signup{
              column-gap: 15px;
        }
    }
    
    

</style>       