<template>
    <transition name="fade">
        <div :class="{'change-justify':state.lan=='En'}" class="modal-backdrop" >
          <div class="modal">
              <img class="close" @click="closeModale" src="../../../assets/icons/icons8-close.svg" alt="">
              <div class="text">
                  <h4>{{state.lang.dashboardalert.title}}</h4>
                  <p>{{state.lang.dashboardalert.body}}</p>
              </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Sellmodal',
  data() {
      return {
      }
  },
  props:{
      text:String,
      pic:String,
  },
  methods : {
      closeModale() {
        this.$emit('closenotif' , false)
      },
  },
  mounted (){
      // setTimeout(this.closeModale,2000)
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal')) {
              this.$emit('closenotif' , false);
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
.close{
  cursor: pointer;
}
.change-justify{
  justify-content: start !important;
}
.text{

    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: end;
    align-items: flex-start;
    z-index: 110;
  }
  .modal {
    
    display: flex;
    // flex-grow: 1;
    row-gap: 8px;
    padding: 8px;
    box-sizing: border-box;
    width: 75%;
    background: #B2DFFF;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    // margin: 24px;
    margin-top: 100px;
    margin-left: 100px;
    box-sizing: border-box;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
</style>