<template>
    <div class="tradingview">
        <div class="header-table">
            <table>
            <thead>
                <tr>
                    <td>
                        <div style="justify-content:center" class="icon-container">
                        <img :src="'/coins/' +$route.params.tradeFrom+ '.png'" alt="">
                        <!-- <img src="../../assets/coins/BITCOIN.png" alt=""> -->
                        </div>
                    </td>
                    <td>
                        <div class="icon-container">
                            <span>{{$route.params.tradeFrom}}</span>
                            <span>-</span>
                            <span v-if="state.lan=='Fa'">{{priceTypeTransform[$route.params.tradeTo]}}</span>
                            <span v-if="state.lan=='En'">{{$route.params.tradeTo}}</span>
                        </div>
                    </td>
                    <td> {{state.lang.advanceTrade.header.changes}} </td>
                    <td class="remove"> {{state.lang.advanceTrade.header.max}} </td>
                    <td class="remove"> {{state.lang.advanceTrade.header.min}} </td>
                    <td style="width:13%"> {{state.lang.advanceTrade.header.volume}} </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td style="text-align:start" :class="{'positive':data.last24HourChange>0,'negetive':data.last24HourChange<0}">{{$toLocal(data.lastPrice,$decimal[$route.params.tradeTo])}}</td>
                    <td v-if="state.lan=='En' && data.last24HourChange>0" class="positive">+{{Math.abs($toLocal(data.last24HourChange,2))}}%</td>
                    <td v-if="state.lan=='En' && data.last24HourChange<0" class="negetive">-{{Math.abs($toLocal(data.last24HourChange,2))}}%</td>
                    <td v-if="state.lan=='Fa' && data.last24HourChange>0" class="positive">{{Math.abs($toLocal(data.last24HourChange,2))}}%+</td>
                    <td v-if="state.lan=='Fa' && data.last24HourChange<0" class="negetive">{{Math.abs($toLocal(data.last24HourChange,2))}}%-</td>
                    <td v-if="data.last24HourChange==0">0</td>
                    <td class="remove">{{$toLocal(data.maxPrice,$decimal[$route.params.tradeTo])}}</td>
                    <td class="remove">{{$toLocal(data.minPrice,$decimal[$route.params.tradeTo])}}</td>
                    <td>{{$toLocal(data.last24HourVolume,$decimal[$route.params.tradeTo])}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="content">
            <Tradingchart :coinType="coin" />
            <Buysell :key="'Buysell'+$route.params.tradeFrom+$route.params.tradeTo"/>
            <Activeorders />
        </div>
    </div>
</template>

<script>
import {Loop,tradeTo,tradeFrom,precision} from "@/library/reuseableFunction";
import Activeorders from './Activeorders.vue'
import Buysell from './Buysell.vue'
import Tradingchart from './Tradingchart.vue'

    export default {
        name: 'Tradingview',
        props:['priceType','coin'],
        components : {
                Tradingchart,
                Buysell,
                Activeorders,
        },
                data () {
                    return{
                        loop:undefined,
                        data: {
                            maxPrice: 0,
                            minPrice: 0,
                            lastPrice: 0,
                            last24HourVolume: 0,
                            last24HourChange: 0,
                        },
                        priceTypeTransform:{
                            TOMAN:'تومان',
                            TETHER:'تتر'
                        },
                    //     headercoin:{
                    //         img: require('../../assets/coins/'+this.coin+'.png'),
                    //         coinname:'',
                    //         toman:'تومان',
                    //         price:1258554000,
                    //         changes:1.27,
                    //         max:1836927000,
                    //         min:1210826000,
                    //         volume:78635948037,

                    // }
            }
        },
        methods:{
            async getTradeHeader() {
                const [res,] = await this.$http.get('/trades/market-info', {
                    params: {
                        weeklyDataIncluded: false,
                        market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                    }
                })
                this.loading = false
                if(res){
                    this.data = res.content[0] || this.data
                    this.state.shopInfo.lastPrice = this.data.lastPrice
                    this.state.shopInfo.change24 = this.data.last24HourChange
                    // document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}` + ' ｜ ' + this.$toLocal(this.data?.lastPrice,this.precision)
                }
            },
        },
        computed:{
            tradeFrom,
            tradeTo,
            precision
        },
        mounted(){
            this.loading=true
            // document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}`
            if (this.state.loop) {
                this.loop = new Loop(this.getTradeHeader,this.state.time,'tradeHeader:'+this.tradeFrom+'-'+this.tradeTo)
                this.loop.start()
            } else {
                this.getTradeHeader()
            }
            // this.headercoin.coinname=this.coin
        },
        beforeDestroy() {
            this.loop.stop();
        },
    }
</script>

<style lang="scss" scoped>
table{
    width: 100%;
    thead{
        font-weight: 700;
        font-size: clamp(10px,2vw,13px);
        color: rgba(63, 63, 63, .7);
        td{
            img{
        width: 22px;
        height: 22px;
    }
        }
    }
    tbody{
        font-size: clamp(10px,2vw,13px);
    }
}
.icon-container{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-weight: 300;
    font-size: clamp(11px,2vw,14px);
    color: black;
}
.tradingview{
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 596px;
}
.header-table{
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
}
.content{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
@media only screen and(max-width:850px){
    .tradingview{
        order: 1;
    }
}
@media only screen and(max-width:550px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:450px){
    .trade-container{
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>