<template>
    <transition name="fade">
        <div @click.self="closeModale" class="modal-backdrop" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
                <h1>{{state.lang.walletAdrresModal.title}} {{coinName}}</h1>
                <p v-if="selected_net">{{state.lang.walletAdrresModal.body1}} {{coinName}} {{state.lang.walletAdrresModal.body2}} </p>
                <p v-if="!selected_net">{{state.lang.walletAdrresModal.channel1}} {{coinName}} {{state.lang.walletAdrresModal.channel2}} </p>
                <div v-if="!selected_net" class="channel">
                                    <div @click="netDrop = !netDrop" class="selected-channel">
                                        <span v-if="selected_net">{{selected_net}}</span>
                                        <span v-else>انتخاب شبکه</span>
                                    </div>
                                    <div class="select-channel" v-if="netDrop">
                                        <div class="channel-item" v-for="(net,index) in nets" :key="index" @click="selectFun(index)">
                                            <span>{{net.tokenType}}</span>
                                        </div>
                                    </div>
                                </div>
                <div v-if="selected_net" class="chosen-channel">
                    <img src="../../../assets/icons/barcode.png" alt="">
                    <div class="links">
                                    <p>{{invitelink}}</p>
                                     <div class="qrcode" v-html="qrCode"></div>
                    </div>
                    <div class="note">
                            <img src="../../../assets/icons/error.svg" alt="">
                            <p>{{state.lang.walletAdrresModal.warning1}} {{coinName}} {{state.lang.walletAdrresModal.warning2}} </p>
                    </div>
                    <button @click="complete" class="submit">{{state.lang.walletAdrresModal.btn}} </button>
                </div>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Coinchargemodal',
  props :['coinType'],
  data() {
      return {
          coinName: this.state.lan=='Fa' ? this.coinType.persianName : this.coinType.coin ,
          selected_net:'',
          txIdIsNeeded:'',
          enable:'',
          drop:false,
          netDrop:false,
          nets:this.coinType.tokenTypeDetails,
          // coinType:'بیت کوین',
          address:'',
          qrCode:undefined,
          tag:'',
          close : false,
          blanckspace:false,
          money:5728361,
          unfocus : true,
          selected:false,
          topcardnumber:'شماره کارت',
          cardnumbers:[6037343455688999,6037343455688999,6037343455688999,6037343455688999],
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeChargeModaleEmit' , false)
      },
      async getQR() {
            this.state.loading = true
            const token = this.selected_net
            console.log(token)
            let params = {
                relatedCoin: this.coinType.coin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=P2P&tokenType=' + token, {
                params
            }).catch(() => {
                this.state.walletBtcDe = false
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
            this.state.loading=false
        },
      toggleFocus () {
                this.blanckspace=true ;
                this.unfocus=true;
            },
      cardToggle(){
        this.selected=!this.selected
      },
      selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.enable = this.nets[index].isDepositEnable || true
            this.netDrop = false
            if (this.enable) {
                this.getQR()
            }

        },
      choseCard(value){
        this.topcardnumber=value;
        this.selected=false;
      },
      complete() {
        navigator.clipboard.writeText(this.qrCode);
        this.$emit('completed' , true)
        this.closeModale()
      },
  },
  mounted () {
      // if(this.coinType==''){
      //   if(this.state.lan=='Fa'){
      //     this.coinType='تومان';
      //   } else {
      //     this.coinType='TETHER';
      //   }
      // }
      // if(this.state.lan=='Fa'){
      //    this.coinType=this.$coinLabel[this.coinType];
      // }
      // setTimeout(this.closeModale,3000);
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.buy,.modal')) {
              // this.$emit('closeChargeModaleEmit' , false)
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
       .channel{
        z-index: 20;
        cursor: pointer;
        position: relative;
        width: 60%;
        background: white;
        padding: 8px 16px;
        border: 0.4px solid #A4C6DE;
        border-radius: 4px;
        max-height: 32px !important;
        .selected-channel{
            font-size: clamp(10px,1vw,12px);
            color: var(--secondary-color);
        }
        .select-channel{
            position: absolute;
            right: 0;
            top: 40px;
            border-radius: 4px;
            font-size: clamp(14px,1vw,16px);
            color: var(--secondary-color);
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            width: 100%;
            .channel-item{
                padding: 5px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            span{
                background: var(--primary-color);
                box-sizing: border-box;
                padding: 5px;
                border-radius: 4px;
                width: 90%;
                text-align: center;
            }
        }
    }
   .chosen-channel{
     display: flex;
     width: 100%;
     flex-direction: column;
     row-gap: 16px;
     align-items: center;
     justify-content: center;
     img{
       max-width: 100px;
     }
   }
   p{
       text-align: start !important;
   }
   .links{
                    overflow: scroll;
                    color: var(--secondary-color);
                    border-radius: 4px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border: 0.4px solid #0077cc;
                    box-sizing: border-box;
                    padding: 8px;
                    p{
                        font-size: clamp(8px,1.6vw,16px);
                        text-align: start;
                    }
                
                }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
//    h1{
//        font-size: clamp(28px,3vw,36px);
//    }
   .note{
       display: flex;
       flex-direction: row;
       column-gap: 5px;
    //    align-items: center;
       color: var(--red-color);
       p{
           opacity: .8;
       }
       img{
           align-self: flex-start;
       }
   }
   .money{
       color: rgba(4, 24, 54, 0.342);
       font-weight: 300;
       font-size: clamp(10px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h1{
       color: var(--secondary-color);
       font-size: clamp(18px,1vw,24px);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 16px;
    align-items: center;
  }
</style>