<template>
    <div class="tickets">
        <Ticketmodal @send="closeTicketModal" @close="ticketModal=false" v-if="ticketModal" />
        <div v-if="!chatPage" class="tickets-container">
            <div class="title">
                <h1>{{state.lang.tickets.title}}</h1>
                <button @click="ticketModal=true" class="submit">{{state.lang.tickets.btn}}</button>
            </div>
            <div class="table">
                <table>
                    <thead>    
                        <tr>
                            <td class="remove" style="width:10%">#</td>
                            <td>{{state.lang.tickets.date}}</td>
                            <td class="remove-two">{{state.lang.tickets.ticketTitle}}</td>
                            <td class="remove" style="width:15%">{{state.lang.tickets.ticketSubject}}</td>
                            <td>{{state.lang.tickets.situation}}</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ticket,index) in tickets" :key="index">
                            <td class="remove">{{index}}</td>
                            <td> {{$G2J(ticket.createdAtDateTime)}} </td>
                            <td class="remove-two">{{ticket.subject}}</td>
                            <td class="remove">{{ticket.text}}</td>
                            <td :class="{'positive':ticket.status=='OPENED','finished':false, 'ispending':ticket.status=='PENDING' || ticket.status=='CUSTOMER_ANSWERED' || ticket.status=='ADMIN_ANSWERED'}">{{state.lan=='En' ? ticket.status : $ticketSituatio[ticket.status]}}</td>
                            <td @click="seeDetails(index)" class="seedetails">{{state.lang.tickets.seeDetails}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>    
        <Message @close="chatPage=false" :id="selectedId" v-if="chatPage" />
    </div>
</template>

<script>
import Message from './Message.vue'
import Ticketmodal from './Ticketmodal.vue'
    export default {
  components: { 
        Ticketmodal,
        Message,
       },
        name:'Ticekts',
       
        data () {
            return{
                selectedId:null,
                messageModal:false,
                chatPage:false,
                ticketModal:false,
                tickets:[],
                details:[],
                id:null,
            }
        },
        
        methods:{
            closeTicketModal(){
                this.ticketModal=false
                this.getTickets();

            },
            seeDetails(index){
                this.chatPage=true
                this.selectedId=this.tickets[index].id
            },  
            async getTickets() {
                    this.state.loading = true
                    const res = await this.$axios.get('/tickets?size=1000')
                    this.tickets = res.content
                    this.state.loading = false
            },
            async showMessage(index) {
            if (this.id == this.tickets[index].id) {
                this.id = ''
            } else {
                this.state.loading = true
                this.id = this.tickets[index].id
                const res = await this.$axios.get('/tickets/message/' + this.id)
                this.details = res.pageDTO.content.reverse()
                console.log(this.details);
                // this.reload = !this.reload
                this.state.loading = false
            }

            },
            async showMessageAgain(index) {
                this.state.loading = true
                const res = await this.$axios.get('/tickets/message/' + index)
                this.details = res.pageDTO.content.reverse()
                console.log(this.details);
                this.reload = !this.reload
                this.state.loading = false
            },
            async sendMessage() {
                let formData = new FormData
                formData.append('text', this.data.text)
                const res = await this.$axios.post('/tickets/message/' + this.id, formData)
                this.details.push(res.baseDTO)
                this.data.text = ''
                this.calcHeight("");
                this.showMessageAgain(this.id)

            },

        },
        mounted(){
            this.getTickets();
            this.showMessageAgain(113940)
        }
    }
</script>

<style lang="scss" scoped>
.chat{
    background: var(--primary-color);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .text{
        font-size: clamp(10px,1vw,12px);
        color: var(--secondary-color);
        opacity: .6;
    }
    .titles{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
}
.chatpage{
    .admin-chats{
        align-self: flex-end;
        width: 60%;
    }
    .chats{
            width: 60%;
    }
    
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}
.seedetails{
    cursor: pointer;
    color: var(--secondary-color);
}
.btns{
    display: flex;
    width: 23%;
    flex-direction: row;
    column-gap: 16px;
    button{
        width: 50%;
    }
}
button{
    margin-left: 20px;
    &:hover{
        transform: scale(1.09);
    }
}
.tickets-container{
    overflow: scroll;
}
h1{
    font-weight: 700;
    font-size: clamp(18px,3vw,24px);
}
.open{
    color: var(--positive-color);
}
.ispending{
    color: var(--banner-primary);
}
.finished{
    color: var(--red-color);
}

table{
    width: 100%;
    font-weight: 400;
    font-size: clamp(10px,1vw,14px);
    tbody{
        color: #3f3f3fde;

    }
}
.tickets{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    height: 98%;
}
.title{
    margin-top: 10px;
    z-index: 5;
            position: sticky;
        top: 0;
        background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between ;
}
.submit{
    max-height: 36px;
    width: 10%;
    
}
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // justify-content: center;
}
@media only screen and(max-width:600px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:450px){
    .remove-two{
        display: none;
    }
    .time{
        flex-direction: column;
    }
}
</style>