<template>
    <transition name="fade">
        <div class="modal-backdrop" @click.self="$emit('closeChargeModaleEmit')" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
                <h1>{{state.lang.walletEmailModal.title}}</h1>
                <p>  {{state.lang.walletEmailModal.body1}} {{type}} {{state.lang.walletEmailModal.body2}}</p>
                <CodeInput :loading="false" class="verif-code" @change="onChange" @complete="submit" />
                <button @click="complete" class="submit"> {{state.lang.walletEmailModal.btn}} </button>
                <button v-if="countDown>0" disabled class="positive">{{countDown}} {{state.lang.walletEmailModal.seconds}} </button>
                <button v-if="countDown==0" @click="send" class="positive">{{state.lang.walletEmailModal.sendCode}}</button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
    import CodeInput from "vue-verification-code-input";
    import {Copy,setPlaceholder,reset,paste} from "@/library/reuseableFunction";
export default {
  name: 'Sellmodal',
  components : {
      CodeInput,
  },
  data() {
      return {
        otp:{
            code: '',
        },
        countDown: 120,
        close : false,
      }
  },
  props:{
      type:String,
      twoStep:String,
      // qrCode:null,
      // qrLink:null,
  },
  methods : {
      closeModale() {
        this.$emit('closeChargeModaleEmit' , this.close)
      },
      countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
      onChange(e) {
          this.otp.code = e ;
      },
      async send() {
                this.reset()
                this.state.loading = true
                const a = {
                    EMAIL: 'ایمیل',
                    SMS: 'شماره'
                }
                const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`)
                if (res.message === 'Verification Code Sent') {
                    this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                    if (this.countDown === 0)
                        this.countDown = 120
                    this.countDownTimer()
                }
      },
      onComplete () {
          this.complete;
      },
      async submit() {
                const a = {
                    'EMAIL': 'ایمیل',
                    'SMS': 'پیامک',
                    'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                }
                if (this.otp.code.toString().length === 6 && (this.twoStep ==='GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {
                    if (this.$route.name !== 'Wallet') {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.otp.code}`)
                        if(res){
                            this.reset()
                            this.$emit('closeChargeModaleEmit')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + a[this.twoStep] + ' تغییر یافت.')
                        }
                    } else {
                        this.$emit('submit', this.otp.code)
                    }
                }
            },
      complete() {
        this.$emit('completed' , true)
      },
      Copy, paste,setPlaceholder,reset
  },
mounted() {
            // this.$refs['code-input'].$refs['input_0'][0].focus()
            // this.setPlaceholder()
            if (this.twoStep !== 'GOOGLE_AUTHENTICATOR') this.countDownTimer()
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .positive{
    background: var(--positive-color) !important;
    color: white !important;
    cursor: pointer;
    width: 100%;
}
   .close{
     cursor: pointer;
   }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
//    h1{
//        font-size: clamp(28px,3vw,36px);
//    }
   .note{
       display: flex;
       flex-direction: row;
       column-gap: 5px;
    //    align-items: center;
       color: var(--red-color);
       img{
           align-self: flex-start;
       }
   }
   .money{
       color: rgba(4, 24, 54, 0.342);
       font-weight: 300;
       font-size: clamp(10px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h1{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>