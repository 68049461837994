<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../assets/icons/icons8-close.svg" alt="">
            <img src="../../assets/icons/clarity_success-line.svg" alt="">
            <h6>{{state.lang.successModal.title}}</h6>
            <p> {{state.lang.successModal.body}} </p>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Success',
  components :{
    
  },
  data() {
      return {
          close : false,
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeModaleEmit' , this.close)
      },
  },
  mounted () {
      
      setTimeout(this.closeModale,3000);
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal , .order-btn')) {
              this.$emit('closeModaleEmit' , this.close)
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>