<template>
    <div class="otpemail">
        <Simplemodal v-if="state.loading" :closeable="false" :text="'در حال انجام عملیات ...'" :pic="require('../../assets/icons/loading.svg')" />
            <form @submit.prevent="submit" action="">
                <h1>{{state.lang.loginOtpEmail.title}}</h1>
                <p v-if="$route.name == 'Loginotpemail'">{{state.lang.loginOtpEmail.body1}}{{otp.email}}{{state.lang.loginOtpEmail.body2}}</p>
                <p v-if="$route.name == 'Loginotpgoogle'">{{state.lang.loginOtpEmail.google}}</p>
                <p v-if="$route.name == 'Loginotpmobile'">
                     {{state.lang.loginOtpEmail.body1}}
                  <span style="direction:ltr;display:inline-block">
                {{state.userInfo.mobilenumber}}
            </span>
            {{state.lang.loginOtpEmail.body2}}</p>
                <CodeInput :loading="false" class="verif-code" @change="onChange" @complete="submit" />
                <button type="submit" :disabled="!otp.code" class="submit">{{state.lang.loginOtpEmail.submit}}</button>
                <button v-if="countdown>0 && $route.name !== 'Loginotpgoogle'" :disabled="true" class="positive">{{countdown}} {{state.lang.loginOtpEmail.seconds}} </button>
                <button v-if="countdown==0 && $route.name !== 'Loginotpgoogle'" @click="send" class="positive">{{state.lang.loginOtpEmail.reSend}}</button>
            </form>

    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import Simplemodal from '../../components/Tools/Simplemodal.vue';
 import {setPlaceholder,reset,paste} from "@/library/reuseableFunction";

    export default {
        name : 'Loginotpemail',
        close : true ,
        components : {
            CodeInput,
            Simplemodal,
        },
        props:['pathMatch'],
        data(){
            return{
                countdown:120,
                error: '',
                code: '',
                otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username')
                },
                password:'',
            }
        },
        methods: {
            countDownTimer() {
                if (this.countdown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countdown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            handleSubmit () {
                 this.$emit('closeModaleEmit' , this.close)
            },
            async send() {
                this.state.loading = true
                this.reset()
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                const text = {
                    Loginotpemail: 'ایمیل',
                    Loginotpmobile: 'شماره'
                }
                this.$error('کد جدید به ' + text[this.$route.name] + ' شما ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)
                if (this.countdown === 0) {
                    this.countdown = 120
                    this.countDownTimer()
                }

            },
            onChange(v) {
                this.otp.code = v
            }, 
            async submit() {
                if (!this.disabled) {
                    this.state.loading = true
                    const res = await this.$axios.post('/users/check-otp-code', this.otp).catch(()=>{})
                    this.reset()
                    this.state.loading = false
                    if (res.token) {
                        await this.$setToken(res.token)
                        await this.$router.push({name:'Dashboard'})
                    }
                }
            },
            setPlaceholder,
            reset,
            paste
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted (){
            this.setPlaceholder()
            if (!this.otp.email) {
                this.$router.push({name:'Login'})
            }
            if (this.$route.name !== 'Loginotpgoogle') {
                this.countDownTimer()
            }
        }
    }
</script>

<style lang="scss" scoped>
.positive{
    background: var(--positive-color) !important;
    color: white !important;
    cursor: pointer;
}
.otpemail{
    // max-width: 336px;
    width: 40%;
    box-sizing: border-box;
    
}
.submit{
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 206px;
}
  @media only screen and (max-width:900px){


      .otpemail{
          width: 70%;
        padding: 0 20px;
      }
}
  @media only screen and (max-width:550px){

      .otpemail{
          width: 100%;
        padding: 0 20px;
      }
}
</style>