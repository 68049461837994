import { render, staticRenderFns } from "./Buysell.vue?vue&type=template&id=38119859&scoped=true&"
import script from "./Buysell.vue?vue&type=script&lang=js&"
export * from "./Buysell.vue?vue&type=script&lang=js&"
import style0 from "./Buysell.vue?vue&type=style&index=0&id=38119859&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38119859",
  null
  
)

export default component.exports