import { render, staticRenderFns } from "./Swingbtn.vue?vue&type=template&id=49b27546&scoped=true&"
import script from "./Swingbtn.vue?vue&type=script&lang=js&"
export * from "./Swingbtn.vue?vue&type=script&lang=js&"
import style0 from "./Swingbtn.vue?vue&type=style&index=0&id=49b27546&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b27546",
  null
  
)

export default component.exports