<template>
    <div>
            <div class="statistic">
      <div class="container stats-container">
            <div class="stats" >
                <h5>{{$toLocal(userscount)}}</h5>
                <h4>{{state.lang.statistic.users}}</h4>
            </div>
            <div class="stats" >
                <h5>{{$toLocal(totaltrad)}}</h5>
                <h4>{{state.lang.statistic.doneDeal}}</h4>
            </div>
            <div class="stats" >
                <h5>{{$toLocal(tradprice.toFixed(0))}}</h5>
                <h4>{{state.lang.statistic.dealValue}}</h4>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name : 'Statistic',
        data () {
            return {
                userscount: 0,
                tradprice: 0,
                totaltrad: 0,
            }
        },
        methods:{
            async getInfo() {
                const res = await this.$axios('trades/market-info-home')
                this.userscount = res.baseDTO.userCount
                this.tradprice = res.baseDTO.tradesValue
                this.totaltrad = res.baseDTO.tradesAmount
            },
        },
        mounted (){
            this.getInfo();
        }
        
    }
</script>

<style lang="scss" scoped>
.statistic{
        min-height: 237px;
        background:var(--secondary-color) ;
        display: flex;
        align-items: center;
        justify-content: center;

    .stats-container{
        box-sizing: border-box;
        padding: 64px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        row-gap: 30px;
        flex-wrap: wrap;
       .stats{
           text-align: center;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           height: 100px;
           h4{
               color: var(--banner-primary) ;
               font-weight:700 ;
               font-size:clamp(18px,1vw,24px) ;
           }
           h5{
               color: var(--primary-color);
               font-weight:700 ;
               font-size:clamp(27px,1vw,36px) ;
           }
       } 
    }
}

</style>