export default {
    changeLangModal:{
        "title":'Changing Language',
        "body":'Select your Language',
        "FA":'PERSIAN',
        "EN":'ENGLISH',
    },
    navbar:{
        "changeLang":'Change Language',
        "homeBtn":'Zeen Currency Exchange',
        "market":'Market',
        "fee":'Fee',
        "support":'Support',
        "blog":'Blog',
        "p2p":'AdvanceTrade',
        "exchange":'OTC',
        "login":'Login',
        "signup":'SignUp',
        "tickets":'Tickets',
        "notifications":'Notifications',
        "dashboard":'Dashboard',
        "account":'Account',
        "validation":'Validation',
        "exit":'Exit'
    },
    homePageBanner:{
        "zeenCurrency":'Zeen Currency',
        "title":'Digital Coins Market',
        "explanation":'Experience buying and selling BITCOIN and other digital Currencies in a secure and professional environment',
        "startBtn":'START NOW',
        
    },
    marketTable:{
        "toman":'TOMAN',
        "tether":'TETHER',
        "changes":'changes',
        "worldPrice":'World Price',
        "buyPrice":'Buy Price',
        "sellPrice":'Sell Price',
        "graph":'Graph',
        "buy":'Buy',
        "sell":'Sell',
        "opration":'Opration',
        "moreCoins":'More Coins',
    },
    cards:{
        "title":'Our Features And Benefits',
        "explanation":'The unique features of ZEEN CURRENCY will give you such a different experience from digital currencies',
        "fastDeal":'Fast Deals',
        "secure":'Secure',
        "support":'24 Hours Support'
    },
    steps:{
        "title":'Steps To Go',
        "signup":'Signing Up',
        "signupBody":'Having only the email address, you can do the initial registration on ZEEN CURRENCY site and get acquainted with the features and capabilities of the site.',
        "validation":'Validation',
        "validationBody":'After the initial registration, by entering your personal information and banking information, as well as a picture of your documents, you can easily and in the shortest possible time access all the facilities of the site and make your transactions.',
        "wallet":'Charging The Wallet',
        "walletBody":'After authentication on the site, you will have access to all the features of the site, and by charging your wallet, you can start trading.',
        "start":'Lets Go',
        "startBody":'ZEEN CURRENCY with the support of more than 30 different currencies allows you, dear users, to do your transactions with the least restrictions.',
    },
    statistic:{
        "dealValue":'Total value of Transactions',
        "doneDeal":'Done Transactions',
        "users":'Users',
    },
    Faq:{
        "title":'FAQ'
    },
    footer:{
        "explanation":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi.',
        "vista":'All rights reserved for Vistadev Company',
        "aboutUs":'About Us',
        "support":'Support',
        "blog":'blog',
        "termsAndConditions":'Terms and Conditions',
        "faq":'FAQ',
        "quickAccess":'Quick Access',
        "contactUs":'Contact Us'
    },
    aboutUs:{
            "title":'About Zeen Currency Exchange',
            "explanation":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.',
            "conclusion":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.',
    },
    contactUs:{
        "explanation":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.',
        "social":'Follow Us In Social Media'
    },
    fee:{
        "title":'Fees',
        "feeTermsTitle":'Zeen Currency trading fee is a percentage of the total transaction amount and is calculated based on the following considerations:',
        "term1":'Deduction of digital currency sellers commission is deducted from the amount received in Tomans and digital currency buyers commission in the form of digital currency',
        "term2":'Fees are deducted from both parties to the transaction',
        "term3":'You will be shown a fee when registering transactions in Valx',
        "levelsTitle":'User levels and transaction fees',
        "levelsBody":'According to the users transactions in the last 3 months the transaction fee is calculated step by step according to the following table:',
        "coinFeeTitle":'Width collection fee',
        "coinFeeBody":'The fee for withdrawing digital assets depends on the type of currency. This cost is actually deducted due to the transaction fees of Shike and Zain Karansi has no role in it. Currently these are the following:',
        "rialFeeTitle":'Rial withdrawal fee',
        "rialFeeBody":'Rial withdrawal for all accounts will be in the form of a stable transfer once a day. Obviously, the settlement time in Rials, depending on the time of withdrawal request, on non-holidays can last up to 42 hours.',
        "fee":'Fee',
        "last3MounthTrade":'The amount of transactions in the last 3 months',
        "userLevel":'User level',
        "userLevels":'User level',
        "coin":'Currency',
        "minWithDrawal":'Minimum Withdrawal rate',
    },
    market:{
        "search":'Search',
     },
     privacyPolicy:{
         "title":'Terms and Conditions',
         "explanation":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.',
         "secondTitle":'Terms and Conditions',
         "body":'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.',
     },
     successModal:{
        "title":'Your request has successfuly done',
        "body":'Your account will be activated after the information is checked by the Zeen Currency team'
    },
    signUp:{
        "title":'SIGNING UP',
        "userName":'Email',
        "passWord":'Password',
        "inviteCode":'Invitation Code',
        "acceptTerms":'By registering in Zeen Currency you accept the terms and conditions of using the site services',
        "signUpBtn":'SignUp',
        "googleBtn":'Continue with Google Account',
        "loginTitle":'You have an account',
        "loginLink":'Enter',
        "Help":'Registration Guide',
    },
    login:{
        "title":'LOGIN',
        "userName":'Email',
        "passWord":'Password',
        "forgetPass":'Forget Password',
        "loginBtn":'Login',
        "googleBtn":'Continue with Google Account',
        "signUpTitle":'dont have an account Yet ?',
        "signUpLink":'SignUp',
    },
    otpEmail:{
        "title":'Email Validation',
        "body":'Enter the six-digit code sent to your email',
        "btn":'Submit',
        "modal":'In Progress ...'
    },
    loginOtpEmail:{
        "title":'Two Step Verfication',
        "body1":'Enter the sent code to',
        "body2":'',
        "google":'Enter the two-step verification code generated by Google Athenticator',
        "submit":'Submit',
        "seconds":'Seconds',
        "reSend":'Sending New Code',
    },
    forgotPass:{
        "title":'Forget PassWord',
        "body":'Enter your Email',
        "userName":'Email',
        "btn":'Submit'
    },
    forgotPassNew:{
        "title":'Forget PassWord',
        "body":'Enter your New Password',
        "passWord":'Password',
        "passwordRepeat":'Password Repeat',
        "btn":'Submit'
    },
    otpNewPassword:{
        "title":'Forget PassWord',
        "body":'Enter the sent code to Your Email',
        "btn":'Submit',
        "seconds":'Seconds',
        "reSend":'Sending New Code',
        "modal":'In Progress ...',
    },
    sidebar:{
        "dashboard":'Dashboard',
        "activeOrders":'Active Orders',
        "account":'Account',
        "wallet":'Wallet',
        "exchangeWallet":'Exchange Wallet',
        "history":'History',
        "setting":'Setting',
        "notifications":'Notifications',
        "inviteFriends":'Invite Friends',
        "tickets":'Tickets',
        "exit":'Exit',
    },
    dashboardalert:{
        "title":'Test Alert',
        "body":'Experience buying and selling BITCOIN and other digital Currencies in a secure and professional environment',
    },
    activeOrderBtns:{
        "market":'Exchange',
        "proMarket":'Advance Trade'
    },
    dashboardAccess:{
        "friendsTitle":'Number Of My Friends',
        "personNum":'Person',
        "totalBenefits":'My Total Profit',
        "coinType":'TETHER',
        "friendsBtn":'Invite Friends',
        "accountError":'Complete Account',
        "accountBankError":'Complete Bank Account',
        "accountLevel":'Level'
    },
    dashboardOrders:{
        "orderHistory":'Orders History',
        "activeOrders":'Active Orders',
        "showBtn":'Continue',
        "inProgrres":'In Progrres',
        "done":'Done',
        "canceled":'Canceled',
        "buy":'Buy',
        "sell":'Sell',
        "bitcoin":'BITCOIN',
        "exchange":'EXCHANGE',
        "channel":'Channel',
        "promarket":'Market_ADVANCETRADE',
        "limit":'LIMIT_ADVANCETRADE',
        "tableDone":'Done',
        "tablePrice":'Price',
        "tableTotalPrice":'Total Price',
        "tableAmount":'Amount',
    },
    walletTable:{
        "remain":'Remain',
        "changes":'Changes',
        "value":'Value(TETHER)',
        "inDeal":'In Deal',
        "depositAble":'DepositAble',
        "seeAll":'See More'
    },
    bankCards:{
        "remain":'Remain',
    },
    apexChart:{
        "property":'Properties',
    },
    activeOrdersMarektTable:{
        "market":'Market',
        "coin":'Type',
        "price":'Price',
        "amount":'Amount',
        "total":'Total Price',
        "channel":'Channel',
        "time":'Time',
        "opration":'Opration',
    },
    activeOrdersHeaderTable:{
        "amount":'Amount',
        "value":'Value(TETHER)',
        "price":'Price',
        "changes":'Changes',
        "inDeal":'In Deal',
        "time":'Time'
    },
    activeOrdersProMarektTable:{
        "allOrders":'All Orders',
        "coins":'Coin',
        "toman":'TOMAN',
        tether:'TETHER',
        "done":'Done',
        "coin":'Type',
        "price":'Price',
        "amount":'Amount',
        "total":'Total',
        "opration":'Opration',
        "cancel":'Cancel Order',
        "isPending":'Is Pending',
        "LIMITED_BUY":'LIMITED_BUY',
        "LIMITED_SELL":'LIMITED_SELL',
        "MARKEY_BUY":'MARKEY_BUY',
        "MARKET_SELL":'MARKET_SELL',
    },
    account:{
        "personalInfoTitle":'Personal Information',
        "name":'Name',
        "family":'Family',
        "nationalCode":'National ID',
        "birthDay":'Birthday',
        "phonenumber":'CelPhone Number',
        "telephone":'Telephone number',
        "addres":'Addres',
        "bankInfoTitle":'Bank Info',
        "newCard":'Add New Card',
        "bankName":'Bank Name',
        "shebaCode":'SHeba Code',
        "cartNumber":'Cart Number',
        "deletCard":'Remove',
        "validationTitle":'Validation',
        "validationHelp1":'Please send the national card image next to your face along with a handwriting in a very clear and legible way, just like the sample image Note that the handwritten text should not be typed.',
        "validationHelp2":'Also note that when preparing the image, the handwritten text and national card information should not be recorded in reverse and in a mirror.',
        "validationHandWrite1":'HandWrite Text :',
        "validationHandWrite2":'I (Name and Family) to the national code (national code), while studying and approving the rules of using the Zeen Currency services, I undertake not to give my account and documents to other people in order to buy and sell digital currencies.',
        "validationHandWrite3":'For Validation in Zeen Currency',
        "validationHandWrite4":'Your Name and Family Also date And Your Signature',
        "picUploadBtn":'Upload Selfie Picture',
        "editBtn":'Edit Informations',
        "submit":'Submit',
        "edit":'Edit And Submit'
    },
    wallet:{
        "remain":'Remain',
        "changes":'Changes',
        "value":'Value(TETHER)',
        "inDeal":'In Deal',
        "depositAble":'DepositAble',
        "opration":'Opration',
        "deposit":'Deposit',
        "withDrawal":'WithDrawal',
        "deal":'Deal',
        "tomanWallet":'TETHER Wallet',
    },
    walletSellModal:{
        "title":'Withdrawal From TOMAN Wallet',
        "priceType":'Price In',
        "balance":'Balance :',
        "cardNum":'Card Number',
        "addCard":'Add a New Card',
        "warning1":'Rial settlement requests are not made on holidays due to bank closures and inactivity of the reliable and satna system.',
        "warning2":'Settlement requests over 100 million Tomans due to bank restrictions will be processed within one working day from the time of submitting the application.',
        "btn":'Submit Withdrawal Request',
        "emptyPrice":'Enter The Price',
        "emptyCard":'Select The Card',
        "lowBalance":'Not Enough Balance',
        "toman":'TOMAN',
    },
    walletChargeModal:{
        "title":'Deposit to the TETHER wallet',
        "body":'In order to increase the credit of your Rial wallet by using Shetab member bank cards and through the payment gateway, you must use bank cards in your own name that are registered and approved in your profile.',   
        "input":'Price in TETHER',
        "newCard":'Add New Card',
        "btn":'Transfer to payment gateway',
    },
    walletCoinSellModal:{
        "title":'withDrawal from',
        "priceInput":'Amount Of',
        "remain":'Balance :',
        "walletAdress":'Wallet Adress',
        "body":'Be sure to enter your wallet address and use a valid Bitcoin wallet address. The buyer is responsible for the consequences and consequences of the invalid wallet address.',
        "feeTitle":'withDrawal Fee In',
        "btn":'Continue',
        "minWithDrawal":'Minimum Amount WithDrawal From',
        "maxWithDrawal":'Maximum Amount WithDrawal From',
        errors:{
            "emptyAmount":'Enter The Amount',
            "emptyWallet":'Enter The Wallet Address',
            "emptyNet":'Select The Net',
            "lowBalance":'Not Enough Balance',
            "continue":'Continue',
        }
    },
    walletEmailModal:{
        "title":'Two Step Athentication',
        "withDrawalTitle":'WithDrawal',
        "body1":'Enter the 6-digits Code that Sent to Your ',
        "body2":'',
        "btn":'Submit',
        "withDrawalBtn":'Submit And WithDrawal',
        "seconds":'Seconds',
        "sendCode":'Sendig a New Code',
    },
    walletAdrresModal:{
        "title":'Deposit to wallet',
        "body1":'Your wallet address can be seen below. You can use this address to deposit ',
        "body2":'in this wallet',
        "channel1":'Choose',
        "cahnnel2":'Channel',
        "warning1":'This wallet is only for depositing',
        "warning2":'. Depositing any other currency to this address will result in the loss of your property',
        "btn":'Copy Adress',
    },
    history:{
        
        "deposite":'Deposite',
        "withDrawal":'WithDrawal',
        "coin":'Coin',
        "tradesTitle":'Advance Trade',
        "exchangesTitle":'Exchange',
        "despintsTitle":'Currency Deposit And WithDraw',
        "coindespintsTitle":'Deposits and Withdrawals - Coins',
        "tomandespintsTitle":'Deposits and Withdrawals - RIALS',
        "loginsTitle":'Logins',
        "allOprations":'All Oprations',
        "buy":'Buy',
        "sell":'Sell',
        "limit":'Limit',
        "market":'Market',
        "allCoins":'All Market',
        "showBtn":'Continue',
        "oprations":'All Oprations',
        "markets":'All Markets',
    },
    tradesTable:{

        "market":'Market',
        "opration":'Opration',
        "price":'Price',
        "amount":'Amount',
        "total":'Total',
        "fee":'Fee',
        "done":'Done',
        "time":'Time',
        "priceType":'TETHER'
    },
    exchangeTable:{
        "coin":'Coin',
        "opration":'Opration',
        "price":'Price',
        "amount":'Amount',
        "total":'Total',
        "channel":'Channel',
        "situation":'Situation',
        "transactionId":'Transaction Id',
        "time":'Time',
    },
    despintTable:{
        "walletAddres":'Wallet Address',
        "deposite":'Deposite',
        "withDrawal":'WithDrawal',
        "coin":'Coin',
        "opration":'Opration',
        "orderType":'Order Type',
        "followId":'Follow Up ID',
        "value":'Value',
        "amount":'Amount',
        "fee":'Fee',
        "channel":'Channel',
        "situation":'Situation',
        "transactionId":'Transaction Id',
        "time":'Time',
    },
    loginTable:{
        "success":'Success',
        "failed":'Failed',
        "enter":'Enter',
        "time":'Time',
        "ip":'IP',
        "platform":'Platform',
        "device":'Device',
        "browser":'Browser',
        "situation":'Situation',
    },
    settings:{
        "title":'Two Step Athentication',
        "body":'Activation is one of the two-factor methods for more security',
        "changePassTitle":'Changing Password',
        "btn":'Change Password',
        "email":'Email',
        "sms":'Phone Number'
    },
    googleModal:{
        "title":'Two Step Athentication',
        "body":'To enable two-factor authentication, first download the Google Authenticator app and then scan the following QR code with it',
        "explanation":'After scanning the barcode, a Zeen Currency item will be created in your application. To confirm the accuracy of the process, enter the code you see in your app in the box below',
        "btn":'Submit',
        "copy":'Copied'
    },
    changePasswordTitle:{
        "title":'Changing Password',
        "body":'To change your password enter your current password',
        "oldPassword":'Present Password',
        "newPassword":'New Password',
        "repeatPassword":'Repeat New Password',
        "btn":'Submit',
    },
    notifications:{
        "title":'Notifications',
    },
    inviteFriends:{
        "title":'Invite Friends',
        "body":'Lorems day is a fake text with easy to understand production from the printing industry, and uses graphic designers, printers and newspaper and magazine texts in columns and rows as needed, for current technology conditions, and a variety of applications aimed at improving tools. It is practical. Many books in the past sixty-three percent of the present and the future require a comprehensive knowledge of the community and professionals, in order to create more software for computer designers, especially creative designers, and a leading culture in Persian. In this case, it can be hoped that all the problems in providing solutions, and difficult typing conditions will end, and the time required, including typing the main characters, and answering the ongoing questions of the existing world of basic design will be used.',
        "friendsNumber":'Friends Number',
        "person":'Persons',
        "totalBenefits":'Total Benefits',
        "priceType":'TETHER',
        "inviteLink":'Invite Link :',
        "inviteCode":'Invite Code :',
        "copy":'Texed Copied',
    },
    tickets:{
        "answer":'Answer',
        "title":'Tickets',
        "btn":'Send Ticket',
        "back":'Back to Tickets',
        "date":'Date',
        "ticketTitle":'Ticket Title',
        "ticketSubject":'Ticket Subject',
        "situation":'Situation',
        "seeDetails":'See Details',
        "open":'Open',
        "isPending":'Is Pending',
        "closed":'Closed'
    },
    ticketModal:{
        "sub1":'Buy',
        "sub2":'Sell',
        "sub3":'Transactions',
        "sub4":'Validation',
        "sub5":'Other',
        "answerTicket":'Answer Ticket',
        "answerBtn":'Submit Your Answer',
        "enterAnswer":'Your Answer',
        "title":'Add Ticket',
        "btn":'Send Ticket',
        "titleInput":'Select The Title',
        "subject":'Select The Subject',
        "explanation":'Write an Complete Explanation About your Issue',
        "emptyText":'Enter The Text',
        "emptyTitle":'Enter The Title',
    },
    
    exitModal:{
        "title":'Loging Out',
        "body":'Are You Sure ?!',
        "yes":'Yes',
        "no":'No'
    },
    advanceTrade:{
        defth:{
            "total":'Total',
            "amount":'Amount',
            "price":'Price'
        },
        marketNumbers:{
            "toman":'TOMAN',
            "tether":'TETHER',
            "price":'Price',
            "changes":'Changes',
            "coin":'Coin',
            "time":'Time',
            "amount":'Amount',
        },
        header:{
            "volume":'Volume',
            "min":'Minimum',
            "max":'Maximum',
            "changes":'Changes',
        },
        trade:{
            "limit":'Limit',
            "market":'Market',
            "balance":'Balance',
            "fee":'Fee',
            "raiseBalance":'Raising The Balance',
            "price":'Price',
            "amount":'Amount',
            "expiredate":'Expire Date',
            "optional":'(Optional)',
            "total":'Total',
            "presentFee":'Present Fee',
            "buy":'Buy',
            "sell":'Sell',
            "activeOrders":'Active Orders',
            "orderHistory":'Order History',
            "dealType":'Deal Type',
            "done":'Done',
            "opration":'Opration',
            "cancle":'Cancel The Order',
            "time":'Submit Time',
            "completeTheAccount":'Complete Your Account',
            "notAuthorized":'Account isnt Authorized',
            "min":'Minimum :',
            "max":'Maximum :',
        },
        errors:{
            "emptyAmount":'Amount Fielde Is Empty',
            "emptyPrice":'Price Fielde Is Empty',
            "lowBalance":'Balance Is Not Enough',
            "lowerThanMin":'Amount Is Lower Than Minimum',
            "higherThanMax":'Amount Is More Than Maximum',
            "notAllowed":'Amount Is Not Allowed',
            "emptySellSaleQueue":'No One Buying Right Now',
        },
        activeOrders:{
            "buy":'BUY',
            "sell":'SELL',
            "modalTitle":'Cancel The Order',
            "modalBody":'Are You Sure ?',
            "modalBtn":'Cancel The Order'
        }
    },
    exchange:{
        "buy":'Buy',
        "sell":'Sell',
        "priceType":'TOMAN',
        "raiseBalance":'Raise The Balance',
        "balance":'Balance',
        "priceIn":'Price In',
        "price":'Price',
        "amount":'Amount',
        "dealVolume":'Amount of Deal',
        "min":'Minimum',
        "max":'Maximum',
        "toMainWallet":'To The Zeen Currency Wallet',
        "toOtherWallet":'To The Other Wallet',
        "walletAddres":'Wallet Addres',
        "warning":'Be sure to enter your wallet address and use a valid Bitcoin wallet address. Liability and consequences of invalid wallet address to buyer',
        "total":'Total',
        errors:{
            "emptyAmount":'Amount Fielde Is Empty',
            "emptyPrice":'Price Fielde Is Empty',
            "lowBalance":'Balance Is Not Enough',
            "lowerThanMin":'Amount Is Lower Than Minimum',
            "higherThanMax":'Amount Is More Than Maximum',
            "notAllowed":'Amount Is Not Allowed',
            "emptySellSaleQueue":'No One Buying Right Now',
        },
        },
        shopModal:{
            "title":'Submit Your Order',
            "opration":'Opration',
            "amount":'Amount',
            "unitPrice":'Unit Price',
            "total":'Total Price',
            "expireTime":'Expire Time',
            "submit":'Submit',
        },
        finoTech:{
            "title":'FinoTech 2 Step Verification',
            "body":'Enter The 6-digit Code That Sent To Your Phone Number',
            "submit":'Submit',
            "seconds":'Seconds',
            "nationalCode":'National Code',
            "phoneNumber":'Phone Number',
            "code":'Code',
            "reSend":'Send Another Code',
        },
        channel:{
            "choose":'Select The Channel'
        },
}