<template>
    <div>
        <Dashboardnavbar @close="exitmodal=true" v-if="username" />
        <Exitmodal v-if="exitmodal" @closeChargeModaleEmit="exitmodal=false" />
        <Navbar v-if="!username" />
        <Aboutus v-if="$route.name == 'aboutus'" />
        <Faq v-if="$route.name == 'Faq'" />
        <Privacypolicy v-if="$route.name == 'Privacypolicy'" />
        <Contactus v-if="$route.name == 'Contactus'" />
        <Fees v-if="$route.name == 'Fees'" />
        <Market v-if="$route.name=='Market'" />
        <Footer />
    </div>
</template>

<script>
import Dashboardnavbar from '../Profile/Dashboardnavbar.vue';
import Exitmodal from '../Profile/Exitmodal.vue';
import Faq from './Faq.vue';
import Aboutus from './Aboutus.vue';
import Privacypolicy from './Privacypolicy.vue';
import Contactus from './Contactus.vue';
import Fees from './Fees.vue';
import Navbar from '../public/Navbar.vue';
import Footer from '../public/Footer.vue';
import Market from './/Market.vue';
    export default {
        name : 'Companyinfo',
        components : {
            Navbar,
            Footer,
            Aboutus,
            Faq,
            Privacypolicy,
            Contactus,
            Fees,
            Market,
            Dashboardnavbar,
            Exitmodal

            },
        data(){
            return{
                username:null,
                exitmodal:false,
            }
        },
        mounted(){
            this.username=this.state.userInfo.firstName+this.state.userInfo.lastName
            if(!this.username){
                this.username=this.$cookies.get("email");
            }
        }  
    }
</script>

<style lang="sass" scoped>

</style>