
var Crypto=[
    {
        img: require('../assets/coins/BITCOIN.png'),
        coin: 'BITCOIN',
        remain: 0.0456,
        variant:2.5,
        price:998525368000,
        value:183625927,
        indeal:0.026,
        useable:0.0826,
        year:1400,
        mounth:5,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/ETH.png'),
        coin: 'ETHEREUM',
        remain: 0.0456,
        price:998525368000,
        variant:2.5,
        value:183625927,
        indeal:0.026,
        useable:0.0826,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/RIPPLE.png'),
        coin: 'RIPPLE',
        remain: 0.0456,
        price:998525368000,
        variant:-2.5,
        value:183625927,
        indeal:0.026,
        useable:0.0826,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/LTC.png'),
        coin: 'LITE_COIN',
        remain: 0.0456,
        price:998525368000,
        variant:-2.5,
        value:183625927,
        indeal:0.026,
        useable:0.0826,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },

    {
        img: require('../assets/coins/DOGE_COIN.png'),
        coin: 'DOGE_COIN',
        remain: 0.0456,
        variant:2.5,
        price:998525368000,
        value:183625927,
        indeal:0.026,
        useable:0.0826,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        // class:'no-border',
    },
]
export default Crypto