<template>
    <div class="setting">
        <Changepassmodal
            v-if="changepassmodal"
            @closeChargeModaleEmit="closeModals" />
        <Emailmodal
            :type="state.lang.settings.email"
            @submit="submitted($event)"
            v-if="modal=='EMAIL'"
            @closeChargeModaleEmit="modal='';getTwoStepAuth();"
            :two-step="modal"  />
        <Emailmodal
            :type="state.lang.settings.sms"
            @submit="submitted($event)"
            v-if="modal=='SMS'"
            @closeChargeModaleEmit="modal='';getTwoStepAuth();" 
            :two-step="modal"/>
        <Googlemodal 
            v-if="modal=='GOOGLE_AUTHENTICATOR'" 
            :qr-code="qrCode"
            :qr-link="qrLink"
            :two-step="modal"
            @closeChargeModaleEmit="modal=null;getTwoStepAuth()"/>
        <div class="setting-container">
            <div class="otp">
                <h3>{{state.lang.settings.title}}</h3>
                <p>{{state.lang.settings.body}}</p>
                <form @change="showModal" class="options">
                    <div class="option">
                        <input type="radio" name="email" id="" v-model="twoStep" value="EMAIL">
                        <span>Email</span>
                    </div>
                    <div class="option">
                        <input type="radio" name="email" id="" v-model="twoStep" value="GOOGLE_AUTHENTICATOR">
                        <span>Google Authenticator</span>
                    </div>
                    <div class="option">
                        <input type="radio" name="email" id="" v-model="twoStep" value="SMS">
                        <span>SMS</span>
                    </div>
                </form>
            </div>
            <div class="forgetpass">
                <h3>{{state.lang.settings.changePassTitle}}</h3>
                <button @click="changepassmodal=true" class="submit">{{state.lang.settings.btn}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Changepassmodal from './Changepassmodal.vue'
import Emailmodal from './Emailmodal.vue'
import Googlemodal from './Googlemodal.vue'
    export default {
  components: { Emailmodal,
                Googlemodal,
                Changepassmodal 
            },
        name: 'Setting',
        data () {
            return{
                twoStep: '',
                changepassmodal:false,
                completedModal: false,
                emailmodal:false,
                google:false,
                sms:false,
                qrCode: '',
                qrLink:'',
                mobile: '',
                completedText: '',
                modal: ''
            }
        },
        methods:{
            async getTwoStepAuth() {
                this.twoStep = this.state.userInfo.towStepAuthenticationType
            },
            async submitted(e) {
                this.completedText = e;
                await this.$error('', e, 'success')
                await this.$getuserInfo()
                this.getTwoStepAuth()
            },
            async showModal() {
                this.state.loading = true
                const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
                    this.getTwoStepAuth()
                })
                if (res) {
                    this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                    this.qrCode = res.baseDTO.file || ''
                    this.qrLink = res.baseDTO.qrCodeLink || ''
                    this.modal = this.twoStep
                }
            },
            closeModals(){
                this.emailmodal=false
                this.google=false
                this.sms=false
                this.completechangepass=false
                this.changepassmodal=false
                this.finish=false
            }
        },
        mounted(){
            this.getTwoStepAuth();
        }

    }
</script>

<style lang="scss" scoped>
input[type="radio"]{
     transform: scale(1.2);
     &:hover{
         cursor: pointer;
     }
}
span{
    color: var(--secondary-color);
    margin-top: 5px;
}
.options{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.option{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
.setting{
    width: 100%;
}
.forgetpass{
    display: flex;
    justify-content: space-between;
}
.setting-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.otp,.forgetpass{
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    width: 100%;
}
.otp{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
h3{
    font-weight: 700;
    font-size: clamp(18px,2vw,24px);
}
.checkbox{

        border-radius:100px ;
        background: white;
        border: 1px solid var(--secondary-color) !important;
}
.checked{
        background: var(--secondary-color);
    border: none !important;
    width: 18px;
    height: 18px;

    }
    p{
    color: rgba(63, 63, 63, .6);
    font-weight: 400;
    font-size: clamp(10px,3vw,14px); 
}
.submit{
    width: 35% !important;
}
</style>