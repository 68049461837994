<template>
    <div class="otpemail">
        <!-- <Simplemodal v-if="state.loading" :closeable="false" :text="'در حال انجام عملیات ...'" :pic="require('../../assets/icons/loading.svg')" /> -->
            <form @submit.prevent="submit" action="">
                <h1>{{state.lang.otpNewPassword.title}}</h1>
                <p v-if="$route.name == 'Forgotpassotpemail'">{{state.lang.loginOtpEmail.body1}}{{otp.email}}{{state.lang.loginOtpEmail.body2}}</p>
                <p v-if="$route.name == 'Forgotpassotpgoogelathenticator'">{{state.lang.loginOtpEmail.google}}</p>
                <p v-if="$route.name == 'Forgotpassotpsms'">
                     {{state.lang.loginOtpEmail.body1}}
                  <span style="direction:ltr;display:inline-block">
                {{state.userInfo.mobilenumber}}
            </span>
            {{state.lang.loginOtpEmail.body2}}</p>
                <CodeInput :loading="false" class="verif-code" v-on:change="onChange" v-on:complete="submit" />
                <button type="submit" :disabled="!otp.code" class="submit">{{state.lang.otpNewPassword.btn}}</button>
            </form>
                <button v-if="countDown>0" :disabled="true" class="positive">{{countDown}}  {{state.lang.otpNewPassword.seconds}}</button>
                <button v-if="countDown==0" @click.prevent="send" class="positive">{{state.lang.otpNewPassword.reSend}}</button>
    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
// import Simplemodal from '../../components/Tools/Simplemodal.vue';
import {setPlaceholder, reset} from "@/library/reuseableFunction";


    export default {
        name : 'Forgotpassotp',
        close : true ,
        components : {
            CodeInput,
            // Simplemodal,
        },
        data () {
            return{
                countDown: 120,
                otp: {
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username') || '',
                },
            }
        },
        methods: {
            onChange(e) {
                this.otp.code = e
            },
             async submit() {
                if (!this.disabled) {
                    this.state.loading = true
                    let res = await this.$http.post('/users/v2/check-otp-password', this.otp)
                    this.reset()
                    if (res) {
                        console.log(res);
                        this.state.otptoken=res[0].token
                        // this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                        await this.$router.push({name:'Forgotpassnew'})
                    }
                }
            },
            async send() {
                this.state.loading = true
                this.reset()
                await this.$axios.post('/users/forget-password?email=' + this.otp.email)
                this.$error('کد جدید ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)

                if (this.countDown === 0) {
                        this.countDown = 120
                    this.countDownTimer()
                }
            },
            countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            reset,
            setPlaceholder,
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted() {
            this.countDownTimer();
            this.setPlaceholder()
            if (!this.$cookies.isKey('username')) {
                this.$router.push({name:'Login'})
            }
            
        }
    }
</script>

<style lang="scss" scoped>
.positive{
    width: 100%;
    background: var(--positive-color) !important;
    color: white !important;
    cursor: pointer;
}
.otpemail{
    // max-width: 336px;
    width: 35%;
    box-sizing: border-box;
    display: flex;
    row-gap: 8px;
    flex-direction: column;
}
.submit{
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 206px;
}
  @media only screen and (max-width:900px){


      .otpemail{
          width: 70%;
        padding: 0 20px;
      }
}
  @media only screen and (max-width:550px){

      .otpemail{
          width: 100%;
        padding: 0 20px;
      }
}
</style>