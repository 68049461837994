<template>
    <div class="cards">
        <div class="card-container" v-for="(card,index) in slicedcards" :key="index" :class="[state.lan=='Fa'?background[index]:backgroundEn[index],fixbackground[index]]" >
        <template v-if="card.cardNumber && card.shebaCode">
            <div class="names">
                <!-- <p>{{card.bankname}}</p> -->
                <div class="username">
                    <p>{{state.userInfo.firstName}}</p>
                    <p>{{state.userInfo.lastName}}</p>
                </div>
            </div>
            <div class="cardnumber">
                {{$separator(card.cardNumber)}}
            </div>
             <div class="date">
                <p>{{card.shebaCode}}</p>
                <p>IR</p>
            </div>
        </template>
        </div>
        <div v-if="cards1.length > 1" @click="changeCard" :class="{'change-card-en':state.lan=='En'}" class="change-card">
                    <img src="../../assets/icons/new-card.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {

        name : 'Walletcards',
        data () {
            return {
                start:0,
                end :3,
                change:0,
                slicedcards : [],
                fixbackground:['blue1','yellow1','green1'],
                background:['blue','yellow','green'],
                backgroundEn:['blue-en','yellow-en','green-en'],
                cards1:this.state.userInfo.bankAccounts,
                timetable: {
                    date : '1400/11/12' ,
                    time: '13:24',
                }
            }
        },
        methods : {
            changeCard () {
                this.change++;
                console.log(this.change);
                if(this.start>=0 && this.change==3){
                    this.start--
                    this.end--
                    this.slicedcards=this.cards1.slice(this.start,this.end);
                    console.log(this.slicedcards);
                    this.change=0
                } else {
                    this.start=this.state.userInfo.bankAccounts.length - 3
                    this.end=this.state.userInfo.bankAccounts.length
                    this.slicedcards=this.cards1.slice(this.start,this.end);
                }
                if(this.state.lan=='Fa'){
                    if(this.background[0]=='blue'){
                        this.background=['yellow','green','blue'];
                    }else if(this.background[0]=='yellow'){
                        this.background=['green','blue','yellow'];
                    } else {
                        this.background=['blue','yellow','green'];
                    }

                }else{
                    if(this.backgroundEn[0]=='blue-en'){
                        this.backgroundEn=['yellow-en','green-en','blue-en'];
                    }else if(this.backgroundEn[0]=='yellow-en'){
                        this.backgroundEn=['green-en','blue-en','yellow-en'];
                    } else {
                        this.backgroundEn=['blue-en','yellow-en','green-en'];
                    }
                }
            },
        },
        mounted () {
            this.start=this.state.userInfo.bankAccounts.length - 3
            this.end=this.state.userInfo.bankAccounts.length
            this.slicedcards=this.cards1.slice(this.start,this.end);
        }
    }
</script>

<style lang="scss" scoped>
.change-card{
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 65px;
    background: rgba(51, 158, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
        z-index: 5;
        right: -5px;
}
.names,.stock{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    width: 100%;
    column-gap: 5px;
}
.username{
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
    justify-content: end;
    align-items: flex-end;    
}
.date{
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
}
.cardnumber{
    text-align: center;
}
.cards{
    // height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
        // align-self: flex-start;
}
.card-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
    height: 132px;
    max-width: 313px;
    p{
            font-size: clamp(10px,1vw,14px);
    }
}
.blue{
    z-index: 5;
    transition: 1s;
    right: 0px;
    top: -50px;
}
.blue-en{
    z-index: 5;
    transition: 1s;
    right: -10px !important;
    top: -50px;
}
.blue1{
    background: url('../../assets/dashboard/Card-blue.png');
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    color: var(--primary-color);
    // top: 0px;
    // bottom: 20px;
}
.yellow{
     z-index: 4;
    //  top: -80px;
     top: -60px;
     right: 10px;
     transition: 1s;
}
.yellow-en{
     z-index: 4;
    //  top: -80px;
     top: -60px;
     right: 0px !important;
     transition: 1s;
}
.yellow1{
    position: absolute;
    background: url('../../assets/dashboard/Card-yellwo.png');
    background-repeat: no-repeat;
    background-position: center;
        box-sizing: border-box;
        position: absolute;
    padding: 16px;
    border-radius: 4px;
}
.green{
    z-index: 3;
        //  top: -20px;
         top: -70px;
         transition: 1s;
    right: 20px;
}
.green-en{
    z-index: 3;
        //  top: -20px;
         top: -80px;
         transition: 1s;
    right: 10px !important;
}
.green1{
    position: absolute;
    background: url('../../assets/dashboard/Card-green.png');
    background-repeat: no-repeat;
    background-position: center;
        box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    .stock,.cardnumber{
        color: var(--primary-color);
    }
}
@media only screen and(max-width:1150px){
    .cards{
        width: 44%;
        padding-bottom: 20px;
        align-self: center;
        justify-content: center;
     min-height: 150px;
    }
    .blue{
        top: 23px;
        right: -10px !important;
        
        
    }
        .yellow{
        top: 10px;
        right: 0px !important;


    }
    .green{
        top: 0px ;
        right: 10px !important;
    }
    .change-card{
        bottom: 10px;
        right: -15px !important;
    }
}
@media only screen and(max-width:750px){
    .cards{
    width: 70%;
    }
}
@media only screen and(max-width:520px){
    .cards{
    width: 100%;
    }
}
// @media only screen and(max-width:400px){
//     .names{
//     flex-direction: column;
//     text-align: center;
//     .username{
//         flex-direction: column;
//     }
//     }
// }
</style>