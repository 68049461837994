<template>
    <div class="background-container">
        <div class="background">
            <Trades @sendcomp="changecomp" v-if="livecomponent=='Trades'" />
            <Exchange @sendcomp="changecomp" v-if="livecomponent=='Exchange'" />
            <Despint @sendcomp="changecomp" v-if="livecomponent=='Despint'" />
            <RialDespint @sendcomp="changecomp" v-if="livecomponent=='RialDespint'" />
            <Logins @sendcomp="changecomp" v-if="livecomponent=='Login'" />
        </div>
    </div>
</template>

<script>
import Despint from './Despint.vue'
import Exchange from './Exchange.vue'
import Logins from './Logins.vue'
import RialDespint from './RialDespint.vue'
import Trades from './Trades.vue'
    export default {
        name:'History',
        components: { 
            Trades,
            Exchange,
            Despint,
            Logins,
            RialDespint

            },
        data (){
            return{
                livecomponent:'Trades',
                // components:['Trades','Exchange','Despint','Login'],
            }
        },
        methods : {
            changecomp(value) {
                this.livecomponent=value;
                console.log(this.livecomponent);
            }
        }
    }
</script>

<style lang="scss" scoped>
.background-container{
height: 100%;
max-height: 665px;
}
.background{
width: 100%;
box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
border-radius: 4px;
height: 100%;
max-height: 665px;
}
@media only screen and(max-height:400px){
    .background{
        overflow: scroll;
    }
}
</style>