<template>
    <div class="trades">
        <h1>{{state.lang.history.loginsTitle}}</h1>
        <div class="filters">
            <div class="coin-type">    
                        <div @click.self="headeritems.had.selected=!headeritems.had.selected" class="chose-coin">
                            <span>{{headeritems.had.name}}</span>
                            <img :class="{'selected':headeritems.had.selected}" @click="headeritems.had.selected=!headeritems.had.selected" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="headeritems.had.selected==true" class="option-container">    
                                <div @click="headeritems.had.name=page.name,headeritems.had.selected=false,chosePage(page.addres)" class="options" v-for="(page,index) in pages" :key="index">
                                    <span>{{page.name}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr class="thead">
                        <td></td>
                        <td>{{state.lang.loginTable.time}}</td>
                        <td class="remove-three">{{state.lang.loginTable.ip}}</td>
                        <td class="remove">{{state.lang.loginTable.platform}}</td>
                        <td class="remove-three">{{state.lang.loginTable.device}}</td>
                        <td class="remove-two">{{state.lang.loginTable.browser}}</td>
                        <td>{{state.lang.loginTable.situation}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(enter,index) in tableContents" :key="index">
                        <td v-if="'ورود' ==enter.intery" class="positive">{{enter.intery}}</td>
                        <td v-if="'ورود' !==enter.intery" class="negetive">{{enter.intery}}</td>
                        <td> {{$G2J(enter.loginRequestedDateTime)}} </td>
                        <td class="enter-channel remove-three">{{enter.ipAddress}}</td>
                        <td class="enter-channel remove">{{enter.deviceType}}</td>
                        <td class="remove-three">{{enter.osType}}</td>
                        <td class="remove-two">{{enter.browser}}</td>
                        <td :class="enter.isSuccessful ? 'positive' : 'negetive'">{{enter.isSuccessful ? state.lang.loginTable.success : state.lang.loginTable.failed }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Logins',
        data () {
            return {
                selected:false,
                headeritems:{
                    market:{
                        name:this.state.lang.history.tradesTitle,
                        selected:false,
                    },
                    opration:{
                        name:this.state.lang.history.exchangesTitle,
                        selected:false,
                        },
                    localmarket:{
                        name:this.state.lang.history.despintsTitle,
                        selected:false,
                    },
                    had:{
                        name:this.state.lang.history.loginsTitle,
                        selected:false,
                    },
                },
                pages:[
                    {
                        name:this.state.lang.history.tradesTitle,
                        addres:'Trades',
                    },
                    {
                        name:this.state.lang.history.exchangesTitle,
                        addres:'Exchange',
                    },
                    {
                        name:this.state.lang.history.coindespintsTitle,
                        addres:'Despint',
                    },
                    {
                        name:this.state.lang.history.tomandespintsTitle,
                        addres:'RialDespint',
                    },
                    {
                        name:this.state.lang.history.loginsTitle,
                        addres:'Login',
                    },
                ],
                lastPage: 1,
                tableContents: [],
                page: 0,
            }
        },
        methods : {
            chosePage(addres) {
                this.$emit('sendcomp', addres)
            },
            async check() {
           this.state.loading = true
            if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                this.loading = true
                this.page++ //for scroll loading
                const [res,] = await this.$http.get('/users/login-history', {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    // this.tableContents = res.content //for pagination
                }
                this.loading = false
            }
            this.state.loading = false
            },
        },
        mounted(){
            this.check();
        },
        created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.chose-coin')) {
             this.headeritems.market.selected=false;
             this.headeritems.opration.selected=false;
             this.headeritems.localmarket.selected=false;
             this.headeritems.had.selected=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.thead{
    position: sticky;
    top: 0;
    background: white;
}
.disable{
    opacity: .5;
    &:hover{
        cursor: default;
    }
}
.enter-channel{
    font-family: 'Yekan-en' !important;
}
.table{
    overflow: scroll;
}
// .positive,.negetive{
//     display: flex;
//     align-items: flex-end;
// }
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}
table{
    font-weight: 400;
    font-size: clamp(11px,1vw,14px);
    .cointype{
    display: flex;
    flex-direction: row;
    color: #3f3f3f7e;
    // justify-content: center;
    column-gap: 5px;
    .coinname{
        color:var(--secondary-color);
    }
}
    width: 100%;
    tr{
        height: 36px;
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.coin-type{
        display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    width: 33%;
}
.option-container{
    top: 33px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
                font-weight: 300;
                font-size: clamp(9px,1vw,12px);
            }
    }
            img{
                position: absolute;
                right: 130px;
                top:-3px;
                width: 30px;
                height: 30px;

            }
}
.chose-coin{
    cursor: pointer;
    height: 30px;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    justify-content: space-between;
    width: 100%;
        position: relative;
    border: 0.2px solid #0077cc65;
    border-radius: 4px;
    span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
}
    .selected{
        transform: rotate(180deg);
    }
.trades{
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    row-gap: 16px;
    h1{
        text-align: start !important;
    }
}
.filters{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}
@media only screen and(max-width:850px){
    .filters{
        flex-direction: column;
        row-gap: 16px;
        .coin-type{
            width: 100%;
        }
    }
        .remove{
            display: none;
        }
}
@media only screen and(max-width:700px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove-three{
        display: none;
    }
}
@media only screen and(max-height:400px){
    .table{
        overflow: unset;
    }
}
</style>