<template>
             <div class="chart-container">
             <apexchart type="donut" :width="$route.name=='Dashboard' ? 250 : 300"  :series="series" :options="chartOptions" :key="'hu'+change"></apexchart>
             <div v-if="$route.name=='Dashboard'" class="background-dashboard"></div>
             <div v-else class="background"></div>
             </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
props:['marketType'],
   components: {
          apexchart: VueApexCharts,
        },
  data(){
    return{
        params:{
          walletType:this.$route.name=='ExchangeWallet' || this.marketType==false?'EXCHANGE':'P2P',
        },
        change:0,
        series:[],
        wallets:[],
        chartOptions: {
            xaxis: {
              type: "numeric"
            },
            labels: [],
            colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
             
            dataLabels:{
              enabled:false,
            },
            states:{
               hover: {
                    filter: {
                        type: '2',
                        
                    }
                },

            },
           
            legend:{show:false},
            stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            width: 2,
            dashArray:2,      
            },
            plotOptions: {
                pie: {
                     expandOnClick: true,

                  donut: {
                    size: '80%',
                    background:'transparent',
                    // background:' rgba(242, 246, 255, 1)',
                    labels: {
                    show: true,
                    name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                  }








                  }
                }
              }
              ,
  tooltip: {
      enabled: false,
      fillSeriesColor: false,
      theme: false,
       fixed: {
          enabled: true,
          position: 'bottomRight',
        
      },
    
      custom:function() {
        // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        // console.log(seriesIndex)
        // series
      
      return (
       '<div class="tooltip"> <div class="tooltip-comp"><div class="container">'+
       'asdfaa'
       +'</div></div></div>'
      //  '<ul>' +
      // '<li><b>Price</b>: ' + data.x + '</li>' +
      // '<li><b>Number</b>: ' + data.y + '</li>' +
      // '<li><b>Product</b>: \'' + data.product + '\'</li>' +
      // '<li><b>Info</b>: \'' + data.info + '\'</li>' +
      // '<li><b>Site</b>: \'' + data.site + '\'</li>' +
      // '</ul>'
      );
    },
     
  }


        
        },
    }
  },
  methods:{
    async getWallets() {
            this.state.loading=true
            // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
            const res = await this.$axios.get('/wallets/customer-addresses',{params:this.params})
            let entireRes = Object.entries(res)
            const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.wallets = entireRes
            this.wallets=this.wallets.filter(wallet => wallet[0] !=='TOMAN')
            this.state.loading=false
        },
    chart() {
            // const vm = this
            // let sortedData = ''
            // sortedData = vm.wallets
            // sortedData = sortedData.filter(e => e[1].credit && e[1].credit > 0)
            // sortedData.sort((a, b) => (a[1].credit < b[1].credit) ? 1 : ((b[1].credit < a[1].credit) ? -1 : 0));
            // console.log('wallet', this.wallets);
            let index = 0
            this.wallets.forEach((e) => {
                    this.chartOptions.labels[index] = {
                        'name': this.$coinLabel[e[0]],
                        'value': e[1].credit || 0
                    }
                    this.series[index] = Math.abs(e[1].totalBalance) || Math.abs(e[1].wholePriceOfCredit) || 0
                    index++
                })
                ++this.change
        },
  },
  async mounted(){
    await this.getWallets();
    this.chart();

  }
}
</script>
<style lang="scss" scoped>
.chart-container{
  box-sizing: border-box;
  // margin-top:15px;
  width: 100%;
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: center;
}
.background{
  width: 158px;
  height: 158px;
  border-radius: 50%;
  position: absolute;
  background: var(--primary-color);
  box-shadow: inset 0px 4px 4px rgba(0, 119, 204, 0.2);
  box-sizing: border-box;
  top: 14.5%;
  // left: 15.5%;
  opacity: 1;
  // padding: 20px;
  z-index: -1;
}
.background-dashboard{
  width: 128px;
  height: 128px;
  border-radius: 50%;
  position: absolute;
  background: var(--primary-color);
  box-shadow: inset 0px 4px 4px rgba(0, 119, 204, 0.2);
  box-sizing: border-box;
  top: 15%;
  // left: 15.5%;
  opacity: 1;
  // padding: 20px;
  z-index: -1;
}
@media only screen and(max-width:1130px){
.chart-container{
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
}
}
</style>