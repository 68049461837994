<template>
 <div class="fees">
    <div class="container">
        <div class="title-text">
                <h3> {{state.lang.fee.title}} </h3>
        </div>
        <div class="fee container">
            <div class="fee-text">
                <p class="terms-explain">{{state.lang.fee.feeTermsTitle}}</p>
                <div v-for="(item,index) in feeterms" :key="index">
                <span class="square"></span>
                <p> {{item.text}}</p>
                </div>
            </div>
            <img src="../../assets/fees/feebanner.svg" alt="" class="banner">
        </div>
    </div>
    <div class="container table">
        <div class="user-level">
            <h4>{{state.lang.fee.levelsTitle}}</h4>
            <p>{{state.lang.fee.levelsBody}}</p>
        </div>
        <div class="user-level-chart">
            <div class="table-content">
                <table>
                <thead>
                    <tr>
                        <td class="start">{{state.lang.fee.userLevel}}</td>
                        <td class="center">{{state.lang.fee.last3MounthTrade}}</td>
                        <td class="end">{{state.lang.fee.fee}}</td>
                    </tr>
                </thead>
                <tr v-for="(item,index) in user" :key="index" :class="item.class">
                    <td class="start">{{state.lang.fee.userLevels}}{{item.level}}</td>
                    <td v-if="state.lan=='Fa'" class="center">میلیون تومان{{item.volume}}</td>
                    <td v-if="state.lan=='En'" class="center">TETHER {{item.volume}}</td>
                    <td class="end"> {{item.commision}}%</td>
                </tr>
                </table>
            </div>
        </div>
    </div>
        <div class="container table-two">
        <div class="user-level">
            <h4>{{state.lang.fee.coinFeeTitle}}</h4>
            <p>{{state.lang.fee.coinFeeBody}}
                  </p>
        </div>
        <div class="user-level-chart">
            <div class="table-content">
                <table>
                <thead>
                    <tr>
                        <td class="start"> {{state.lang.fee.coin}} </td>
                        <td class="center">{{state.lang.fee.minWithDrawal}}</td>
                        <td class="end">{{state.lang.fee.fee}}</td>
                    </tr>
                </thead>
                <tr v-for="(item,index) in feelist" :key="index" :class="item.class">
                    <td class="start">{{item.type}}</td>
                    <td class="center">{{item.minimum}}BTC</td>
                    <td class="end"> {{item.commision}}BTC</td>
                </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="container">
            <h4>{{state.lang.fee.rialFeeTitle}}</h4>
            <h6 class="h6">{{state.lang.fee.rialFeeBody}}</h6>
    </div>
</div>
</template>

<script>
    export default {
        name : '',
        data () {
            return{
            feeterms : [
                {
                    text : this.state.lang.fee.term1
                },
                {
                    text : this.state.lang.fee.term2             },
                {
                    text : this.state.lang.fee.term3                        }
            ],
            user : [
                {
                    level : 1,
                    volume : 200,
                    commision : 0.4
                },
                                {
                    level : 1,
                    volume : 200,
                    commision : 0.4
                },
                                {
                    level : 1,
                    volume : 200,
                    commision : 0.4
                },
                                {
                    level : 1,
                    volume : 200,
                    commision : 0.4
                },
                                {
                    level : 1,
                    volume : 200,
                    commision : 0.4,
                    class : 'no-border',
                },
                
            ],
            feelist : [
                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                },
                                {
                    type : 'بیت کوین',
                    minimum : 0.001,
                    commision : 0.001,
                    class : 'no-border'
                },
            ],
            coins: [],
            }
        }
    }
</script>

<style lang="scss" scoped>
.table ,.table-two{
    position: relative;

}
.table-two::after{
        content: ""; 
    position: absolute;
    top: 400px;
    right: -90px;
    width: 91%;
    height: 100%;
    opacity: .8;
    background: url('../../assets/fees/blob-24.png');
    background-repeat:no-repeat ;
    z-index: -10;
}
.table::after{
        content: ""; 
    position: absolute;
    top: -200px;
    right: -250px;
    width: 61%;
    height: 140%;
    opacity: .8;
    background: url('../../assets/fees/blob-23.png');
    background-repeat:no-repeat ;
    z-index: -10;
}
.table::before{
        content: ""; 
    position: absolute;
    top: 3px;
    right: 550px;
    width: 61%;
    height: 140%;
    opacity: .8;
    background: url('../../assets/fees/blob3.png');
    background-repeat:no-repeat ;
    z-index: -10;
}
.fees{
        display: flex;
    flex-direction: column;
    row-gap: 120px;
    padding-bottom: 40px;
}
h4{
    font-weight: 700;
    font-size: clamp(25px,1vw,36px);
}
.start{
    text-align: start;
}
.center{
    text-align: center;
}
.end{
    text-align: end;
}
.user-level{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.no-border{
    border: none ;
}
table{  
    font-weight: 400;
    font-size: clamp(10px,1vw,14px);
      z-index: 10;
    border-collapse: collapse;
    border-radius: 4px;
    padding: 5px ;
    box-sizing: border-box;
    width: 100%;
thead{
    tr{
         border-bottom:1px solid rgba(0, 119, 204, 0.3) ;
    }
}
tr{
    
    border-bottom:1px solid rgba(0, 119, 204, 0.1) ;
    td{
        box-sizing: border-box;
        padding: 20px 10px;
    }
}
}
.user-level-chart{
    .table-content{
        width: 80%;
                background: white;
            box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 5px;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

}
    p{
        // white-space: pre;
        font-weight: 400;
        font-size:clamp(11px,1vw,18px);
    }
.container{
    row-gap: 20px;
    flex-direction: column;
    align-items: flex-start;
}
.title-text{
    padding: 30px 0;
    display: flex;
    justify-content: flex-start;
                        h3{
            font-size: clamp(36px,1vw,48px);
        }
}
    .fee{
        img{
            width: 60%;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0;
        .fee-text{
            row-gap: 25px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .terms-explain{
               width: 120%;
            }
            div{
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                width: 80%;
                
            }
            .square{
                height: 14px;
                margin-top: 3px;
                box-sizing: border-box;
                padding: 7px;
                background: var(--secondary-color);
                border-radius: 4px;
            }
        }
    }
    @media only screen and(max-width:1400px){
                .table-two::after ,.table::after,.table::before{
                    display: none;
                }
}
    @media only screen and(max-width:750px){

        .fee{
            flex-direction: column;
            direction: rtl;
            row-gap: 40px;
            img{
                justify-self: center;
                align-self: center;
            }
        }
                    .terms-explain{
               width: 100% !important;
               justify-content: center;
            }
    }
</style>