<template>
  <div :key="state.lan" :class="state.lan=='Fa' ? 'fafont' : 'enfont'" id="app" :dir="state.lan === 'Fa'? 'rtl' : 'ltr'">
    <Simplemodal v-if="state.loading==true" :pic="require('./assets/icons/loading.svg')" :text="state.lang.otpNewPassword.modal" />
    <Changelanmodal v-if="state.changelanmodal" @closeChangelan="state.changelanmodal=false"/>
    <router-view></router-view>
  </div>
</template>


<script>
import Changelanmodal from './components/Tools/Changelanmodal.vue';
import Simplemodal from './components/Tools/Simplemodal.vue';
export default {
  name: 'App',
  components :{
    Simplemodal,
    Changelanmodal,
  },
}
</script>

<style scoped lang="scss">

</style>
