<template>
    <transition name="fade">
        <form @submit.prevent="sendTickets" class="modal-backdrop" @click.self="closeModale">
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
                <h3>{{state.lang.ticketModal.title}}</h3>
                <div class="coin-type">    
                        <div @click.self="sjToggle"  class="chose-coin">
                            <span @click.self="sjToggle">{{data.subject}}</span>
                            <img @click.self="sjToggle" :class="{'selected':selected}" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="selected==true" class="option-container">    
                                <div @click="choseCard(subject)" class="options" v-for="(subject,index) in subjects" :key="index">
                                    <span>{{subject}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                </div>
                <label v-if="data.subject=='other' || data.subject=='سایر موارد'" for="">
                    <input v-model="customSubject" @focus="toggleFocus" class="email" name="email" type="text" >
                    <span :class="[{'focus':(blanckspace)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.ticketModal.subject}} </span>
                </label>
                <label @click="textAreaBlanckSpace=true" for="">
                    <textarea v-model="data.text" @focus="textAreaBlanckSpace=true" class="email" name="email" type="text" ></textarea>
                    <span class="textarea-span" :class="[{'focus':(textAreaBlanckSpace)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.ticketModal.explanation}} </span>
                </label>
                <button :disabled="errorHandler" class="submit">{{errorHandler ? errorHandler : state.lang.ticketModal.btn}}</button>
            </div>
          </div>
        </form>
    </transition>
</template>

<script>
export default {
  name: 'Sellmodal',
  data() {
      return {
          data:{
            type:'',
            subject:this.state.lang.ticketModal.subject,
            text:'',
          },
          close : false,
          blanckspace:false,
          textAreaBlanckSpace:false,
          money:5728361,
          unfocus : true,
          selected:false,
          customSubject:'',
          subjects:[this.state.lang.ticketModal.sub1,this.state.lang.ticketModal.sub2,this.state.lang.ticketModal.sub3,this.state.lang.ticketModal.sub4,this.state.lang.ticketModal.sub5],
      }
  },
  computed:{
            errorHandler(){
                let problem
                if(this.data.subject=='' ||this.data.subject==this.state.lang.ticketModal.subject){
                    problem = this.state.lang.ticketModal.emptyTitle
                }else if(this.data.text==''){
                    problem = this.state.lang.ticketModal.emptyText
                } else{
                    problem=false
                }
                return problem
            }
        },
  methods : {
      closeModale() {
        this.$emit('close' , this.close)
      },
      toggleFocus () {
                this.blanckspace=true ;
                this.unfocus=true;
            },
      sjToggle(){
        this.selected=!this.selected
      },
      async sendTickets() {
        if(this.data.subject=='other' || this.data.subject=='سایر موارد') {
            this.data.subject=this.customSubject
            this.data.type='GENERAL'
          }else{
            this.data.type='TECHNICAL'
          }
            let formData = new FormData
            formData.append('text', this.data.text)
            formData.append('subject', this.data.subject)
            formData.append('type', this.data.type)
            this.state.loading = true
            const res = await this.$axios.post('/tickets', formData)
            if(res){
                  this.state.lan=='Fa' ? this.$error('درخواست شما با موفقیت ثبت شد', '', 'success') : this.$error('Your Request Accept Successfuly', '', 'success')
                  this.$emit('send' , this.close)
                }
            this.state.loading = false ;
      },
      choseCard(value){
        this.data.subject=value;
        this.selected=false;
      }
  },
  mounted () {
      
      // setTimeout(this.closeModale,3000);
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.coin-type')) {
              this.selected=false
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
   .note{
       display: flex;
       flex-direction: row;
       column-gap: 5px;
    //    align-items: center;
       color: var(--red-color);
       img{
           align-self: flex-start;
       }
   }
   .money{
       color: rgba(4, 24, 54, 0.342);
       font-weight: 300;
       font-size: clamp(10px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    z-index: 5;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       cursor: pointer;
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h3{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input,textarea{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
textarea{
    resize: none;
    height: 138px;
}
.textarea-span{
    top: 10px;
    // bottom: 0;
    max-height: 20px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>