<template>
    <div class="advancetrade">
        <Dashboardnavbar />
        <div class="page-content">
            <Tradesidebar />
            <div class="trade-container">
                <Marketnumbers
                 :key="'Marketnumbers'+$route.params.tradeFrom+$route.params.tradeTo"
                  @priceType="changeType" @coinType="changeCoin" />
                <Tradingview
                    :key="'Tradingview'+$route.params.tradeFrom+$route.params.tradeTo"
                    :priceType="priceType" :coin="coinType" />
                <Defth
                    :key="'Defth'+$route.params.tradeFrom+$route.params.tradeTo+state.orderChanged"
                 />
            </div>
        </div>
    </div>
</template>

<script>
import Dashboardnavbar from '../Profile/Dashboardnavbar.vue'
import Defth from './Defth.vue';
import Marketnumbers from './Marketnumbers.vue'
import Tradesidebar from './Tradesidebar.vue'
import Tradingview from './Tradingview.vue';
    export default {
        name:'Advancetrade',
        components: { Dashboardnavbar,
                Tradesidebar,
                Marketnumbers,
                Tradingview,
                Defth,
         },
         data(){
             return{
                 priceType:null,
                 coinType:null,
             }
         },
         methods:{
             changeType(priceType){
                 this.priceType=priceType;
             },
             changeCoin(coin){
                 this.coinType=coin;
             }
         }

    }
</script>

<style lang="scss" scoped>
.page-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    height: calc(100vh - 85px);
    box-sizing: border-box;
    column-gap: 24px;
    overflow: scroll;
}
.trade-container{
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 30px;
    height: 100%;
    // margin-top: 80px;
    width: 100%;
    position: relative;
}
@media only screen and(max-width:850px){
    .trade-container{
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>