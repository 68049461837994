<template>
    <div>
        <Swingbtn />
        <div class="container">
            <div class="top-content">
                <div class="banner">
                    <img src="../../assets/Privacypolicy/privacypic.png" alt="">
                    <h3 :class="{'order-en':state.lan=='En'}">{{state.lang.privacyPolicy.title}}</h3>
                </div>
                    <p class="text">
                        {{state.lang.privacyPolicy.explanation}}
                    </p>
            </div>
            <div class="terms">
                <h6>{{state.lang.privacyPolicy.secondTitle}}</h6>
                <p>{{state.lang.privacyPolicy.body}}</p>
                <!-- <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
                <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
                <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p> -->
                <!-- <p>{{privacyPolicy}}</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import Swingbtn from '../public/Swingbtn.vue'
    export default {
        name : 'Privacypolicy',
        components : {
                Swingbtn

        },
        data(){
            return{
                privacyPolicy: '',
            }
        },
        async mounted() {
            this.loading = true
            const res = await this.$axios.get('/exchange-info').catch(() => {
            })
            this.loading = false
            if (res && res.baseDTO) {
                this.privacyPolicy = res.baseDTO.privacyPolicy
            }
        }
    }
</script>

<style lang="scss" scoped>
.order-en{
    order: -2 !important;
}
p{
    font-weight: 700;
    font-size: clamp(8px,1vw,18px);
}
.container{
    .terms{
        h6{
            font-weight: 700;
            font-size: clamp(18px,1vw,24px);
        }
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .top-content{
            display: flex;
            flex-direction: column;
            .banner{
                h3{
                    font-size: clamp(35px,1vw,48px) !important;
                }
                img{
                    width: 50%;
                    max-width: 545px;
                    max-height: 470px;
                }
                direction: ltr;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
    }
    }
    flex-direction: column;
    align-items: center;
    row-gap: 60px;
}
@media only screen and(max-width:600px){
    .top-content{
        row-gap: 40px;
    }
    .banner{
        flex-direction: column !important;
        h3{
            text-align: center;
        }
    }
}

</style>