<template>
    <transition name="fade">
        <div class="modal-backdrop" @click.self="closeModale">
            <div class="modal">
              <img class="close" @click="closeModale" src="../../assets/icons/icons8-close.svg" alt="">
              <div class="content">
                  <h1> {{state.lang.shopModal.title}} </h1>
                  <div class="infos">
                    <div class="info">
                          <p> {{state.lang.shopModal.opration}} </p>
                         <p v-if="state.lan=='En'" :class="{'positive':type=='BUY','negetive':type=='SELL'}">{{type}} </p>
                         <p v-if="state.lan=='Fa'" :class="{'positive':type=='BUY','negetive':type=='SELL'}">{{$oprationType[type]}} </p>
                    </div>
                     <div class="info">
                          <p> {{state.lang.shopModal.amount}} </p>
                        <p style="font-family:'Yekan-en'">{{amount}} {{$coinUnit[tradeFrom]}}</p>
                    </div>
                    <div class="info">
                        <p> {{state.lang.shopModal.unitPrice}} </p>
                        <p>{{$coinLabel[tradeTo]}}  {{$toLocal(unitPrice,$decimal[tradeTo])}}</p>
                    </div>
                    <div class="info">
                        <p> {{state.lang.shopModal.total}} </p>
                        <p>{{$coinLabel[tradeTo]}}  {{$toLocal(totalPrice,$decimal[tradeTo])}}</p>
                    </div>
                    <div v-if="date" class="info">
                        <p> {{state.lang.shopModal.expireTime}} </p>
                        <p>{{$G2J(date,'faDateTime')}}</p>
                    </div>
                </div>
              </div>
              <button @click.prevent="confirmOrder" class="submit"> {{state.lang.shopModal.submit}} </button>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Shopmodal',
  props: ['totalPrice', 'unitPrice', 'amount', 'type', 'date', 'limit','confirm','tradeTo','tradeFrom'],
  data() {
      return {
      }
  },
  methods : {
      closeModale() {
        this.$emit('close' , false)
      },
      confirmOrder(){
          this.$emit('confirm' , true);
          this.closeModale();
      }
  },
  mounted () {
      
  },
  }
</script>
<style lang="scss" scoped>
h1{
    color: var(--secondary-color);
    font-weight: 700;
    font-size: clamp(14px,2vw,18px);
}
.submit{
    width: 100%;
}
.info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.content{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}
.infos{
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    align-items: center;
    img{
        max-width: 59px;
        max-height: 43px;
    }
}
.close{
    align-self: flex-start;
    cursor: pointer;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
.modal {
    display: flex;
    flex-grow: 1;
    max-width: 380px;
    min-height: 140px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    // row-gap: 16px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>