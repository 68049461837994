<template>
    <div class="banner">
        <div>
            <div class="container banner-container">
                <div class="banner-text">
                    <h1> {{state.lang.homePageBanner.zeenCurrency}} </h1>
                    <h4> {{state.lang.homePageBanner.title}}  </h4>
                    <h6> {{state.lang.homePageBanner.explanation}}  </h6>
                    <button class="start-btn" @click="$router.push({name : 'Signup'})">  {{state.lang.homePageBanner.startBtn}}  </button>
                </div>
                <div :class="state.lan=='Fa' ? 'banner-animation' : 'banner-animation-en'">
                    <div>
                        <img src="../../assets/Banner/svg.png" alt="">
                    </div>
                    <div class="graph-container">
                        <img src="../../assets/Banner/Chart.png" alt="">
                        <img class="circle" src="../../assets/Banner/Circle.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Banner',

    }
</script>

<style lang="scss" scoped>
.start-btn{
    font-size: clamp(12px,1vw,14px);
    font-family: 'Yekan-en';
}
h1{
    text-align: start;
}
    .graph-container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        row-gap: 60px;
        .circle{
            width: 60%;
        }
    }
    .banner-animation{
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 30px;
    }
    .banner-animation-en{
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 30px;
    }
    .banner-container{
        min-height: 740px;
        position: relative;
        overflow: hidden;
            // margin: 0 10px;
    }
    .banner-animation::before{
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 150px;
    right: unset;
    left: 55px;
    width: 55%;
    height: 106%;
    background-repeat: no-repeat;
    background: url('../../assets/Banner/background.png');
    z-index: -10;
    }
    .banner-animation-en::before{
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 150px;
    // right: 550px;
    right: 55px;
    width: 55%;
    height: 106%;
    background-repeat: no-repeat;
    background: url('../../assets/Banner/background.png');
    z-index: -10;
    }

    .banner-text{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        h1{
            color: rgba(0, 119, 204, 1);
            font-size: clamp(55px,1vw,72px);

        }
        h4{
                        font-size: clamp(30px,1vw,36px);
        }
        h6{
            font-size: clamp(15px,1vw,18px);
            width: 56%;
        }
        button{
            width: 25%;
            padding :6px 16px;
            border-radius: 3px;
           background:var(--banner-primary) ; 
            transition: 500ms;
        }
        button:hover{
            width: 40%;
            cursor: pointer;
        }
    }
        // @media only screen and (max-width:1200px){
        //             .banner-animation::before{
        //    right: 50%;
        // }
        // }

    @media only screen and (max-width:900px){
        .banner-container{
            flex-wrap: wrap;
        }
        .banner-animation::before{
           background: none;
           background-repeat:no-repeat ;
           position: unset;
        }
        .banner-animation-en::before{
           background: none;
           background-repeat:no-repeat ;
           position: unset;
        }
    .banner-container{
        justify-content: center;
    }

}
        @media only screen and (max-width:600px){
            .graph-container{
                display: none;
            }
            .banner-text{
                align-items: center;
    text-align: center;
            }
            .banner-text button{
                width: 35%;
            }
        }
</style>