<template>
    <div class="wallet">
    <Sellmodal :info="toman" @close="sellModal=false" v-if="sellModal" />
    <Chargemodel @closeChargeModaleEmit="chargemodal=false" v-if="chargemodal" />
    <Coinsellmodal :balance="credit" :coinType="selectedcoin" @openotp="otpModal" @closeChargeModaleEmit="coinsellmodal=false"  v-if="coinsellmodal"/>
    <Coinchargemodal :coinType="selectedcoin" @closeChargeModaleEmit="Coinchargemodal=false" v-if="Coinchargemodal" />
        <div class="table">
            <Wallettable :coins="wallets" @coinType="selectCoin" @sell="coinsellmodal=true" @charge="Coinchargemodal=true" />
        </div>
        <div class="wallet-chart">
            <Walletcards />
            <WalletChart :exchangeType="false" />
            <div class="wallet-info">
                <div class="wallet-item">
                    <p>{{state.lang.wallet.tomanWallet}}</p>
                    <p class="number">{{toman[1].wholePriceOfCredit}}</p>
                </div>
                <div class="wallet-item">
                    <p>{{state.lang.wallet.inDeal}}</p>
                    <p class="number">{{toman[1].blockedCredit}}</p>
                </div>
                <div class="wallet-item">
                    <p>{{state.lang.wallet.depositAble}}</p>
                    <p class="number">{{toman[1].wholePriceOfCredit - toman[1].totalBalance}}</p>
                </div>
                    <button @click="chargemodal=true" class="submit">{{state.lang.wallet.deposit}}</button>
                    <button @click="sellModal=true" class="submit">{{state.lang.wallet.withDrawal}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Walletcards from './Walletcards.vue'
import WalletChart from '../WalletChart.vue'
import Wallettable from './Wallettable.vue'
import Chargemodel from './Chargemodel.vue'
import Coinsellmodal from './Coinsellmodal.vue'
import Coinchargemodal from './Coinchargemodal.vue'
import Sellmodal from './Sellmodal.vue'
    export default {
        name :'Mainwallet',
        components :{
                Wallettable,
                Walletcards,
                WalletChart,
                Chargemodel, 
                Coinsellmodal,
                Coinchargemodal,
                Sellmodal,   
        },
        data () {
            return{
                credit:'',
                totalBalance : 0,
                wallets: [],
                toman:[],
                // wallet: [],
                coins:[],
                Coinchargemodal:false,
                otpmodal:false,
                coinsellmodal:false,
                chargemodal:false,
                sellModal:false,
                allmoney:123456789,
                indeal:456789,
                useable:3,
                selectedcoin:'',
            }
        },
        methods:{
            tomanWithDrawal(){
                this.sellModal=true;
                this.selectedcoin=this.coins.find((a) => a.coin === 'TOMAN')
            },
            selectCoin(v){

                // this.selectedcoin=v;
                    this.selectedcoin=this.coins.find((a) => a.coin === v)
                    this.credit=this.wallets.find((a) => a[0] === v)[1].credit
                    console.log(this.wallets);
            },
            otpModal() {
                console.log('hi');
                this.otpmodal=true;
                this.coinsellmodal=false;
            },
            async getWallets() {
            this.state.loading = true
            // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
            // this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                let entireRes = Object.entries(res)
                const sortCoins = this.$walletSortCoins
                entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = entireRes
                let total = 0
                for(var i=0 ; i <= this.wallets.length-1 ;i++) {
                    const lengthItem = this.wallets[i]
                    total = lengthItem[1].wholePriceOfCredit + total
                }
                this.toman=this.wallets.find((a) => a[0] === 'TOMAN')
                console.log(this.toman);
                this.wallets=this.wallets.filter(wallet => wallet[0] !=='TOMAN')
                this.totalBalance = this.$toLocal(total,0)

            }
            this.loading = false
            },
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/coins/details')
                this.coins = res.content
                this.state.loading = false
            },
        },
        mounted (){
            this.state.loading = true
            this.getWallets()
            this.getData()
            this.useable=this.allmoney-this.indeal;
        }
    }
</script>

<style lang="scss" scoped>
.wallet{
    display: flex;
    flex-direction: row;
    column-gap: 16px; 
    width: 100%;
    justify-content: space-between;
    height: 100%;
}
.wallet-chart{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 98%;
    width: 40%;
    justify-content: space-between;
    // align-items: center;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
        .wallet-info{
            font-weight: 700;
            font-size: clamp(10px,1vw,14px);
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            .wallet-item{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .number{
                    color: var(--secondary-color);
                }
            }
        }
    .chart-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .cards{
        padding-top: 150px;
        width: 90%;
        margin-top: 20px;
        justify-content: start;
        align-items: flex-start;
    }
}
.table{
    // max-height: 665px;
    height: 98%;
    width: 100%;
    // max-width: 605px;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    overflow: scroll;
}
@media only screen and(max-width:450px){
    .table{
        padding: 10px;
    }
}
@media only screen and(max-width:1200px){
    .wallet-info{
        .cards{
            width: 90%;
        }
    }
}
@media only screen and(max-width:900px){
    .wallet{
        // .cards{
        //     width: 80%;
        //     align-self: flex-end;
        //     margin-left: 20px;

        // }
        flex-direction: column;
        height: max-content;
        row-gap: 20px;
        width: 100%;
        .table,.wallet-chart{
            width: 100%;
            // .cards{
            //     justify-content: center;
            // }
        }
    }
}
</style>