<template>
    <div class="dashboard">
        <Notif style="cursor:pointer" v-if="closenotif && $route.name=='Dashboard'" @closenotif="closenotif=false" />
        <Exitmodal v-if="exitmodal" @closeChargeModaleEmit="exitmodal=false" />
        <Dashboardnavbar  @close="exitmodal=true" />
        <!-- <Navbar v-if="$route.name=='Validation'" /> -->
        <div class="parent-container">
        <Sidebar v-if="$route.name !== 'Validation'" @close="exitmodal=true" />
            <div class="dashboard-container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Dashboardnavbar from './Dashboardnavbar.vue';
import Exitmodal from './Exitmodal.vue';
import Sidebar from './Sidebar.vue';
import Notif from './Dashboard/Notif.vue';
// import Navbar from '../public/Navbar.vue';
    export default {
        name : 'Dashboard',
        components : {
            Sidebar,
                Dashboardnavbar,
                Exitmodal,
                Notif,
                // Navbar,
        },
        data () {
            return{
                exitmodal:false,
                closenotif:true,
            }
        },
                 }
</script>

<style lang="scss" scoped>
.dashboard{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}
.dashboard-container{
    height: 100%;
    width: 100%;
    }
.parent-container{
    // margin-top: 80px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    column-gap: 24px;
    // padding: 16px 120px;
    max-width: 1440px;
    padding:0 10px !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 105px);
    // overflow: scroll;

}
@media only screen and(max-width:700px){
    .parent-container{
        column-gap: 8px;
    }
}
@media only screen and(max-width:950px){
    .dashboard-container{
    width: none !important;
    padding-left: 0;
}
}
@media only screen and(max-width:1150px){
    .dashboard-container{
    width: 100%;
}
.dashboard-container{
    overflow: scroll;
}
    .mainpage{
        flex-direction: column !important;
        justify-content: center;
    }
    // .info{
    //     width: 100% !important;
    // }
    
}
@media only screen and(max-width:1350px){
        .parent-container{
        padding: 16px 5px;
        justify-content: center;
        // justify-content: space-between;
        }
    
    
}
// @media only screen and(max-width:500px){
//         .parent-container{
//         padding: 16px 10px;
//         column-gap: 10px;
//         }
    
    
// }
</style>