<template>
    <form @input="calculate" class="sell">
        <Shopmodal v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice"
                    :amount="amount" type="BUY" :date="eDate" :limit="marketclick" @confirm="accept" :tradeFrom="$route.params.tradeFrom" :tradeTo="$route.params.tradeTo" />
                <div class="total">
                    <div class="remain">
                        <p>{{state.lang.advanceTrade.trade.balance}} :</p>
                        <p v-if="state.lan=='En'" :key="state.orderChanged">{{$route.params.tradeTo}}  {{$toLocal(cash,$decimal[$route.params.tradeTo])}}</p>
                        <p v-if="state.lan=='Fa'" :key="state.orderChanged">{{$toLocal(cash,$decimal[$route.params.tradeTo])}}  {{$coinLabel[$route.params.tradeTo]}}</p>
                    </div>
                    <a @click.prevent="$router.push({name:'Wallet'})" href="/dashboard/wallet">{{state.lang.advanceTrade.trade.raiseBalance}}</a>
                </div>
                <div class="inputs">
                    <label for="">
                        <input @input="unitPrice = $toLocal(unitPrice,$decimal[$route.params.tradeTo])" :disabled="!marketclick" v-model="unitPrice" @focus="focus.buyprice=true" name="email" type="text" required>
                        <span :class="[{'focus':focus.buyprice==true},{'disable':!marketclick},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.advanceTrade.trade.price}} ({{state.lan=='Fa' ? $coinLabel[$route.params.tradeTo] : $route.params.tradeTo}}) </span>
                    </label>
                    <label for="">
                        <input @input="amount = $toLocal(amount,$decimal[$route.params.tradeFrom])" v-model="amount" @focus="focus.buyvolume=true" name="email" type="text" required>
                        <span :class="[{'focus':focus.buyvolume==true},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.advanceTrade.trade.amount}} ({{$coinUnit[$route.params.tradeFrom]}}) </span>
                    </label>
                    <div class="range">
                        <input  @input="putcash(percent)" min="0" max="100" v-model="percent" type="range" name="" id="">
                        <div class="spans">
                            <span @click="percent=0">.</span>
                            <span @click="percent=10">.</span>
                            <span @click="percent=20">.</span>
                            <span @click="percent=30">.</span>
                            <span @click="percent=40">.</span>
                            <span @click="percent=50">.</span>
                            <span @click="percent=60">.</span>
                            <span @click="percent=70">.</span>
                            <span @click="percent=80">.</span>
                            <span @click="percent=90">.</span>
                            <span @click="percent=100">.</span>
                        </div>   
                    </div>
                    <p class="showRange">{{$toLocal(percent/100,2)}}</p> 
                    <date-picker 
                        v-if="marketclick==true"
                        :placeholder="state.lang.advanceTrade.trade.expiredate+' '+ (state.lang.advanceTrade.trade.optional)" 
                        v-model="eDate"
                        :format="$dateFormat['en']"
                        :display-format="state.lan=='Fa' ? $dateFormat['faDateTime'] : $dateFormat['enDateTime']"
                        :clearable="true"
                        type="datetime"
                        :min="now"
                        :class="state.lan=='Fa' ? 'date-fa':'date-en'"
                        class="date"/>
                </div>
                <div class="total">
                    <div class="dealamount">
                    <p>{{state.lang.advanceTrade.trade.total}}</p>
                    <div class="minmax">
                        <span> {{state.lang.advanceTrade.trade.min}} {{min}} </span>
                        <span> {{state.lang.advanceTrade.trade.max}} {{max}} </span>
                    </div>
                    </div>
                    <div class="numbers">
                        <p v-if="state.lan=='En'">{{$toLocal(totalPrice,$decimal[$route.params.tradeTo])}}{{$route.params.tradeTo}}</p>
                        <p v-else>{{$toLocal(totalPrice,$decimal[$route.params.tradeTo])}} {{$coinLabel[$route.params.tradeTo]}}</p>
                        <div class="fee">
                            <p> {{state.lang.advanceTrade.trade.presentFee}}:  </p>
                            <p>{{wage}}%</p>
                        </div>
                    </div>
                </div>
                <button
                    v-if="checkBtn"
                    disabled
                    class="btnbuy">
                    {{checkBtn}}  
                </button>
                <button
                    v-if="!checkBtn"
                    @click.prevent="show=true"
                    class="btnbuy">
                    {{state.lang.advanceTrade.trade.buy}} {{state.lan=='Fa' ? $coinLabel[$route.params.tradeFrom] :$route.params.tradeFrom}}  
                </button>
                <button
                    v-if="state.userInfo.pending"
                    @click.prevent=""
                    disabled 
                    class="btnbuy">
                    {{state.lang.advanceTrade.trade.notAuthorized}}  
                </button>
                <!-- <button
                    v-if="state.userInfo"
                    @click.prevent="$router.push('/Account')"
                    class="btnbuy">
                    {{state.lang.advanceTrade.trade.completeTheAccount}}
                </button> -->
    </form>
</template>

<script>
import {tradeTo, tradeFrom, precision, Loop} from "@/library/reuseableFunction";
import Shopmodal from '../Tools/ShopModal.vue';
    export default {
  components: { Shopmodal },
        name:'BuyCrypto',
        props:['role','wage','cash','marketclick'],
        data (){
            return{
                show:false,
                errors:'',
                eDate: '',
                percent:0,
                unitPrice: '',
                amount: '',
                totalPrice:0,
                value:0,
                total:0,
                getAmount: false,
                calculating: false,
                decimal:this.$decimal[this.$route.params.tradeFrom],
                // coinName:this.state.lan=='Fa' ? this.$coinLabel[this.coinType]:this.coinType ,
                // priceName:this.state.lan=='Fa' ? this.$coinLabel[this.priceType]:this.priceType ,
                time:'',
                loop: undefined,
                focus:{
                    buyprice:false,
                    buydate:false,
                    buyvolume:false,
                },
            }
        },
        watch: {
            'marketclick'(){
                this.unitPrice='';
                this.amount='';
                if(this.marketclick){
                    this.loop.stop();
                }
            },
            'unitPrice'(){
                if(this.marketclick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                }
            },
            'percent'(){
                this.putcash(this.percent);
                if(this.marketclick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                    this.amount='';
                    this.totalPrice=0;
                }
            },
            'amount'(){
                this.focus.buyvolume=true;
            },
            'state.buyAmount'() {
                this.amount = this.$toLocal(this.state.buyAmount, this.decimal)
                this.unitPrice = this.$toLocal(this.state.buyPrice, this.precision)
                this.calculate()
            },
            'state.buyPrice'() {
                this.amount = this.$toLocal(this.state.buyAmount, this.$decimal[this.$route.params.tradeFrom])
                this.unitPrice = this.$toLocal(this.state.buyPrice, this.precision)
                this.calculate()
            }
        },
        computed:{
            checkBtn(){
                let errors = ''
                if(!this.marketclick && this.$S2N(this.amount) && !this.$S2N(this.unitPrice)){
                    errors= this.state.lang.advanceTrade.errors.notAllowed
                }
                else if(this.marketclick && !this.$S2N(this.unitPrice)){
                        errors= this.state.lang.advanceTrade.errors.emptyPrice
                }
                else if(!this.$S2N(this.amount)){
                        errors= this.state.lang.advanceTrade.errors.emptyAmount
                }
                else if(this.$S2N(this.totalPrice) > this.$S2N(this.cash)){
                    errors= this.state.lang.advanceTrade.errors.lowBalance
                }
                else if(this.$S2N(this.amount) < this.min){
                    errors= this.state.lang.advanceTrade.errors.lowerThanMin
                }
                else if(this.$S2N(this.amount) > this.max){
                    errors= this.state.lang.advanceTrade.errors.higherThanMax
                } else{
                    errors=false;
                }
                return errors ;
            },
            now() {
                return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
            },
            readonly() {
                return (this.marketclick && !this.$S2N(this.unitPrice))
                    || !this.$S2N(this.amount)
                    || this.$S2N(this.amount) < this.min
                    || this.$S2N(this.amount) > this.max
                    // || this.$S2N(this.amount) > this.$S2N(this.cash)
                    || (this.value > 1 && !this.state.userInfo.supervisor)
                    || this.load
                    || (!this.marketclick && this.state.userInfo.supervisor)
            },
            disableBtn() {
                return this.$S2N(this.amount)
                    && (this.state.userInfo.supervisor && this.state.userInfo.authorized)
                    && (!this.marketclick && this.tableContentsSell)
                    && (!this.marketclick && this.tableContentsBuy)
                    && this.$S2N(this.amount) > this.min
                    && this.$S2N(this.amount) < this.max
                    && this.$S2N(this.unitPrice)
                    // || this.$S2N(this.amount) > this.$S2N(this.cash)
                    && !this.state.loading
                    // && (this.value > 1 && !this.state.userInfo.supervisor)
                    // && (!this.limit && this.state.userInfo.supervisor)
            },
            min(){
                return this.role?.minAmount
            },
            max(){
                return this.role?.maxAmount
            },
            tradeTo, tradeFrom, precision
        },

        methods:{
            async accept() {
                console.log('hi');
                this.loading = true;
                let orderDetail;
                let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

                if (this.marketclick) {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `LIMITED_BUY`,
                        expiresAt: this.eDate || null,
                        unitPrice: this.$S2N(this.unitPrice),
                        wholePrice: this.$S2N(this.totalPrice),
                    }
                } else {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `MARKET_BUY`,
                    }
                }
                try {
                    const res = await this.$axios.post(`/orders/buy`, orderDetail)
                    this.loading = false
                    // this.$emit('close')
                    ++this.state.orderChanged
                    this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
                    this.$emit("accept", res.message)
                } catch (error) {
                    this.loading = false
                    // this.$emit('close')
                    if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                        await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                    }
                }
            },
             async putcash(p) {
                if(this.marketclick){
                    let up = this.$S2N(this.unitPrice)
                    if (up) {
                        this.percent = p
                        let e = ((this.percent/100) * this.cash) / up
                        this.amount = this.$toLocal(e,this.$decimal[this.$route.params.tradeFrom])
                        this.calculate()
                        this.danger = 0
                    } else {
                        this.danger = 1
                    }
                }else if(!this.marketclick){
                        // let up = this.$S2N(this.unitPrice)
                        this.percent = p
                        let e = (this.percent/100) * this.cash
                        const res = await this.$axios('/orders/amount-via-price', {
                        params: {
                            amount: e,
                            marketType: this.tradeFrom + '_' + this.tradeTo,
                            orderType: 'MARKET_BUY'
                        }
                        })
                        this.getAmount = false
                        if (res) {
                            this.amount = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[this.$route.params.tradeFrom])
                            this.calculate()
                        }
                        // this.amount = this.$toLocal(e,this.$decimal[this.$route.params.tradeFrom])
                        // this.calculate()
                        this.danger = 0
                }
            },
            localCalculate() {
                const up = this.$S2N(this.unitPrice)
                const a = this.$S2N(this.amount)
                this.totalPrice = this.$toLocal((up * a), this.precision)
                this.value = this.$S2N(this.totalPrice) / this.cash
                // this.percent=this.value
            },
            async serverCalculate() {
                this.loop ? this.loop.stop() : undefined
                this.calculating = true
                const a = this.amount
                await this.$sleep(500)
                if (a === this.amount && !this.marketclick) {
                    this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
                    this.loop.start()
                }
            },
            async getPriceFromServer() {
                if(!this.marketclick){
                let amount = this.$S2N(this.amount);
                const [res,] = await this.$http.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY'
                    }
                })
                if (res) {
                    this.totalPrice = this.$toLocal(res.baseDTO.wholePrice, this.precision);
                    this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount), this.precision);
                    this.value = this.$S2N(this.totalPrice) / this.cash
                }
                this.calculating = false
            }
            },
            async getAmountFromServer(p) {
                this.percent = p
                let e = this.percent * this.cash
                if (e) {
                    this.getAmount = true
                    const res = await this.$http('/orders/amount-via-price', {
                        params: {
                            marketType: this.tradeFrom + '_' + this.tradeTo,
                            orderType: 'MARKET_BUY',
                            amount: e
                        }
                    })
                    this.getAmount = false
                    if (res) {
                        this.amount = this.$toLocal(res.baseDTO.wholePrice, this.decimal)
                        this.calculate()
                    }
                }
            },
            calculate() {
                if (this.marketclick && this.unitPrice && this.amount) {
                    this.localCalculate()
                } else if (!this.marketclick && this.$S2N(this.amount)) {
                    this.serverCalculate()
                }
            },
        },
        beforeDestroy() {            
            this.loop ? this.loop.stop() : undefined
        }

    }
</script>

<style lang="scss" scoped>
.minmax{
    display: flex;
    flex-direction: column;
    height: 50%;
}
.dealamount{
    display: flex;
    flex-direction: column;
}
.date{
    width: 100%;
}
.showRange{
    align-self:center ;
}
.btnbuy{
       background: var(--positive-color);
    color: white;
    width: 100%; 
}
.numbers{
    display: flex;
    flex-direction: column;
    // row-gap: 5px;
    justify-content: space-between;
    text-align: end;
    .fee{
        display: flex;
        flex-direction: row;   
        column-gap: 5px;     
    }
}
.total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: rgba(63, 63, 63, .7);
    font-weight: 300;
    font-size: clamp(10px,2vw,13px);
    a{
        transform: none;
        color: rgba(0, 119, 204, 1);
        font-weight: 300;
        font-size: clamp(10px,1vw,13px);
    }
}
.spans{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    height: 1px;
    left: 7px;
    width: 96.3%;
    bottom: 32px;
    cursor: default;
    position: absolute;
    border-radius: 100px;
    span{
        &:hover{
            
            cursor: pointer;
        }
    }
    // z-index: -3;
    // background: var(--secondary-color);
    border-radius: 100px;
}
.range{
        position: relative;
    datalist,option{
        opacity: 0;
    }
   transform: rotate(180deg);
   input{
       padding: 0;
   }
}
.inputs{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
}
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
        label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 5px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
    // max-width: 266px;
    max-height: 36px;
}
.remain{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    color: rgba(63, 63, 63, .7);
    font-weight: 300;
    font-size: clamp(10px,2vw,13px);
}
.sell{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 50%;
        box-sizing: border-box;
        padding: 8px 16px;
    }
button{
    width: 50%;
        font-weight: 700;
    font-size: clamp(10px,1vw,14px);
    color: rgba(164, 198, 222, 1);
    &:hover{
        cursor: pointer;
    }
}
@media only screen and(max-width:550px){
    .spans{
        padding: 0 4px;
    }
    .total{

        flex-direction: column;
        align-items: flex-start;
    }
    .remain{
        flex-direction: column;
        align-items: flex-start;
    }
    .numbers{
        p{
            text-align: start;
        }
    }
}
@media only screen and(max-width:400px){
    .sell{
        padding: 8px !important;
    }
    .fee{
        flex-direction: column !important;
    }
}

</style>