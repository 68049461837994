<template>
    <div class="order-history">
        <div class="order-container">
            <h3>{{state.lang.dashboardOrders.orderHistory}}</h3>
            <div class="orders">
                <template v-for="(order,index) in tableContents">
                    <div class="order" :key="index">
                        <div class="order-time-store">
                            <div v-if="order.tokenType" class="exchange-detail">
                                <span> {{state.lang.dashboardOrders.channel}} {{order.tokenType}} </span>
                            </div>
                            <div v-else-if="order.orderType=='EXCHANGE_SELL'" class="exchange-detail">
                                <span> {{state.lang.dashboardOrders.exchange}} {{order.tokenType}} </span>
                            </div>
                            <div v-else class="p2p-detail">
                                <span v-if="order.orderType=='LIMITEED_BUY' || order.orderType=='LIMITED_SELL'"> {{state.lang.dashboardOrders.promarket}} </span>
                                <span v-else> {{state.lang.dashboardOrders.limit}} </span>
                            </div> 
                            <span> {{$G2J(order.createdAtDateTime)}} </span>
                        </div>
                        <div class="order-situation-type">
                            <div class="coin-type">
                            <p v-if="order.orderType=='LIMITED_BUY' || order.orderType=='MARKET_BUY'  || order.orderType=='EXCHANGE_BUY'">{{state.lan=='En'?order.destinationCoin:$coins[order.destinationCoin].persianName}}</p>
                            <p v-if="order.orderType=='LIMITED_SELL' || order.orderType=='MARKET_SELL'  || order.orderType=='EXCHANGE_SELL'">{{state.lan=='En'?order.sourceCoin:$coins[order.sourceCoin].persianName}}</p>
                            <p>-</p>
                            <p v-if="order.orderType=='LIMITED_BUY' || order.orderType=='MARKET_BUY'  || order.orderType=='EXCHANGE_BUY'" class="finish"> {{state.lang.dashboardOrders.buy}} </p>
                            <p v-if="order.orderType=='LIMITED_SELL' || order.orderType=='MARKET_SELL'  || order.orderType=='EXCHANGE_SELL'" class="cancel"> {{state.lang.dashboardOrders.sell}} </p>
                            </div>
                            <div class="coin-situation">
                                <span v-if="order.orderStatus=='IS_OPEN'" class="ispending"> {{state.lang.dashboardOrders.inProgrres}} </span>
                                <span v-else-if="order.orderStatus=='FINISH' || order.orderStatus=='FINISH_WITHOUT_TRANSACTION'" class="finish"> {{state.lang.dashboardOrders.done}} </span>
                                <span v-else class="cancel"> {{state.lang.dashboardOrders.canceled}} </span>
                                <!-- <p>-</p> -->
                                <!-- <button :class="{'enable':order.finish==true || order.cancel==true}"> {{state.lang.dashboardOrders.showBtn}} </button> -->
                            </div>
                        </div>
                        <div class="details-table">
                            <table>
                                <thead>
                                    <tr>
                                        <td>{{state.lang.dashboardOrders.tableAmount}}</td>
                                        <td>{{state.lang.dashboardOrders.tableTotalPrice}}</td>
                                        <td class="remove">{{state.lang.dashboardOrders.tablePrice}}</td>
                                        <td>{{state.lang.dashboardOrders.tableDone}}</td>
                                    </tr>
                                </thead>
                                <tr class="item">
                                    <td v-if="order.orderType=='LIMITED_BUY' || order.orderType=='MARKET_BUY'  || order.orderType=='EXCHANGE_BUY'"> {{$toLocal(order.amount,$decimal[destinationCoin])}} </td>
                                    <td v-if="order.orderType=='LIMITED_SELL' || order.orderType=='MARKET_SELL'  || order.orderType=='EXCHANGE_SELL'"> {{$toLocal(order.amount,$decimal[sourceCoin])}} </td>
                                    <td>{{$toLocal(order.unitPrice,0)}}</td>
                                    <td class="remove">{{$toLocal(order.wholePrice,0)}}</td>
                                    <td v-if="order.orderType=='LIMITED_BUY' || order.orderType=='MARKET_BUY' || order.orderType=='EXCHANGE_BUY'"> {{$toLocal(order.executedAmount,$decimal[destinationCoin])}} </td>
                                    <td v-if="order.orderType=='LIMITED_SELL' || order.orderType=='MARKET_SELL' || order.orderType=='EXCHANGE_SELL'"> {{$toLocal(order.executedAmount,$decimal[sourceCoin])}} </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Orderhistory',
        data () {
            return{
                tableContents:[],
                ordersFa :[
                    {
                        targetmarket :'شبکه ارسال TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'در حال بررسی',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'شبکه ارسال TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'در حال بررسی',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'شبکه ارسال TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'در حال بررسی',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'بازار-معاملات حرفه ای-RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'لغو شده',
                        cancel:true,
                        finish:false,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'بازار-معاملات حرفه ای-RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'در حال بررسی',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'شبکه ارسال TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'انجام شده',
                        cancel:false,
                        finish:true,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.386,
                    },
                    {
                        targetmarket :'شبکه ارسال TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'بیت کوین',
                        dealtype:'خرید',
                        situation:'انجام شده',
                        cancel:false,
                        finish:true,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.386,
                    },
                ],
                ordersEn :[
                    {
                        targetmarket :' Channel TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'In Progress',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :' Channel TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'Sell',
                        situation:'In Progress',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :' Channel TRC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'In Progress',
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'Market - Pro Deals - RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'Canceled',
                        cancel:true,
                        finish:false,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'Market - Pro Deals - RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'In Progress',
                        cancel:false,
                        finish:false,
                        ispending:true,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.00,
                    },
                    {
                        targetmarket :'Market - Pro Deals - RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'Done',
                        cancel:false,
                        finish:true,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.386,
                    },
                    {
                        targetmarket :'Market - Pro Deals - RTC20',
                        year:2021 ,
                        mounth:5 ,
                        day:12 ,
                        hour:13,
                        minute:45,
                        coin:'BITCOIN',
                        dealtype:'BUY',
                        situation:'Done',
                        cancel:false,
                        finish:true,
                        ispending:false,
                        volume:0.386,
                        price:998525368000,
                        total:12738625,
                        done:0.386,
                    },
                ],
                orders:[]
            }
        },
        methods:{
            async getActiveOrders() {
                const res = await this.$axios.get('/orders', {
                    params: {
                        size:1000,
                    }
                })
                this.tableContents = res.content || [];
                this.$emit('update:activeLength', this.tableContents.length);
                this.state.loading = false;
            },
        },
        mounted (){
            this.getActiveOrders();
        }
    }
</script>

<style lang="scss" scoped>
table{
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: clamp(9px,1vw,12px);
}
.cancel{
    color:  rgba(255, 0, 92, 1);
}
.finish{
    color: rgba(51, 158, 0, 1);   
}
.item{
    color: rgba(51, 158, 0, .5);
}
.order-history{
    height: 35%;
}
    .order-container{
        // max-width: 384px;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        box-sizing: border-box ;
        padding: 16px;
        box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    }
    .orders{
        overflow: scroll;
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 8px;

    }
    .order{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #0077cc49;
    }
    .order-situation-type,.order-situation-store,.time,.coin-type,.coin-situation,.order-time-store{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 3px;
    }
    button{
        padding: 0px;
        background: none;
        color: #0077CC;
        opacity: 0.5;   
        font-size: clamp(10px,1.5vw,14px);
    }
        .enable{
            opacity: 1; 
            cursor: pointer;
        }
@media only screen and(max-width:1150px){
    .order-container{
        max-width: none;
    }
}
@media only screen and(max-width:450px){
    .remove{
        display: none;
    }
    .order-time-store ,.order-situation-type,.order-situation-store{
        flex-direction: column;
        div,.order-targetmarket{
            justify-content: center;
            text-align: center;
        }
    }
}
</style>