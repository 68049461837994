<template>
    <div class="cards">
        <div class="card-container" v-for="(card,index) in slicedcards" :key="index" :class="[state.lan=='Fa'?background[index]:backgroundEn[index],fixbackground[index]]" >
        <template v-if="card.cardNumber && card.shebaCode">
            <div class="names">
                <!-- <p>{{card.bankname}}</p> -->
                <div class="username">
                    <p>{{state.userInfo.firstName}}</p>
                    <p>{{state.userInfo.lastName}}</p>
                </div>
            </div>
            <div class="cardnumber">
                {{$separator(card.cardNumber)}}
            </div>
             <div class="date">
                <p>{{card.shebaCode}}</p>
                <p>IR</p>
            </div>
        </template>
        </div>
        <div v-if="cards1.length > 1" @click="changeCard" :class="{'change-card-en':state.lan=='En'}" class="change-card">
                    <img src="../../../assets/icons/new-card.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {

        name : 'Walletcards',
        data () {
            return {
                start:0,
                end:3,
                slicedcards : [],
                fixbackground:['blue1','yellow1','green1'],
                background:['blue','yellow','green'],
                backgroundEn:['blue-en','yellow-en','green-en'],
                cards1:this.state.userInfo.bankAccounts,
            }
        },
        methods : {
            changeCard () {
                if(this.state.userInfo.bankAccounts.length>this.end){
                    this.slicedcards=this.cards1.slice(this.start,this.end);
                    console.log(this.slicedcards);
                    this.start++
                    this.end++
                } else {
                    this.start=0;
                    this.end=3;
                }
                if(this.state.lan=='Fa'){
                    if(this.background[0]=='blue'){
                        this.background=['yellow','green','blue'];
                    }else if(this.background[0]=='yellow'){
                        this.background=['green','blue','yellow'];
                    } else {
                        this.background=['blue','yellow','green'];
                    }

                }else{
                    if(this.backgroundEn[0]=='blue-en'){
                        this.backgroundEn=['yellow-en','green-en','blue-en'];
                    }else if(this.backgroundEn[0]=='yellow-en'){
                        this.backgroundEn=['green-en','blue-en','yellow-en'];
                    } else {
                        this.backgroundEn=['blue-en','yellow-en','green-en'];
                    }
                }
            },
        },
        mounted () {
            this.changeCard();
        }
    }
</script>

<style lang="scss" scoped>
.change-card{
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 115px;
    background: rgba(51, 158, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
        z-index: 5;
        right: -5px;
}
.change-card-en{
 right: -25px !important;   
}
.date{
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
}
.names,.stock,.username{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 5px;
}
.cardnumber{
    text-align: center;
}
.cards{
    width: 60%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    // height: 100%;
        // align-self: flex-end;
}
.card-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 132px;
    max-width: 313px;
    p{
            font-size: clamp(10px,1vw,14px);
    }
}
.blue{
    transition: 1s;
    z-index: 5;
    top: 0px;
    right: 0px;
}
.blue-en{
    z-index: 5;
    transition: 1s;
    right: -20px !important;
    top: 0 !important;
}
.blue1{
    background: url('../../../assets/dashboard/Card-blue.png');
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    color: var(--primary-color);
    position: absolute;
}
.yellow{
    z-index: 4;
    transition: 1s;
    top: -10px;
    right: 15px;
}
.yellow-en{
     z-index: 4;
    //  top: -80px;
     top: -10px;
     right: -10px !important;
     transition: 1s;
}
.yellow1{
    background: url('../../../assets/dashboard/Card-yellwo.png');
    background-repeat: no-repeat;
    background-position: center;
        box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    position: absolute;
    
}
.green{
    transition: 1s;
    z-index: 3;
     top: -20px;
right: 25px;
}
.green-en{
    z-index: 3;
        //  top: -20px;
         top: -20px;
         transition: 1s;
    right: 0px !important;
}
.green1{
    background: url('../../../assets/dashboard/Card-green.png');
    background-repeat: no-repeat;
    background-position: center;
        box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    position: absolute;
    .stock,.cardnumber{
        color: var(--primary-color);
    }

}
@media only screen and(max-width:900px){
        .yellow{
        right: 10px;


    }
    .green{
        right: 20px ;
    }
    .change-card{
        top: 115px;
    }
}
@media only screen and(max-width:950px){
    .cards{
    width: 70%;
    }
}
@media only screen and(max-width:900px){
    .cards{
    width: 100%;
    }
}
</style>