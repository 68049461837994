<template>
    <div>
        <RemoveOrderConfirmModal :id="id" v-if="show" @close="show= false" @cancel="removeorder"/>
         <div class="table">
                <div class="btn">
                    <button @click.prevent="ordertype='active'" :class="{'submit':ordertype=='active'}"> {{state.lang.advanceTrade.trade.activeOrders}} </button>
                    <button @click.prevent="ordertype='history'" :class="{'submit':ordertype!=='active'}"> {{state.lang.advanceTrade.trade.orderHistory}}  </button>
                </div>
                <div v-if="ordertype!=='active'" class="orders">
                    <table>
                        <thead>
                            <tr>
                                <td>{{state.lang.advanceTrade.trade.dealType}} </td>
                                <td>{{state.lang.advanceTrade.trade.price}} </td>
                                <td class="remove-two">{{state.lang.advanceTrade.trade.amount}} </td>
                                <td class="remove-two">{{state.lang.advanceTrade.trade.total}}  </td>
                                <!-- <td >{{state.lang.advanceTrade.trade.fee}} </td> -->
                                <td class="remove"> {{state.lang.advanceTrade.trade.time}} </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in historyContent" :key="index">
                                <td v-if="item.orderType=='LIMITED_BUY'" class="positive">{{state.lang.advanceTrade.activeOrders.buy}}</td>
                                <td v-if="item.orderType=='MARKET_BUY'" class="positive">{{state.lang.advanceTrade.activeOrders.buy}}</td>
                                <td v-if="item.orderType=='LIMITED_SELL'" class="negetive">{{state.lang.advanceTrade.activeOrders.sell}}</td>
                                <td v-if="item.orderType=='MARKET_SELL'" class="negetive">{{state.lang.advanceTrade.activeOrders.sell}}</td>
                                <td>{{$toLocal(item.unitPrice,$decimal[$route.params.tradeFrom])}}</td>
                                <td>{{$toLocal(item.amount,$decimal[$route.params.tradeFrom])}}</td>
                                <td class="remove-two">{{$toLocal(item.wholePrice,$decimal[$route.params.tradeTo])}}</td>
                                <!-- <td class="remove-two">{{item.fee}}%</td> -->
                                <td class="remove"> {{item.createdAtDateTime}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="ordertype=='active'" class="orders">
                    <table>
                        <thead>
                            <tr>
                                <td>{{state.lang.advanceTrade.trade.dealType}} </td>
                                <td>{{state.lang.advanceTrade.trade.price}} </td>
                                <td>{{state.lang.advanceTrade.trade.amount}} </td>
                                <td class="remove-two">{{state.lang.advanceTrade.trade.total}}  </td>
                                <td class="remove"> {{state.lang.advanceTrade.trade.done}} </td>
                                <td class="remove-two">{{state.lang.advanceTrade.trade.opration}} </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tableContents" :key="index">
                                <td v-if="item.orderType=='LIMITED_BUY'" class="positive">{{state.lang.advanceTrade.activeOrders.buy}}</td>
                                <td v-if="item.orderType=='MARKET_BUY'" class="positive">{{state.lang.advanceTrade.activeOrders.buy}}</td>
                                <td v-if="item.orderType=='LIMITED_SELL'" class="negetive">{{state.lang.advanceTrade.activeOrders.sell}}</td>
                                <td v-if="item.orderType=='MARKET_SELL'" class="negetive">{{state.lang.advanceTrade.activeOrders.sell}}</td>
                                <td>{{$toLocal(item.unitPrice,$decimal[$route.params.tradeFrom])}}</td>
                                <td>{{$toLocal(item.amount,$decimal[$route.params.tradeFrom])}}</td>
                                <td class="remove-two">{{$toLocal(item.wholePrice,$decimal[$route.params.tradeTo])}}</td>
                                <td class="remove-two">{{$toLocal(item.executedPercent,2)}}%</td>
                                <td @click="cancelOrder(item.id)" class="secondary-color remove">{{state.lang.advanceTrade.trade.cancle}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    </div>
</template>

<script>
import {tradeFrom, tradeTo, precision, Loop} from "@/library/reuseableFunction";
import RemoveOrderConfirmModal from './RemoveOrderConfirmModal.vue';
    export default {
        name:'Activeorders',
        components:{
            RemoveOrderConfirmModal,
        },
        data() {
            return{
                show:false,
                ordertype:'active',
                id:'',
                loop:undefined,
                loopHistory:undefined,
                page: 0,
                lastPage:1,
                activeorders:[
                    {
                        type:'خرید',
                        price:1354625000,
                        volume:0.836,
                        total:998525368000,
                        finished:25,
                        opration:'لغو سفارش',
                        fee:0.4,
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                    },
                    {
                        type:'خرید',
                        price:1354625000,
                        volume:0.836,
                        total:998525368000,
                        finished:25,
                        opration:'لغو سفارش',
                        fee:0.4,
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                    },
                    {
                        type:'خرید',
                        price:1354625000,
                        volume:0.836,
                        total:998525368000,
                        finished:25,
                        opration:'لغو سفارش',
                        fee:0.4,
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                    },
                    {
                        type:'خرید',
                        price:1354625000,
                        volume:0.836,
                        total:998525368000,
                        finished:25,
                        opration:'لغو سفارش',
                        fee:0.4,
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                    },
                    {
                        type:'خرید',
                        price:1354625000,
                        volume:0.836,
                        total:998525368000,
                        finished:25,
                        opration:'لغو سفارش',
                        fee:0.4,
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                    },
                ],
                historyContent:[],
                tableContents:[],
            }
        },
        computed: {
            precision, tradeFrom, tradeTo
        },
        methods:{
            cancelOrder(id){
                this.id=id
                this.show=true
            },
            async removeorder(e) {
                this.state.loading = true
                const [res,] = await this.$http.delete(`orders/${e}`)
                if (res) {
                    ++this.state.orderChanged
                    this.state.lan=='Fa' ? this.$error('سفارش شما با موفقیت حذف شد', '', 'success') : this.$error('Your Order Removed Successfuly', '', 'success')
                    if (this.state.loop) {
                        this.loop.start()
                    } else {
                        this.getActiveOrders()
                    }
                }
            },
            async getLastOrders() {
            if (this.page <= this.lastPage) {
               //for scroll loading
                this.page++ //for scroll loading
                let res = await this.$axios.get('/orders', {
                    params: {
                        market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        page: this.page,
                        size: 20
                    }
                })
                if (res.content)
                    res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
                this.historyContent = res.content;
                this.state.loading = false
            }
            },
            async getActiveOrders() {
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    }
                })
                this.tableContents = res.content || [];
                this.$emit('update:activeLength', this.tableContents.length)
                this.state.loading = false
            },
        },
        mounted() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
                this.loop.start()
            } else {
                this.getActiveOrders()
            }
            if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.loop.start()
            } else {
                this.getLastOrders();
            }
        },
        beforeDestroy() {
            this.loop.stop();
        }
    }
</script>

<style lang="scss" scoped>
.cointype{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 5px;
    .coinname{
        color:var(--secondary-color);
    }
}
.secondary-color{
    color: var(--secondary-color);
    cursor: pointer;
}
.disabled{
    opacity: .5;
    &:hover{
        cursor: default;
    }
}
.table{
    display: flex;
    flex-direction: column;
    width: 100%;
    // border: 0.5px solid #a4c6de65;
    border-top: none;
    border-radius: 4px;
    height: 100%;
    .orders{
        width: 100%;
        box-sizing: border-box;
        padding: 16px;
        overflow: scroll;
        table{
            width: 100%;
            thead{
                color: #041836;
                font-weight: 400;
                font-size: clamp(11px,1vw,14px);
            }
            tbody{
                color: rgba(63, 63, 63, .8);
                font-weight: 100 !important;
                font-size: clamp(10px,1vw,13px);
                tr{
                    height: 30px;
                }
            }
            td{
                text-align: center;
            }
        }
    }
    .btn{
        width: 100%;
        button{
            width: 50%;
            background: var(--primary-color);
            color: var(--secondary-color);
                font-weight: 700;
                font-size: clamp(10px,1vw,14px);
            &:hover{
                cursor: pointer;
            }
        }
    }
}
@media only screen and(max-width:800px){
    .remove{
        display: none;
    }
    .header{
        flex-direction: column;
        justify-content: center;
        .coin-type{
            width: 100%;
            justify-content: center;
        }
        .coin-info{
            width: 100%;
            text-align: center;
        }
    }
}
@media only screen and(max-width:700px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove-three{
        display: none;
    }
}
</style>