<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
              <h3>{{state.lang.walletChargeModal.title}}</h3>
              <p>{{state.lang.walletChargeModal.body}}</p>
              <label for="">
                    <input @focus="toggleFocus" class="email" name="email" type="text" >
                    <span :class="[{'focus':(blanckspace)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.walletChargeModal.input}}</span>
              </label>
              <div class="coin-type">    
                        <div  class="chose-coin">
                            <span @click="cardToggle">{{cardnumbers[0]}}</span>
                            <img @click="cardToggle" :class="{'selected':selected}" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="selected==true" class="option-container">    
                                <div @click="choseCard(card)" class="options" v-for="(card,index) in cardnumbers" :key="index">
                                    <span>{{card}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                        <a @click.prevent="$router.push({name:'Account'})" href="">{{state.lang.walletChargeModal.newCard}}</a>
              </div>
              <button class="submit">{{state.lang.walletChargeModal.btn}}</button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Chargemodel',
  data() {
      return {
          close : false,
          blanckspace:false,
          unfocus : true,
          selected:false,
          topcardnumber:'شماره کارت',
          cardnumbers:[6037343455688999,6037343455688999,6037343455688999,6037343455688999],
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeChargeModaleEmit' , this.close)
      },
      toggleFocus () {
                this.blanckspace=true ;
                this.unfocus=true;
            },
      cardToggle(){
        this.selected=!this.selected
      },
      choseCard(value){
        this.topcardnumber=value;
        this.selected=false;
      }
  },
  mounted () {
      
      // setTimeout(this.closeModale,3000);
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.options,.modal,.submit, .email , .order-btn')) {
              this.$emit('closeChargeModaleEmit' , this.close)
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     row-gap: 10px;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     box-sizing: border-box;
     row-gap: 16px;
     h3{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>