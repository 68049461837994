import Vue from 'vue';
import VueRouter from 'vue-router';
import Homepage from '../components/homepage/Homepage.vue';
import Companyinfo from '../components/companyinfo/Companyinfo.vue';
import Forms from '../components/forms/Forms.vue';
import Dashboard from '../components/Profile/Dashboard.vue';
import Mainpage from '../components/Profile/Dashboard/Mainpage.vue'
import Mainwallet from '../components/Profile/Wallet/Mainwallet.vue';
import MainExchangewallet from '../components/Profile/ExchangeWallet/MainExchangewallet.vue';
import Activeorders from '../components/Profile/Activeorders/Activeorders.vue';
import Account from '../components/Profile/Account/Account.vue';
import History from '../components/Profile/History/History.vue';
import Tickets from '../components/Profile/Tickets/Tickets.vue';
import Notifications from '../components/Profile/Notifications/Notifications.vue';
import Setting from '../components/Profile/Setting/Setting.vue';
import Invitefrinds from '../components/Profile/Invitefriends/Invitefriends.vue';
import Advancetrade from '../components/Advancetrade/Advancetrade.vue';
import Exchange from '../components/Exchange/Exchange.vue';
import FinoTech from '../components/Profile/Account/FinoTech.vue';
Vue.use(VueRouter);
const that = Vue.prototype


const routes =[
    {
        path :'/',
        name :'Homepage',
        component: Homepage
    },
    {
        path :'/aboutus',
        name :'aboutus',
        component: Companyinfo
    },
    {
        path :'/faq',
        name :'Faq',
        component: Companyinfo
    },
    {
        path :'/Privacypolicy',
        name :'Privacypolicy',
        component: Companyinfo
    },
    {
        path :'/Contactus',
        name :'Contactus',
        component: Companyinfo
    },
    {
        path :'/Fees',
        name :'Fees',
        component: Companyinfo
    },
    {
        path :'/Market',
        name :'Market',
        component: Companyinfo
    },
    {
        path :'/Signup',
        name :'Signup',
        component: Forms
    },
    {
        path :'/Otp-email',
        name :'Otpemail',
        component: Forms
    },
    {
        path :'/Login',
        name :'Login',
        component: Forms
    },
    {
        path :'/Login-otp-email',
        name :'Loginotpemail',
        component: Forms
    },
    {
        path :'/Login-otp-mobile',
        name :'Loginotpmobile',
        component: Forms
    },
    {
        path :'/Login-otp-google',
        name :'Loginotpgoogle',
        component: Forms
    },
    {
        path :'/Forgot-pass',
        name :'Forgotpass',
        component: Forms
    },
    {
        path :'/Forgot-pass-otp-email',
        name :'Forgotpassotpemail',
        component: Forms
    },
    {
        path :'/Forgot-pass-otp-sms',
        name :'Forgotpassotpsms',
        component: Forms
    },
    {
        path :'/Forgot-pass-otp-googleathenticator',
        name :'Forgotpassotpgoogelathenticator',
        component: Forms
    },
    {
        path :'/Forgot-pass-new',
        name :'Forgotpassnew',
        component: Forms
    },
    {
        path :'/Advancetrade/:tradeTo/:tradeFrom',
        name :'Advancetrade',
        component: Advancetrade,
        props:true
    },
    {
        path: '/Advancetrade',
        name: 'Advancetrade',
        redirect: '/Advancetrade/TOMAN/BITCOIN',
    },
    {
        path :'/Exchange',
        name :'Exchange',
        component: Exchange
    },
    {
        path :'/Dashboard',
        name :'Parent',
        component: Dashboard,
        children : [
            {
                path :'',
                name :'Dashboard',
                component: Mainpage,
            },
            {
                path : 'finotech',
                name:'FinoTech',
                component:FinoTech,
            },
            {
                path :'Wallet',
                name :'Wallet',
                component: Mainwallet,
            },
            {
                path :'ExchangeWallet',
                name :'ExchangeWallet',
                component: MainExchangewallet,
            },
            {
                path :'Activeorders',
                name :'Activeorders',
                component: Activeorders,
            },
            {
                path :'Account',
                name :'Account',
                component: Account,
            },
            {
                path :'validation',
                name :'Validation',
                component: Account,
            },
            {
                path :'History',
                name :'History',
                component: History,
            },
            {
                path :'Tickets',
                name :'Tickets',
                component: Tickets,
            },
            {
                path :'Notifications',
                name :'Notifications',
                component: Notifications,
            },
            {
                path :'Setting',
                name :'Setting',
                component: Setting,
            },
            {
                path :'Invitefriends',
                name :'Invitefriends',
                component: Invitefrinds,
            },

            {
                path: '*',
                redirect: '/'
            }
        ]
    },
];
const router = new VueRouter({
    mode : 'history',
    base : process.env.BASE-URL,
    routes
})

// scrollBehavior(to, from, savedPosition)
//     {
//     return new Promise(() => {
//         setTimeout(() => {
//             window.scrollTo({
//                 top: 0,
//                 left: 0,
//                 behavior: 'smooth'
//             })
//         }, 500)
//     })
//     }


const forLogin = [
'Dashboard',
'Activeorders',
'Account',
'Wallet',
'History',
'Setting',
'Notifications',
'Invitefriends',
'Tickets',
'Exchange',
'Advancetrade',
]
const unAuthorUser = [
'History',
'Dashboard',
'Notifications',
'Tickets',
'Setting',
'Account',
'Activeorders',
'Wallet',
// 'Exchange',
]
const otp = [
'Otpemail',
'Forgotpassnew',
]
const auth = [
'Login',
'Signup',
'Forgotpassnew',
];

router.beforeEach(async (to, from, next) => {

    document.title = to.name

    that.state.loading = true
    if(!that.state.coins){
        await that.$getCoins()
      }
    if (that.state.token) {

        if (!that.state.gotten) {
            await that.$getuserInfo()
            that.state.loading = true
            await that.$getFees()
        }

        that.state.loading = true

        // if (from.name === 'Blog') {
        //     await window.open(to.fullPath, '_self')
        // } 
        // else 
        if (otp.includes(to.name) || auth.includes(to.name)) {
            next({name: 'Dashboard'})
        } 
        else 
        if(to.name === 'Advancetrade') {
            try {
                that.state.loading = true
                await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
                next()
            } catch (e) {
                if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                    if (otp.includes(from.name)) {
                        next({name: 'Homepage'})
                    } else {
                        await that.$error('بازار غیرفعال است')
                        next(from)
                    }
                }
            }
        } 
        else {
            if (that.state.userInfo.authenticationType =="AUTHORIZED") {
                next()
            } else {
                if (otp.includes(from.name) && to.name !== 'Validation') {
                    next({name: 'Validation'})
                } else if (unAuthorUser.includes(to.name)) {
                    that.state.loading = false
                    const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                    if (res.isConfirmed) {
                        next({name: 'Validation'})
                    } else {
                        next(from)
                    }
                } else next()
            }
        }
    } 
    else {
        if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
            next({name: 'Login'})
        else {
            next()
        }
    }
    that.state.loading = false
})



export default router