<template>
    <transition name="fade">
        <div class="modal-backdrop" @click.self="closeModale">
          <form @submit.prevent="sendMessage" class="modal">
            
                <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
                <div class="modal-content">
                    <h3>{{state.lang.ticketModal.answerTicket}}</h3>
                    <label @click="textAreaBlanckSpace=true" for="">
                        <textarea @focus="textAreaBlanckSpace=true" v-model="data.text"  class="email" name="email" type="text" ></textarea>
                        <span class="textarea-span" :class="[{'focus':(textAreaBlanckSpace)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.ticketModal.enterAnswer}} </span>
                    </label>
                    <button :disabled="errorHandler" class="submit">{{errorHandler? errorHandler : state.lang.ticketModal.answerBtn}}</button>
                </div>
            
          </form>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Sellmodal',
  props:['id'],
  data() {
      return {
          data: {
                type: '',
                subject: '',
                text: '',
            },
          close : false,
          textAreaBlanckSpace:false,
      }
  },
  computed:{
    errorHandler(){
      let problem
      if(this.data.text==''){
        problem = this.state.lang.ticketModal.emptyText
      }else{
        problem=false ;
        }
        return problem ;
    }
  },
  methods : {
      closeModale() {
        this.$emit('close' , this.close)
      },
      async sendMessage() {
                this.state.loading=true
                let formData = new FormData
                formData.append('text', this.data.text)
                const res = await this.$axios.post('/tickets/message/' + this.id, formData)
                if(res){
                  this.state.lan=='Fa' ? this.$error('درخواست شما با موفقیت ثبت شد', '', 'success') : this.$error('Your Request Accept Successfuly', '', 'success')
                  console.log(res);
                  this.data.text = '';
                }
                // this.details.push(res.baseDTO)
                this.state.loading=false
                this.closeModale();
            },
  },
  mounted () {
      
      // setTimeout(this.closeModale,3000);
  },
  // created: function () {
  //   window.addEventListener("click",(event)=>{
  //           if(!event.target.closest('.options,.modal,.submit, .email , .order-btn ,.submit')) {
  //             this.$emit('closeChargeModaleEmit' , this.close)
  //           }
  //           else  {
  //             return true
  //           }
            
  //         } )
  // },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
   .note{
       display: flex;
       flex-direction: row;
       column-gap: 5px;
    //    align-items: center;
       color: var(--red-color);
       img{
           align-self: flex-start;
       }
   }
   .money{
       color: rgba(4, 24, 54, 0.342);
       font-weight: 300;
       font-size: clamp(10px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h3{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input,textarea{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
textarea{
    resize: none;
    height: 138px;
}
.textarea-span{
    top: 10px;
    // bottom: 0;
    max-height: 20px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>