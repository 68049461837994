<template>
    <div class="navbar container">
        <div class="navber-container">
            <div class="sidebar inwidth">
                <img v-if="!navbartoggle" @click="handleToggle" class="icon"  src="../../assets/icons/icons8-menu-48.png" alt="">
                <transition :name="state.lan=='Fa'? 'fade':'fadeEn'" >
                <div v-if="navbartoggle" :class=" state.lan=='Fa' ? 'sidebar-items' :'sidebar-items-en'" >
                    <img @click="handleToggle" class="icon" :class="{'outopacity':!navbartoggle}" src="../../assets/icons/icons8-close-40.png" alt="">
                    <div class="item-container">
                    <a  @click.prevent="reDirect(item.addres)" v-for="(item,index) in navbaritems" :key="index" :href="item.addres">{{item.title}}</a>
                    <span @click="state.changelanmodal=true" class="change-lan">{{this.state.lang.navbar.changeLang}}</span>
                    </div>
                </div>
                </transition>
            </div>
            <div class="navbar-logo">
                <img @click="$router.push({name : 'Homepage'})" src="../../assets/Logo/Zeen-Currency.svg" alt="">
            </div>
            <div class="navbar-items outwidth">
                <a @click.prevent="reDirect(item.addres)" v-for="(item) in navbaritems" :key="item.title" :href="item.addres">{{item.title}}</a>
                <span @click="state.changelanmodal=true" class="change-lan">{{this.state.lang.navbar.changeLang}}</span>
            </div>
            <div class="navbar-signup" v-if="$route.name !=='FinoTech'">
                <a @click.prevent="reDirect('Signup')" :class="{'inpage':$route.name=='Signup'}" class="login" href="/signup">{{this.state.lang.navbar.signup}}</a>
                <a @click.prevent="reDirect('Login')" :class="{'inpage':$route.name=='Login'}" class="login" href="/login">{{this.state.lang.navbar.login}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Navbar',
        data () {
            return {
                 navbaritems : [
                {
                    title : this.state.lang.navbar.homeBtn,
                    addres : 'Homepage'
                },
                {
                    title : this.state.lang.navbar.market,
                    addres : 'Market'
                },
                {
                    title : this.state.lang.navbar.fee,
                    addres : 'Fees'
                },
                {
                    title : this.state.lang.navbar.support,
                    addres : 'Contactus'
                },
                {
                    title : this.state.lang.navbar.blog,
                    addres : ''
                },
            ],
            navbartoggle : false ,
        
            }
        },
        methods : {
            handleToggle (){
                this.navbartoggle=!this.navbartoggle
            },
            reDirect(addres){
                this.$router.push({ name : addres});
            }
        }
    }
</script>

<style scoped lang="scss">
.change-lan{
    cursor: pointer;
    font-size: clamp(10px,1vw,14px);
      font-weight: 700;
      color: rgba(0, 119, 204, 1);
      text-align: center;
      transition: 500ms !important;
  &:hover{
    transform: scale(1.1,1.1);
  }
}
.login:hover{
    background: var(--secondary-color);
    color: var(--primary-color);

}
.fade-enter-active, .fade-leave-active {
  transition: margin-right 0.5s ease;
}

.fade-enter, .fade-leave-to {
  margin-right: -200px;
}
.fadeEn-enter-active, .fadeEn-leave-active {
  transition: margin-left 0.5s ease;
}

.fadeEn-enter, .fadeEn-leave-to {
  margin-left: -200px;
}
    .sidebar{
            width: 10%;
    }
    .sidebar-items{
        right: 0;
        width: 150px;
        overflow: scroll;
        position: fixed;
        bottom: 0;
        height: 100vh;
        padding-bottom: 20px ;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background:var(--primary-color) ;
            box-shadow: -6px 0 15px 10px rgba(202, 202, 202, 0.685);
            z-index: 10000000;
        height: 100%;
        img{
            padding:15px ;
        }
    }
    .sidebar-items-en{
        left: 0;
        width: 150px;
        overflow: scroll;
        position: fixed;
        bottom: 0;
        height: 100vh;
        padding-bottom: 20px ;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background:var(--primary-color) ;
            box-shadow: -6px 0 15px 10px rgba(202, 202, 202, 0.685);
            z-index: 10000000;
        height: 100%;
        img{
            padding:15px ;
        }
    }
    .navber-container{
        max-width: 1600px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        // padding: 20px;
        box-sizing: border-box;
        align-items: center;
    }
    .navbar {
        box-sizing: border-box;
        // padding: 2px 10px;
        max-height: 81px;
        display: flex;
        justify-content: space-between;
        flex-direction: row ; 
        width: 100%;
        align-items: center;
        background:var(--primary-color) ;
        min-height: 75px;
    }
    .navbar-logo{
        width: 15%;
        cursor: pointer;
        img{
            width: 100%;
            max-height: 67px;
        }
    }
      .item-container{
        display: flex;
        flex-direction: column;
        row-gap: 45px;
        height: 100%;
        padding-top: 32px;

        }
        .navbar-items{

        width: 40%;
        justify-content: space-between;
        display: flex;
        // text-align: center;
    }
    .navbar-signup{
        width: 20%;
        display: flex;
        column-gap: 24px;
        justify-content: flex-end;
        a{
            border: 1px solid rgba(0, 119, 204, 1);
            border-radius: 3px;
            padding: 6px 16px;
            color:  rgba(0, 119, 204, 1) ;
            font-size: clamp(11px,1vw,14px);
        }
    }   
    @media only screen and (max-width:850px){
        .navbar-logo{
            img{
                width: 100%;
            }
        }
        .navbar-signup{
            width: 50%;
        }

    }
        @media only screen and (max-width:460px){

          .navbar-signup{
      a{

          padding: 3px 8px;
      }
        }
    }
            @media only screen and (max-width:340px){

          .navbar-signup{
      a{

          padding: 6px 6px;
      }
        }
        .navbar-logo{
            width: 50%;
        }
    }
              @media only screen and (max-width:300px){

          .navbar-signup{
              column-gap: 15px;
        }
    }
    
    

</style>       