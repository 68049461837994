<template>
    <transition name="fade">
      <ConfirmModal @close="closeModale" :data="data" v-if="confirmModal" :type="state.userInfo.towStepAuthenticationType" :twoStep="state.userInfo.towStepAuthenticationType" />
        <div @click.self="closeModale" class="modal-backdrop" >
          <div class="modal">
            <img @click="closeModale" class="close" src="../../../assets/icons/icons8-close.svg" alt="">
            <div class="modal-content">
                <h3>{{state.lang.walletCoinSellModal.title}} {{coin}}</h3>
                <label for="">
                    <input @input="data.amount = $toLocal(data.amount,$decimal[coinType.coin])" v-model="data.amount" @focus="toggleFocus" class="email" name="email" type="text" >
                    <span :class="[{'focus':(blanckspace)},state.lan=='En'?'span-en':'span-fa']"> {{state.lang.walletCoinSellModal.priceInput}}  {{coin}} </span>
                </label>
                <div class="money">
                        <p>{{state.lang.walletCoinSellModal.remain}} </p>
                        <p style="font-family:'Yekan-en'">{{$toLocal(balance,$decimal[coinType.coin])}} {{$coinUnit[coinType.coin]}}</p>
                </div>
                <!-- wallet address  -->
                <label for="">
                    <input v-model="data.destinationWalletAddress" @focus="Walletblanckspace=true" class="email" name="email" type="text" >
                    <span :class="[{'focus':(Walletblanckspace)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.walletCoinSellModal.walletAdress}} </span>
                </label>
                <div class="channel">
                                    <div @click="netDrop = !netDrop" class="selected-channel">
                                        <span v-if="selected_net">{{selected_net}}</span>
                                        <span v-else> {{state.lang.channel.choose}} </span>
                                    </div>
                                    <div class="select-channel" v-if="netDrop">
                                        <div class="channel-item" v-for="(net,index) in nets" :key="index" @click="selectFun(index)">
                                            <span>{{net.tokenType}}</span>
                                        </div>
                                    </div>
                </div>
                <div class="notes">
                    <div class="note">
                        <img src="../../../assets/icons/error.svg" alt="">
                        <p>{{state.lang.walletCoinSellModal.body}} </p>
                    </div>
                </div>
                <div class="nets-details">
                    <div v-if="selected_net" class="coinremain money">
                            <p>{{state.lang.walletCoinSellModal.feeTitle}}  {{coin}}:</p>
                            <p style="font-family:'Yekan-en'">{{netFee}} {{$coinUnit[coinType.coin]}}</p>
                    </div>
                    <div v-if="selected_net" class="coinremain money">
                            <p>{{state.lang.walletCoinSellModal.minWithDrawal}}  {{coin}}:</p>
                            <p style="font-family:'Yekan-en'">{{min}} {{$coinUnit[coinType.coin]}}</p>
                    </div>
                    <div v-if="selected_net" class="coinremain money">
                            <p>{{state.lang.walletCoinSellModal.maxWithDrawal}}  {{coin}}:</p>
                            <p style="font-family:'Yekan-en'">{{max}} {{$coinUnit[coinType.coin]}}</p>
                    </div>
                </div>
                <button :disabled="disable" @click="confirmModal=true" class="submit"> {{ disable ? disable : state.lang.walletCoinSellModal.btn}}   </button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
import ConfirmModal from './ConfirmModal.vue';
export default {
  name: 'Coinsellmodal',
  components:{
    ConfirmModal
  },
  props:['coinType','balance'],
  data() {
      return {
          data:{
            relatedCoin:this.coinType.coin,
            amount:null,
            destinationWalletAddress:null,
            tokenType:null,
            // otp:'',
            walletType:'P2P'
          },
          min:'',
          max:'',
          netFee:'',
          confirmModal:false,
          tokenType:'',
          netDrop:false,
          selected_net:'',
          nets:[],
          coin:this.state.lan=='Fa' ? this.coinType.persianName:this.coinType.coin,
          fee:0.0002,
          close : false,
          blanckspace:false,
          Walletblanckspace:false,
          unfocus : true,
          selected:false,
      }
  },
  computed:{
    disable(){
      let errors =''
      if(!this.$S2N(this.data.amount)){
        errors=this.state.lang.walletCoinSellModal.errors.emptyAmount
      } else if (this.balance < this.$S2N(this.data.amount) ){
        errors=this.state.lang.walletCoinSellModal.errors.lowBalance
      } else if(!this.data.destinationWalletAddress){
        errors=this.state.lang.walletCoinSellModal.errors.emptyWallet
      } else if(!this.data.tokenType){
        errors=this.state.lang.walletCoinSellModal.errors.emptyNet
      }  else {
        errors=false;
      }
      return errors
    }
  },
  methods : {
      closeModale() {
        this.confirmModal=false
        this.$emit('closeChargeModaleEmit' , this.close)
      },
      toggleFocus () {
                this.blanckspace=true ;
                this.unfocus=true;
            },
      cardToggle(){
        this.selected=!this.selected
      },
      selectFun(index) {
                this.selected_net = this.nets[index].tokenType
                this.data.tokenType = this.nets[index].tokenType
                this.txIdIsNeeded = this.nets[index].txIdIsNeeded
                this.tokenType = this.nets[index].tokenType
                this.netFee=this.nets[index].withdrawalFee
                this.min=this.nets[index].minWithdraw;
                this.max=this.nets[index].maxWithdraw;
                this.netDrop = false

      },
      choseCard(value){
        this.topcardnumber=value;
        this.selected=false;
      },
      test() {
          // this.$emit('openotp',true);
        //   console.log('what');
      }
  },
  mounted () {
    this.nets=this.coinType.tokenTypeDetails
    console.log(this.coinType);
      // if(this.coin==''){
      //   if(this.state.lan=='Fa'){
      //     this.coin='تومان';
      //   } else {
      //     this.coin='TETHER';
      //   }
      // }
      // if(this.state.lan=='Fa'){
      //    this.coin=this.$coinLabel[this.coin];
      // }
      // setTimeout(this.closeModale,3000);
  },
    created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.channel')) {
              this.netDrop=false
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
   .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
   .close{
     cursor: pointer !important;
   }
   .nets-details{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
       .channel{
        z-index: 20;
        cursor: pointer;
        position: relative;
        width: 100%;
        background: white;
        padding: 8px 16px;
        border: 0.4px solid #A4C6DE;
        border-radius: 4px;
        max-height: 32px !important;
        .selected-channel{
            font-size: clamp(10px,1vw,12px);
            color: var(--secondary-color);
        }
        .select-channel{
            position: absolute;
            right: 0;
            top: 30px;
            border-radius: 4px;
            font-size: clamp(14px,1vw,16px);
            color: var(--secondary-color);
            background: var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            width: 100%;
            .channel-item{
                padding: 5px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            span{
                background: white;
                box-sizing: border-box;
                padding: 5px;
                border-radius: 4px;
                width: 90%;
                text-align: center;
            }
        }
    }
   .notes{
       display: flex;
       flex-direction: column;
       row-gap: 4px;
   }
   .coinremain{
       opacity: 1 !important;
   }
   .note{
       display: flex;
       flex-direction: row;
           column-gap: 5px;
       p{
           font-weight: 100 !important;
           text-align: start;

       }
    //    align-items: center;
       color: var(--red-color);
       img{
           align-self: flex-start;
       }
   }
   .money{
       opacity: .5;
       color: rgba(4, 24, 54, 1);
       font-weight: 100;
       font-size: clamp(8px,1vw,12px);
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }
   .submit{
     width: 100%;
   }
       .selected{
        transform: rotate(180deg);
    }
    .option-container{
    top: 30px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
    }
}
   .coin-type{
     width: 100%;
     display: flex;
     flex-direction: column;
     span{
      color: var(--secondary-color);
      font-weight: 400;
      font-size: clamp(10px,1vw,14px);
     }
     a{
       width: 100%;
       text-align: end;
       font-weight: 400;
       font-size: clamp(10px,1vw,14px);
       &:hover{
         transform: none;
       }
     }
     .chose-coin{
       border: 0.4px solid #A4C6DE;
       position: relative;
       border-radius: 4px;
       box-sizing: border-box;
       padding:8px 16px;
       width: 100%;
            display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     span:hover,img:hover{
         cursor: pointer;
     }
     }
   }
   .modal-content{
     display: flex;
     flex-direction: column;
    //  row-gap: 16px;
    justify-content: space-between;
    align-items: center;
     max-width: 336px;
     width: 100%;
     box-sizing: border-box;
     row-gap: 16px;
     h3{
       color: var(--secondary-color);
     }
   }
   label{
    position: relative;
    width: 100%;
    span {
    transition: 1s;
        background: white;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
   input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
   .focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    .close{
        align-self: baseline;
    }
    p{
        text-align: center;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 204px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>