<template>
    <div class="notifications">
            <h1>{{state.lang.notifications.title}}</h1>
            <div class="notification-container">
                <perfect-scrollbar style="direction: ltr;padding-right:10px" :options="setting"
                @ps-y-reach-end="allmessages.length < unreadTotalElements ? load() : ''">
                    <div class="readmessage">
                        <div v-for="(allmessage,index) in allmessages" :key="index" class="notification">
                            <div @click.prevent="toggle0(allmessage.id,index)" class="header">
                                <div class="title-container">
                                <span :class="{'checked': allmessage.read}" class="checkbox" name="terms"></span>
                                <h4>{{allmessage.title}}</h4>
                                </div>
                                <div class="time-container">
                                <div class="time">
                                     {{$G2J(allmessage.createdAtDateTime)}}
                                </div>
                                <img :style="{transform: [showmessage0[index] ?  'rotate(-180deg)':'' ]}" src="../../../assets/icons/listicon.svg" alt="">
                                </div>
                            </div>
                            <div class="message-description"
                                    :style="{maxHeight: [showmessage0[index]?  allmessageheight[index] + 'px' : '']}">
                                    <p>{{allmessage.content}}</p>
                                </div>
                        </div>
                    </div>
                </perfect-scrollbar>
                <!-- <perfect-scrollbar style="direction: ltr;padding-right:10px" :options="setting"
                @ps-y-reach-end="readmessages.length < readTotalElements ? load('read') : ''">
                    <div v-for="(item,index) in notifications" :key="index" class="notification">
                        <div class="header">
                            <div class="title-container">
                            <span :class="{'checked': read}" class="checkbox" name="terms"></span>
                            <h4>{{item.title}}</h4>
                            </div>
                            <div class="time-container">
                            <div class="time">
                               {{$G2J(readmessage.createdAtDateTime)}}
                            </div>
                            <img :class="{'complete': item.complete}" src="../../../assets/icons/listicon.svg" alt="">
                            </div>
                        </div>
                        <div class="message-description"
                                :style="{maxHeight: [showmessage[index]?  readmessageheight[index] + 'px' : '']}">
                                <p>{{readmessage.content}}</p>
                            </div>
                    </div>
                </perfect-scrollbar> -->
            </div>
    </div>
</template>

<script>
    export default {
        name:'Notifications',
        data () {
            return{
                termscheck:false,
                notifications:[
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:null,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:null,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:null,
                    },
                    {
                        title:'لورم ایپسوم',
                        text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد',
                        year:1400,
                        mounth:11,
                        day:12,
                        hours:13,
                        minute:13,
                        checked:false,
                        complete:null,
                    },
                ],
                read: false,
                showmessage: [],
                showmessage0: [],
                unreadmessageheight: [],
                allmessageheight: [],
                readmessageheight: [],
                messages: [],
                readmessages: [],
                unreadmessages: [],
                allmessages: [],
                allTotalElements: 0,
                readTotalElements: 0,
                unreadTotalElements: 0,
                page: 1,
                perPage: 6,
                setting: {
                    suppressScrollX: true
                }
            }
        },
        methods:{
            // Toggle(index){
            //     if(this.notifications[index].complete!==1){
            //         for(let key in this.notifications){
            //             this.notifications[key].complete=0;
            //         }
            //         this.notifications[index].complete=1
            //     }else {
            //         this.notifications[index].complete=0
            //     }
            // },
            tabChange(item) {
                this.readmessages = []
                this.unreadmessages = []
                this.page = 1
                if (item === 'read') {
                    this.read = true
                    this.getReadMessages()
                } else {
                    this.read = false
                    this.getUnreadMessages()
                }
            },
            toggle(e) {
                this.readmessageheight[e] = document.getElementsByClassName('message-description')[e].firstChild.clientHeight + 20
                this.showmessage[e] = !this.showmessage[e];
                // console.log(this.showmessage[e]);
                this.$forceUpdate()
            },
            toggle0(e, index) {
                this.allmessageheight[index] = document.getElementsByClassName('message-description')[index].firstChild.clientHeight + 20
                this.showmessage0[index] = !this.showmessage0[index];
                this.$forceUpdate()
                this.readMessage(e, index)
            },
            async getUnreadMessages(page = this.page, perPage = this.perPage) {
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                            read: false, page: page, size: perPage,
                        }
                    })
                    console.log(res);
                if (res) {
                    this.unreadmessages.push(...res.content)
                    this.unreadTotalElements = res.totalElements
                    this.state.messages = this.unreadTotalElements
                }
            },
            async getReadMessages(page = this.page, perPage = this.perPage) {
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                            read: true, page: page, size: perPage,
                        }
                    })
                    console.log(res);
                if (res) {
                    this.readmessages.push(...res.content)
                    this.readTotalElements = res.totalElements
                }
            },
            async getMessages(page = this.page, perPage = this.perPage) {
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                             page: page, size: perPage,
                        }
                    })
                    console.log(res);
                if (res) {
                    this.allmessages.push(...res.content)
                    this.allTotalElements = res.totalElements
                    console.log(this.allmessages);
                }
            },
            async readMessage(e, index) {
                if (!this.allmessages[index].read) {
                    await this.$axios.put(`/users/notifications/read/${e}`)
                    this.unreadmessages[index].read = true
                    --this.readTotalElements
                    --this.state.messages
                }
                this.$forceUpdate()
            },
            async load() {
                if (!this.loading) {
                    this.state.loading = true;
                    this.page++
                    this.getMessages()
                    // if (e === 'read') {
                    //     await this.getReadMessages()
                    // } else {
                    //     await this.getUnreadMessages()
                    // }
                }
            }
        },
        mounted() {
            this.state.loading = true
            this.getMessages()
        },
        created: function () {
            window.addEventListener("click",(event)=>{
            if(!event.target.closest('.notification')) {
              for(let key in this.notifications){
                  this.notifications[key].complete=0;
              }
            }
            else  {
              return true
            }
            
          } )
        },
    }
</script>

<style lang="scss" scoped>
.readmessage{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
p{
    color: rgba(63, 63, 63, .6);
    font-weight: 400;
    font-size: clamp(10px,3vw,14px); 
}
h4,span{
    font-weight: 400;
    font-size: clamp(10px,3vw,14px);    
}
h1{
    align-self: flex-start;
    font-weight: 700;
    font-size: clamp(18px,3vw,24px);
}
    .message-description {
        // font-size: 18px;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        max-height: 0;
        // padding: 0 15px;
    }

    // .message-description > p {
    //     margin-bottom: 10px;
    //     font-weight: 300;
    // }

.notifications{
    height: 98%;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    box-sizing: border-box;
    padding: 16px;
    width: 100%
}
.notification-container{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.title-container,.time-container{
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: center;
    column-gap: 5px;

}
.time-container{
   justify-content: flex-end;
   img{
       cursor: pointer;
   }
}
.notification{
    cursor: pointer;
    background: var(--primary-color);
    width: 100%;
    border-radius:4px ;
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
}
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    color: rgba(63, 63, 63, .6);
}
.header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.checkbox{
    cursor: pointer;
    width: 16px;
        height: 16px;
        border-radius:4px ;
}
.checked{
        background: var(--banner-primary);
    border: none !important;
    width: 18px;
    height: 18px;

    }
.complete{
    transform: rotate(180deg);
}
@media only screen and(max-width:750px){
    .title-container,.time-container{
        width: 40%;
    }
}
@media only screen and(max-width:400px){
    .time-container{
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .title-container{
        width: 50%;
    }
}
</style>