<template>
    <div class="invite">
        <div class="invite-container">
            <div class="title">
                <h1>{{state.lang.inviteFriends.title}}</h1>
                <p>{{state.lang.inviteFriends.body}}</p>
            </div>
            <div class="content-container">
                    <div class="content">
                        <img style="height:245px" class="card" src="../../../assets/icons/Iconscardinvate.svg" alt="">
                        <h6>{{state.lang.inviteFriends.friendsNumber}}</h6>
                        <p class="numbers">{{$toLocal(data.countInvitees) || 0}} {{state.lang.inviteFriends.person}}</p>
                        <div @click="Copy(data.referralCode)" class="links">
                            <p>{{state.lang.inviteFriends.inviteCode}}</p>
                            <div class="link invitecode">
                                <p class="invitep">{{data.referralCode}}</p>
                                <img style="max-width:24px"  src="../../../assets/icons/copyicon.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <img class="card" src="../../../assets/icons/invitecardbenefit.svg" alt="">
                        <h6>{{state.lang.inviteFriends.totalBenefits}}</h6>
                        <p class="numbers">{{$toLocal(data.profit) || 0}} {{state.lang.inviteFriends.priceType}} </p>
                        <div @click="Copy('zeencurrency.com/'+data.referralCode)" class="links">
                            <p class="link-title">{{state.lang.inviteFriends.inviteLink}}</p>
                            <div class="link">
                                <p class="invitep">{{'zeencurrency.com/'+data.referralCode}}</p>
                                <img style="max-width:24px" src="../../../assets/icons/copyicon.svg" alt="">
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <transition name="fade">
            <p v-if="alertLink" class="alert">{{state.lang.inviteFriends.copy}}</p>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'Invitefriends',
        data () {
            return{
                alertLink:false,
                benefit:345290,
                friends:7,
                invitecode:'ESFAA6F6',
                invitelink:'Coinex.com/register?refer_code=fyhgm',
                data:{
                    countInvitees:0,
                    profit:'',
                    referralCode: '',
                }
            }
        },
        methods: {
            async Copy(e) {
                await navigator.clipboard.writeText(e)
                this.alertLink=true;
                setTimeout(() => {this.alertLink=false},1500)
            }
        },
        async mounted() {
            this.state.loading=true;
            const res = await this.$axios.get('/users/invites')
            this.data = res.baseDTO
            this.state.loading=false;
        }

    }
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
   }
   .fade-enter, .fade-leave-to {
       opacity: 0
   }
   .numbers{
        font-weight: 900;
        font-size: clamp(30px,3vw,36px) !important;
   }
       h6{
           font-weight: 700;
           font-size: clamp(14px,2vw,18px);
       }
.alert{
    position: absolute;
    right: 53%;
    bottom: 5%;
    // background: var(--primary-color);
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, .2);
    color: rgba(64, 64, 64, .7);
    border-radius: 4px;
}
.invitep{
    margin-top: 5px;
}
.invite{
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    .invite-container{
        .title{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            h1{
                text-align: start;
            }
            p{
                color: rgba(63, 63, 63, .8);
            }
        }
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px;
        row-gap: 60px;
        width: 100%;
        .content-container{
            display: flex;
            flex-direction: row;
            width: 100%;
            // column-gap: 16px;
            justify-content: space-between;
            .content{
                img{
                    max-height: 150px;
                    max-width: 245px;
                    width: 100%;
                }
                h1{
                    color: var(--positive-color);
                    
                }
                width: 49%;
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                justify-content: space-between;
                align-items: center;
                .links{
                    cursor: pointer;
                    color: rgba(64, 64, 64, .9);
                    border-radius: 4px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    background: var(--primary-color);
                    border: 0.4px solid #0077cc62;
                    box-sizing: border-box;
                    padding: 8px;
                    p{
                        font-size: clamp(8px,1.6vw,16px);
                        width: 20%;
                    }
                    .link{
                        p{
                            width: 100%;
                        }
                        column-gap: 8px;
                        justify-content: center;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }
        }
        p{
            font-size: clamp(10px,1vw,14px);
        }
    }
}
@media only screen and(max-width:1200px){
    .content-container{
        flex-direction: column !important;
        align-items: center !important;
        row-gap: 30px !important;
        .content{
            width: 70% !important;
        }
    }
    .links{
        flex-direction: column !important;
        align-items: flex-start !important;
        p{
            width: 100% !important;
        }
        .link{
            align-self: flex-end;
        }
    }
}
</style>