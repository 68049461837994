<template>
    <div class="personal-info" :class="state.lan=='Fa' ? 'span-fa' : 'span-en'" >
                <h1>{{state.lang.account.personalInfoTitle}}</h1>
                <div class="info-input">
                    <label @click="blanckspace.name=true"  for="">
                        <input 
                            @input="state.lan == 'Fa' ?data.firstName = $S2Fa(data.firstName):data.firstName"
                            @focus="blanckspace.name=true"
                            class="name" 
                            :disabled="$route.name =='Account'"
                            name="name" 
                            v-model="data.firstName"
                            type="text">
                        <span @click="blanckspace.name=true" :class="{'focus field-disable':(blanckspace.name) || data.firstName}">{{state.lang.account.name}}</span>
                    </label>
                        <date-picker 
                        :disabled="$route.name =='Account'"
                        :placeholder="state.lang.account.birthDay" 
                        v-model="data.birthDay" 
                        :format="$dateFormat['en']"
                        :display-format="state.lan=='Fa' ? $dateFormat['fa'] : $dateFormat['en']"
                        :max="$jmoment().format($dateFormat['en'])"
                        :clearable="!state.userInfo.authorized"
                        :class="state.lan=='Fa' ? 'date-fa':'date-en'"
                        class="date"/>
                    <!-- <label for="">
                        <input name="name" type="date">
                        <span :class="{'focus':(blanckspace.name)}">تاریخ تولد</span>
                    </label> -->
                    <label @click="blanckspace.phonenum=true" for="">
                        <input 
                            class="name"
                            name="phonenum"
                            @focus="blanckspace.phonenum=true"
                            @input="data.mobileNumber = $p2e(data.mobileNumber)"
                            v-model="data.mobileNumber"
                            minlength="11"
                            maxlength="11"
                            type="text">
                        <span @click="blanckspace.phonenum=true" :class="{'focus':(blanckspace.phonenum) || data.mobileNumber}">{{state.lang.account.phonenumber}}</span>
                    </label>
                </div>
                <div class="info-input">
                    <label @click="blanckspace.family=true" for="">
                        <input
                            @input="data.lastName = $S2Fa(data.lastName)"
                            v-model="data.lastName"
                            class="name" 
                            name="family" 
                            :disabled="$route.name =='Account'"
                            type="text">
                        <span :class="{'focus field-disable':(blanckspace.family) || data.lastName}">{{state.lang.account.family}}</span>
                    </label>
                    <label @click="blanckspace.idcode=true" for="">
                        <input                          
                            @input="data.nationalCode = $p2e(data.nationalCode)"
                            v-model="data.nationalCode"
                            maxlength="10"
                            class="name"
                            name="idcode" 
                            :disabled="$route.name =='Account'"
                            type="text">
                        <span :class="{'focus field-disable':(blanckspace.idcode) || data.nationalCode}">{{state.lang.account.nationalCode}}</span>
                    </label>
                    <label @click="blanckspace.cellnum=true"  for="">
                        <input 
                            @input="data.telephone = $p2e(data.telephone)"
                            v-model="data.telephone"
                            minlength="11"
                            maxlength="11"
                            class="name" 
                            name="cellnum" 
                            type="text">
                        <span :class="{'focus':(blanckspace.cellnum) || data.telephone}">{{state.lang.account.telephone}}</span>
                    </label>
                </div>
                <div class="info-input">
                    <label @click="blanckspace.addres=true" class="textarea" for="">
                        <textarea v-model="data.address" class="addres" name="cellphonenum" type="text"></textarea>
                        <span :class="{'focus':(blanckspace.addres) || data.address}">{{state.lang.account.addres}}</span>
                    </label>
                </div>
            </div>
</template>

<script>
    export default {
        name:'Personalinfo',
        props:['blanckspace','data'],
          watch: {
                data : function () {

                    if(this.$route.name !=='Validation'){
                        if(this.data.firstName !== ''){
                        this.blanckspace.name=true;
                        }
                        if(this.data.lastName !== ''){
                        this.blanckspace.family=true;
                        }
                        if(this.data.mobileNumber !== ''){
                        this.blanckspace.phonenum=true;
                        }
                        if(this.data.nationalCode !== ''){
                        this.blanckspace.idcode=true;
                        }
                        if(this.data.addres !== ''){
                        this.blanckspace.addres=true;
                        }
                        if(this.data.telephone !== ''){
                        this.blanckspace.cellnum=true;
                        }
                        if(this.data.shebaCode !== ''){
                        this.blanckspace.shabanumber=true;
                        }
                        if(this.data.cardNumber !== ''){
                        this.blanckspace.cartnumber=true;
                        }
                    }
                },
            },
        mounted(){
            // if(state.loading==true &&this.data.firstName !== ''){
            //     this.blanckspace.name=true;
            // }
            // console.log(this.data);
        }

    }
</script>

<style lang="scss" scoped>
.span-en{
    span{
        right: unset !important;
        left: 5px !important;
        bottom: 3px !important;
    }
}
.field-disable{
    background: linear-gradient(180deg,white 50%,rgba(242, 246, 255, 1) 60%) !important;
}
    label{
        width: 30%;
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 7px;
        right: 5px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
    input,textarea{
    font-family: 'Yekan-en' ;
    color: var(--secondary-color);
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #a4c6de9a;
    width: 100%;
    border-radius: 4px;
    resize: none;
    height: 100%;
}
}
.textarea{
    width: 100%;
    color:var(--primary-color);
    max-height: 108px;
    span{
                top: 10px;
                max-height: 22px;
    }
    textarea{
        height: 108px;
    }
    height: 100%;
}
.info-input{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a{
        align-self: center;
        font-weight: 400;
        font-size: clamp(9px,1vw,13px);
        &:hover{
            transform: none;
        }
    }
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border-bottom: 0.5px solid #0077cc88;
    padding-bottom: 16px;
    h1{
        align-self: flex-start;
    }
}
@media only screen and(max-width:550px){
    .info-input{
        flex-direction: column;
        row-gap: 5px;
        label{

            width:100%;
        }
    }
}
</style>