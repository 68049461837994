<template>
    <div class="cards">
      <div class="container cards-container">
        <div class="cards-title"> 
        <h1> {{state.lang.cards.title}} </h1>
        <h6>{{state.lang.cards.explanation}}</h6>
        </div>
        <div class="cards-image">
            <div class="card-container">
                <img src="../../assets/cards/fastdealing.svg" alt="">
                <h4>{{state.lang.cards.fastDeal}}</h4>
            </div>
            <div class="card-container">
                <img src="../../assets/cards/secure.svg" alt="">
                <h4>{{state.lang.cards.secure}}</h4>
            </div>
            <div class="card-container">
                 <img src="../../assets/cards/support.svg" alt="">
                <h4>{{state.lang.cards.support}}</h4>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name : 'Cards' ,
        
    }
</script>

<style lang="scss" scoped>
.cards{
        background:var(--secondary-color) ;
        box-sizing: border-box;
        padding: 64px 0;
}
.cards-container {
    .cards-title{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        text-align: center;
        h1{
            font-size: clamp(36px,1vw,48px);
            color: var(--banner-primary);
        }
        h6{
            word-spacing: 4px;
            font-size: clamp(14px,1vw,18px);
            color: var(--primary-color);
        }
    }
    .cards-image{
        .card-container{
            max-height: 360px;
            width: 20%;
            border-radius: 4px;
            row-gap: 100px;
            padding: 16px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: white;
        }
        height: 100%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    width: 100%;
    img{
        max-width: 280px ;
        max-height: 200px ;
        width: 100%;
    }
        
    }
    box-sizing: border-box;
    // padding: 64px 0;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    padding-bottom: 10px;
}

@media only screen and(max-width:1100px) {
    .cards-image{
        column-gap: 20px; 
        justify-content: space-evenly !important;
        row-gap: 20px;
    }
}

@media only screen and(max-width:750px) {
    .cards-image{
        flex-direction: column !important;
        align-items: center !important;
        row-gap: 20px;
    }
    .card-container{
        width: 80% !important;
    }
}

</style>