<template>
    <transition name="fade">
        <div class="modal-backdrop" @click.self="closeModale">
          <div class="modal">
              <img @click="closeModale" class="close" src="../../assets/icons/icons8-close.svg" alt="">
              <div class="content">
                <h1>{{state.lang.changeLangModal.title}}</h1>
                <p>{{state.lang.changeLangModal.body}}</p>
                <div class="btns">
                    <button @click="changeLang('Fa')" :class="{'submit':state.lan == 'En'}">{{state.lang.changeLangModal.FA}}</button>
                    <button @click="changeLang('En')" :class="{'submit':state.lan == 'Fa'}">{{state.lang.changeLangModal.EN}}</button>
                </div>
              </div>
          </div>
        </div>
    </transition>
</template>

<script>
import Fa from '../../library/Langs/Fa';
import En from '../../library/Langs/En';
export default {
  name: 'ChangeLanModal',
  data() {
      return {
      }
  },
  props:{
      text:String,
      pic:String,
  },
  methods : {
      closeModale() {
        this.$emit('closeChangelan' , false)
      },
      changeLang(e) {
      localStorage.lang = e;
      this.state.lan = e;
      this.state.lang = (this.state.lan === 'En' ? En : Fa);
      // console.log(this.$root.$children[0].$el);
    //   this.state.local = e === 'fa' ? 'fa_IR' : 'en'
      this.$root.$children[0].$children.forEach(e => e.$forceUpdate())
      this.closeModale();
    },
  },
  }
</script>

<style lang="scss" scoped>
h1{
    color: var(--secondary-color);
    font-weight: 700;
    font-size: clamp(20px,1vw,24px);
}
.btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button{
        width: 45%;
        cursor: pointer;
    }
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: center;
    align-items: center;
    img{
        max-width: 59px;
        max-height: 43px;
    }
}
.close{
    cursor: pointer;
    align-self: flex-start;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 160px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>