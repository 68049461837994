<template>
    <div class="trades">
        <h1>{{state.lang.history.exchangesTitle}}</h1>
        <div class="filters">
            <div class="coin-type" style="zIndex:9">    
                        <div @click.self="headeritems.opration.selected=!headeritems.opration.selected" class="chose-coin">
                            <span>{{pages[1].name}}</span>
                            <img :class="{'selected':headeritems.opration.selected}" @click.self="headeritems.opration.selected=!headeritems.opration.selected" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="headeritems.opration.selected==true" class="option-container">    
                                <div @click="headeritems.opration.name=page.name,headeritems.opration.selected=false,chosePage(page.addres)" class="options" v-for="(page,index) in pages" :key="index">
                                    <span>{{page.name}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
            <div class="coin-type" style="zIndex:8">    
                        <div @click.self="oprationDrop=!oprationDrop" class="chose-coin">
                            <span>{{this.state.lan=='Fa' ? this.$oprationType[selected_opration] : selected_opration}}</span>
                            <img :class="{'selected':oprationDrop}" @click.self="oprationDrop=!oprationDrop" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="oprationDrop==true" class="option-container">    
                                <div @click="changeOpration(opration)" class="options" v-for="(opration,index) in oprations" :key="index">
                                    <span v-if="state.lan=='Fa'">{{$oprationType[opration]}}</span>
                                    <span v-else> {{opration}} </span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
            <div class="coin-type" style="zIndex:7">    
                        <div @click.self="marketDrop=!marketDrop" class="chose-coin">
                            <span v-if="selectedMarket=='All Markets'"> {{ state.lan=='Fa' ? 'تمام بازار' :'All Markets'}} </span>
                            <span v-else>{{state.lan=='En' ? selectedMarket : $coinLabel[selectedMarket]}}</span>
                            <img :class="{'selected':marketDrop}" @click.self="marketDrop=!marketDrop" src="../../../assets/icons/listicon.svg" alt="">
                            <transition name="fade">
                            <div v-if="marketDrop==true" class="option-container">    
                                <div @click="changeMarkets('All Markets',-1)" class="options">
                                    <span> {{ state.lan=='Fa' ? 'تمام بازار' :'All Markets'}} </span>
                                </div>
                                <div @click="changeMarkets(market.coin,index)" class="options" v-for="(market,index) in coins" :key="index">
                                    <span v-if="state.lan=='En'">{{market.coin}}</span>
                                    <span v-else>{{market.persianName}}</span>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr class="thead">
                        <td>{{state.lang.exchangeTable.coin}}</td>
                        <td>{{state.lang.tradesTable.opration}}</td>
                        <td class="remove-three">{{state.lang.tradesTable.price}}</td>
                        <td class="remove-three">{{state.lang.tradesTable.amount}}</td>
                        <td class="remove">{{state.lang.tradesTable.total}}</td>
                        <!-- <td class="remove">{{state.lang.exchangeTable.channel}}</td> -->
                        <!-- <td class="remove">{{state.lang.exchangeTable.situation}}</td> -->
                        <td>{{state.lang.exchangeTable.transactionId}}</td>
                        <td class="remove-two">{{state.lang.tradesTable.time}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order,index) in tableContents" :key="index">
                        <td> 
                            <div v-if="order.orderType=='EXCHANGE_BUY'" class="cointype">
                                    <span v-if="state.lan=='Fa'" class="coinname">{{$coinLabel[order.destinationCoin]}}</span>
                                    <span v-else class="coinname">{{order.destinationCoin}}</span>

                            </div>
                            <div v-else class="cointype">
                                    <span v-if="state.lan=='Fa'" class="coinname">{{$coinLabel[order.sourceCoin]}}</span>
                                    <span v-else class="coinname">{{order.sourceCoin}}</span>

                            </div>
                        </td>
                        <td v-if="state.lan=='Fa'" :class="order.orderType=='EXCHANGE_BUY' ?'positive':'negetive'">{{$orderType[order.orderType]}}</td>
                        <td v-if="state.lan=='En'" :class="order.orderType=='EXCHANGE_BUY' ?'positive':'negetive'">{{order.orderType}}</td>
                        <td class="remove-three">{{$toLocal(order.unitPrice,$decimal[order.destinationCoin])}}</td>
                        <td class="remove-three">{{$toLocal(order.amount,$decimal[order.destinationCoin])}}</td>
                        <td class="remove">{{$toLocal(order.wholePrice,$decimal[order.destinationCoin])}}</td>
                        <!-- <td class="remove"> {{}} </td> -->
                        <td>{{order.receivedAmount}}</td>
                        <td class="remove-two"> {{order.createdAtDateTime}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Trades',
        data () {
            return {
                params:{
                    // marketType:'',
                    // market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    sourceCoin:'',
                    destinationCoin:'',
                    orderType:'',
                    type : 'EXCHANGE',
                    page: 1,
                    size: 20
                },
                selected:false,
                oprations:['All Oprations','BUY','SELL'],
                selected_opration:'All Oprations',
                oprationDrop:false,
                markets:'All Markets',
                selectedMarket:'All Markets',
                marketDrop:false,
                marketType:this.state.lang.history.limit,
                selectedMarketType:'All Types',
                marketTypeDrop:false,
                marketTypes:['Limit','Market'],
                page:0,
                lastPage:1,
                coins:[],
                tableContents:[],
                headeritems:{
                    market:{
                        name:this.state.lang.history.tradesTitle,
                        selected:false,
                    },
                    opration:{
                        name:this.state.lang.history.exchangesTitle,
                        selected:false,
                        },
                    localmarket:{
                        name:this.state.lang.history.despintsTitle,
                        selected:false,
                    },
                    had:{
                        name:this.state.lang.history.loginsTitle,
                        selected:false,
                    },
                },
                pages:[
                    {
                        name:this.state.lang.history.tradesTitle,
                        addres:'Trades',
                    },
                    {
                        name:this.state.lang.history.exchangesTitle,
                        addres:'Exchange',
                    },
                    {
                        name:this.state.lang.history.coindespintsTitle,
                        addres:'Despint',
                    },
                    {
                        name:this.state.lang.history.tomandespintsTitle,
                        addres:'RialDespint',
                    },
                    {
                        name:this.state.lang.history.loginsTitle,
                        addres:'Login',
                    },
                ],

            }
        },
        methods : {
            changeMarketType(e){
                this.selectedMarketType=e
                this.marketTypeDrop=false
                console.log(e);
            },
            async changeMarkets(e){
                console.log(e);
                if(e!=='All Markets'){
                        this.selectedMarket=e;
                        this.params.destinationCoin=e
                        this.changeOpration(this.selected_opration);
                } else{
                    this.params.destinationCoin=''
                    this.changeOpration(this.selected_opration);
                    this.selectedMarket=e;
                }
                this.selectedMarket=e;
                this.marketDrop=false;
            },
            async changeOpration(e){
                this.state.lan=='Fa' ? this.selected_opration=this.$oprationType[e] : this.selected_opration=e;
                this.selected_opration=e
                this.state.loading=true;
                if(e=='All Oprations'){
                    this.params.orderType=''
                    let res = await this.$axios.get('/trades', {params: this.params})
                    if (res.content)
                    this.tableContents = res.content;
                } else if(e=='BUY'){
                    this.params.orderType='EXCHANGE_BUY'
                    let res = await this.$axios.get('/trades', {params: this.params})
                    if (res.content)
                    this.tableContents = res.content;
                    // this.tableContents=this.tableContents.filter(a => a.orderType=='LIMITED_BUY' || a.orderType=='MARKET_BUY');
                } else{
                    this.params.orderType='EXCHANGE_SELL'
                    let res = await this.$axios.get('/trades', {params: this.params})
                    if (res.content)
                    this.tableContents = res.content;
                    // this.tableContents=this.tableContents.filter(a => a.orderType=='LIMITED_SELL' || a.orderType=='MARKET_SELL');
                }
                this.state.loading=false;
                this.oprationDrop=false
            },
            chosePage(addres) {
                // this.headeritems.market=value;
                // this.$router.push({ name : addres })
                this.$emit('sendcomp', addres)
            },
            async getLastOrders() {
            this.state.loading = true
            if (this.page <= this.lastPage) {
                //for scroll loading
                this.page++ //for scroll loading
                // let res = await this.$axios.get('/trades', this.params)
                let res = await this.$axios.get('/trades', {params: this.params})
                if (res.content)
                this.tableContents = res.content;
                this.loading = false
            }
            this.state.loading = false
            console.log(this.tableContents);
        },
            async getCoins(){
                this.state.loading = true
                const res = await this.$axios.get('/coins/details?p2pSupported=true')
                this.coins = res.content
            },
        },
        mounted(){
            this.getCoins()
            this.getLastOrders();
        },
        created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.chose-coin')) {
             this.headeritems.market.selected=false;
             this.oprationDrop=false;
             this.marketDrop=false;
             this.headeritems.had.selected=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.thead{
    position: sticky;
    top: 0;
    background: white;
}
.table{
    overflow: scroll;
}
table{
    font-weight: 400;
    font-size: clamp(11px,1vw,14px);
    .cointype{
    display: flex;
    flex-direction: row;
    color: #3f3f3f7e;
    // justify-content: center;
    column-gap: 5px;
    .coinname{
        color:var(--secondary-color);
    }
}
    width: 100%;
    tr{
        height: 36px;
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.time{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // justify-content: center;
}
.coin-type{
        display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    width: 32%;
}
.option-container{
    max-height: 250px;
    overflow: scroll;
    top: 33px;
    right: 0px;
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction:column ;
    row-gap: 8px;
    .options{
        background: white;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
          span{
                font-weight: 300;
                font-size: clamp(9px,1vw,12px);
            }
    }
            img{
                position: absolute;
                right: 130px;
                top:-3px;
                width: 30px;
                height: 30px;

            }
}
.chose-coin{
    cursor: pointer;
    height: 30px;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    justify-content: space-between;
    width: 100%;
        position: relative;
    border: 0.2px solid #0077cc65;
    border-radius: 4px;
    span{
        font-weight: 300;
                font-size: clamp(9px,1vw,12px);
    }
}
    .selected{
        transform: rotate(180deg);
    }
.trades{
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    row-gap: 16px;
    h1{
        text-align: start !important;
    }
}
.filters{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}
@media only screen and(max-width:700px){
    .filters{
        flex-direction: column;
        row-gap: 16px;
        .coin-type{
            width: 100%;
        }
    }
        .remove{
            display: none;
        }
}
@media only screen and(max-width:600px){
    .remove-two{
        display: none;
    }
}
@media only screen and(max-width:500px){
    .remove-three{
        display: none;
    }
}
@media only screen and(max-height:400px){
    .table{
        overflow: unset;
    }
}
</style>