<template>
    <div :class="state.lan=='Fa' ? 'span-fa' : 'span-en' "  class="bank-account">
                <div class="title">
                    <h1>{{state.lang.account.bankInfoTitle}}</h1>
                    <div class="newac">
                        <button @click.prevent="addcard">{{state.lang.account.newCard}}</button>
                        <!-- <a v-if="newaccount && data.bankAccounts.length < 2" @click.prevent="addcard" href="">ثبت</a> -->
                    </div>
                </div>
                <div class="info-input">
                    <label @click="emptyblanckspace.cardNumber=true"  for="">
                        <input 
                        class="name" 
                        @input="dash0"
                        v-model="newcard.cardNumber"
                        :name="'newcard-cardnumber'"
                        :id="'newcard-cardnumber'" maxlength="19" minlength="19"
                        type="text">
                        <span :class="{'focus':(emptyblanckspace.cardNumber)}">{{state.lang.account.cartNumber}}</span>
                    </label>
                    <label @click="emptyblanckspace.shebaCode=true"  for="">
                        <input 
                        @input="newcard.shebaCode = newcard.shebaCode.replace(/[^\d.]|\.\./ig,'');error='';"
                        v-model="newcard.shebaCode"
                        class="name" 
                        :id="'newcard-shaba'"
                        maxlength="24"
                        :name="'newcard-shaba'"
                        type="text">
                        <span :class="{'focus':(emptyblanckspace.shebaCode)}">{{state.lang.account.shebaCode}}</span>
                    </label>
                    <label  for="">
                        <input 
                        class="name" 
                        v-model="newcard.bankName"
                        :name="'newcard-bankname'"
                        :id="'newcard-bankname'"
                        type="text"
                        readonly>
                        <span class="bankname focus">{{state.lang.account.bankName}}</span>
                    </label>
                </div>
                    <template v-for="(bankAccount,index) in data.bankAccounts"> 
                        <div class="info-input" v-if="bankAccount.cardNumber" :key="index">
                                <label @click="blanckspace.cartnumber=true"  for="">
                                    <input 
                                    disabled
                                    class="name" 
                                    @input="dash(index)"
                                    v-model="bankAccount.cardNumber"
                                    :name="'cardnumber'+index"
                                    :id="'cardnumber'+index" maxlength="19" minlength="19"
                                    type="text">
                                    <span class="bankname focus">{{state.lang.account.cartNumber}}</span>
                                </label>
                                <label @click="blanckspace.shabanumber=true"  for="">
                                    <input 
                                    @input="bankAccount.shebaCode = bankAccount.shebaCode.replace(/[^\d.]|\.\./ig,'');error='';"
                                    v-model="bankAccount.shebaCode"
                                    class="name" 
                                    disabled
                                    :id="'shaba'+index"
                                    maxlength="24"
                                    :name="'shaba'+index"
                                    type="text">
                                    <span class="bankname focus">{{state.lang.account.shebaCode}}</span>
                                </label>
                                <label  @click="blanckspace.bankname=true"  for="">
                                    <input 
                                    disabled
                                    class="name" 
                                    v-model="bankAccount.bankName"
                                    :name="'bankname'+index"
                                    :id="'bankname'+index"
                                    type="text"
                                    readonly>
                                    <span class="bankname" :class="{'focus':(blanckspace.bankname)}">{{state.lang.account.bankName}}</span>
                                </label>
                                <a v-if="data.bankAccounts.length>1" @click.prevent="removeaccount(index)" href="">{{state.lang.account.deletCard}}</a>
                                <!-- <a @click.prevent="" v-if="true" href="">ثبت</a> -->
                        </div>
                    </template>
    </div>
</template>

<script>
    const banks = {
            '603799': 'بانک ملی ایران',
            '589210': 'بانک سپه',
            '627648': 'بانک توسعه صادرات',
            '207177': 'بانک توسعه صادرات',
            '627353': 'بانک تجارت',
            '585983': 'بانک تجارت',
            '627961': 'بانک صنعت و معدن',
            '603770': 'بانک کشاورزی',
            '639217': 'بانک کشاورزی',
            '628023': 'بانک مسکن',
            '627760': 'پست بانک ایران',
            '502908': 'بانک توسعه تعاون',
            '627412': 'بانک اقتصاد نوین',
            '622106': 'بانک پارسیان',
            '639194': 'بانک پارسیان',
            '627884': 'بانک پارسیان',
            '502229': 'بانک پاسارگاد',
            '639347': 'بانک پاسارگاد',
            '639599': 'بانک قوامین',
            '504706': 'بانک شهر',
            '502806': 'بانک شهر',
            '603769': 'بانک صادرات',
            '610433': 'بانک ملت',
            '991975': 'بانک ملت',
            '589463': 'بانک رفاه',
            '502938': 'بانک دی',
            '639607': 'بانک سرمایه',
            '627381': 'بانک انصار',
            '505785': 'بانک ایران زمین',
            '636214': 'بانک آینده',
            '636949': 'بانک حکمت ایرانیان',
            '621986': 'بانک سامان',
            '639346': 'بانک سینا',
            '606373': 'بانک قرض الحسنه مهر',
            '627488': 'بانک کارآفرین',
            '502910': 'بانک کارآفرین',
            '505416': 'بانک گردشگری',
            '639370': 'بانک مهر اقتصاد',
            '628157': 'موسسه اعتباری توسعه',
            '505801': 'موسسه اعتباری کوثر',
        }
    export default {
        name:'Bankaccount',
         props: ['blanckspace','data', 'error', 'change'],
         data(){
             return{
                emptyblanckspace:{
                    shebaCode:false,
                    cardNumber:false,
                    bankName:false,
                },
                newcard:{
                    shebaCode: "",
                    cardNumber: "",
                    bankName: ""
                },
                newaccount:false
             }
         },
         methods :{
            addcard(){
                if(this.newcard.bankName=='کارت نامعتبر' ||this.newcard.bankName==''||this.newcard.shebaCode==''){
                    console.log('error');
                } else{
                    console.log(this.data.bankAccounts.length);
                    if(this.data.bankAccounts.length==0){
                        this.data.bankAccounts[0].shebaCode=this.newcard.shebaCode
                        this.data.bankAccounts[0].cardNumber=this.newcard.cardNumber
                        this.data.bankAccounts[0].bankName=this.newcard.bankName
                    }else{
                        this.data.bankAccounts = [
                            ...this.data.bankAccounts,
                            {
                                shebaCode: this.newcard.shebaCode,
                                cardNumber: this.newcard.cardNumber,
                                bankName: this.newcard.bankName,
                            }
                        ]
                    }
                }
                this.newcard.shebaCode=""
                this.newcard.cardNumber=""
                this.newcard.bankName="کارت نامعتبر"
                console.log(this.data.bankAccounts);
            },
             addaccount() {
                 if(this.newaccount==false){
                     this.newaccount=true
                 }else{
                     this.newaccount=true
                 }
            },
            removeaccount(e) {
                    this.data.bankAccounts.splice(e, 1)
            },
            dash(e) {
                    this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig,'').slice(0, 6) : ''] || 'کارت نامعتبر'
                this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
                this.error=''
                },
            dash0() {
                    this.newcard.bankName = banks[this.newcard.cardNumber ? this.newcard.cardNumber.replace(/-/ig,'').slice(0, 6) : ''] || 'کارت نامعتبر'
                this.newcard.cardNumber = this.$separator(this.newcard.cardNumber)
                this.error=''
                }
         },
         mounted (){
            //  this.data.bankAccounts.splice(0,1)
            // console.log(this.data.bankAccounts[0].cardNumber);
             for (let [index] of this.data.bankAccounts.entries()) {
                this.dash(index)
            }
         }
    }
</script>

<style lang="scss" scoped>
.span-en{
    span{
        right: unset !important;
        left: 5px !important;
        bottom: 3px !important;
    }
}
button{
    background: none;
    color: var(--secondary-color);
    align-self: center;
        font-weight: 400;
        font-size: clamp(9px,1vw,13px);
        cursor: pointer;
}
.bankname{
    background: linear-gradient(180deg,white 50%,rgba(242, 246, 255, 1) 60%) !important;
}
.bank-account{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border-bottom: 0.5px solid #0077cc88;
    padding-bottom: 16px;
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        a{
        align-self: center;
        font-weight: 400;
        font-size: clamp(9px,1vw,13px);
        &:hover{
            transform: none;
        }
    }

    }
}
    label{
        width: 30%;
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 7px;
        right: 5px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
    input,textarea{
    color: var(--secondary-color);
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #a4c6de9a;
    width: 100%;
    border-radius: 4px;
    resize: none;
    height: 100%;
}
}
.info-input{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a{
        align-self: center;
        font-weight: 400;
        font-size: clamp(9px,1vw,13px);
        &:hover{
            transform: none;
        }
    }
}
@media only screen and(max-width:550px){
    .info-input{
        flex-direction: column;
        row-gap: 5px;
        label{

            width:100%;
        }
    }
}
</style>