export function setPlaceholder() {
    for (let i = 0; i < this.num; i++) {
        this.$refs['code-input'].$refs['input_' + i][0].placeholder = '-------'
    }
}

export async function reset() {
    this.otp.code = ''
    for (let i = 0; i < this.num; i++) {
        this.$refs['code-input'].values[i] = ''
        this.$refs['code-input'].$refs['input_' + i][0].value = ''
    }
    this.$refs['code-input'].$refs['input_0'][0].focus()
}

export function paste(e) {
    if (e.clipboardData || window.clipboardData) {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        paste = this.$A2N(paste)
        for (let i = 0; i < this.num; i++) {
            this.$refs['code-input'].values[i] = paste[i] || ''
            this.$refs['code-input'].$refs['input_' + i][0].value = paste[i] || ''
        }
        this.otp.code = paste.slice(0, this.num)
        this.submit()
    }
}

export function Copy(e, text = 'کپی شد') {
    navigator.clipboard.writeText(e)
    alert(text)
}

export function tradeFrom() {
    return this.$route.params.tradeFrom
}

export function tradeTo() {
    return this.$route.params.tradeTo
}

export function precision() {
    return this.$route.params.tradeTo === 'TOMAN' ? 0 : 2
}

export function statusLabel() {
    const a = {
        FINISHED: 'تکمیل شده',
        IS_OPEN: 'باز',
        CANCELLED_BY_ADMIN: 'لغو توسط مدیر',
        CANCELLED_BY_USER: 'لغو توسط کاربر',
        EXPIRED: 'منضی شده',
        RAISED_BY_USER: 'درحال بررسی',
        PENDING: 'درحال انجام',
        ACCEPTED: 'موفق'
    }
    return e => a[e]
}

export function statusColor() {
    const a = {
        FINISHED: 'var(--main-green)',
        IS_OPEN: 'var(--main-green)',
        CANCELLED_BY_ADMIN: 'var(--main-red)',
        CANCELLED_BY_USER: 'var(--main-red)',
        EXPIRED: 'darkgoldenrod',
        RAISED_BY_USER: 'darkorange',
        PENDING: 'darkgoldenrod',
        ACCEPTED: 'var(--main-green)'
    }
    return e => a[e]
}

export function orderTypeLabel() {
    const a = {
        'BUY': 'خرید',
        'SELL': 'فروش',
        'LIMITED': 'حد',
        'MARKET': 'بازار'
    }
    return e => a[e.split('_')[0]] + ' - ' + a[e.split('_')[1]]
}

export function activeOrderTypeLabel() {
    const a = {
        'BUY': 'خرید',
        'SELL': 'فروش',
        'LIMITED': 'حد',
        'MARKET': 'بازار'
    }
    return e => a[e.split('_')[1]]
}

export class Loop {
    // callback
    // name
    // time
    // isRun = false
    // isAlive = false
    #sleep = (ms) => (new Promise(resolve => setTimeout(resolve, ms)))

    constructor(callback, time = 5000, name = 'specific') {
        this.callback = callback;
        this.time = time;
        this.name = name;
    }

    async run() {
        console.log(this.name + ' loop started')
        while (this.isRun) {
            this.isAlive = true
            await this.callback()
            await this.#sleep(this.time)
        }
        this.isAlive = false
        console.log(this.name + ' loop stopped')
    }

    //setter
    stop() {
        console.log(this.name + ' loop stop')
        this.isRun = false
    }

    start() {
        console.log(this.name + ' loop start')
        this.isRun = true
        if (!this.isAlive) {
            this.run()
        } else {
            console.log(this.name + ' loop is alive')
        }
    }

    //methods
}