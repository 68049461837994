<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
              <img @click="closeModale" class="close" src="../../assets/icons/icons8-close.svg" alt="">
              <div class="content">
                <h1> {{state.lang.exitModal.title}} </h1>
                <p> {{state.lang.exitModal.body}} </p>
                <div class="btns">
                    <button @click="Exit" class="submit"> {{state.lang.exitModal.yes}}  </button>
                    <button @click="closeModale"> {{state.lang.exitModal.no}}  </button>
                </div>
              </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Exitmodal',
  data() {
      return {
      }
  },
  props:{
      text:String,
      pic:String,
  },
  methods : {
      closeModale() {
        this.$emit('closeChargeModaleEmit' , false)
      },
      Exit(){
            localStorage.removeItem('loglevel:webpack-dev-server')
            localStorage.removeItem('token');
            this.$cookies.remove('username');
            this.$router.push({name: 'Homepage'});
            this.closeModale();
            location.reload();
      }
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.options,.modal,.submit, .email , .order-btn ,.option,.exit,.dropdown')) {
              this.$emit('closeChargeModaleEmit' , false);
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
h1{
    color: var(--secondary-color);
    font-weight: 700;
    font-size: clamp(20px,1vw,24px);
}
.btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .submit{
        width: 45%;
    }
    button{
      text-align: center !important;
        width: 45%;
        cursor: pointer;
    }
}
.content{
  width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: center;
    align-items: center;
    img{
        max-width: 59px;
        max-height: 43px;
    }
}
.close{
    cursor: pointer;
    align-self: flex-start;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 160px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 4px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>