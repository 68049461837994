<template>
    <div class="form-container">
        <Navbar />
        <div class="forms container">
            <Success v-if="close" @closeModaleEmit="closeModal" />
            <Signup v-if="$route.name == 'Signup'" />
            <Otpemail @closeModaleEmit="close=true" v-if="$route.name =='Otpemail'" />
            <Login v-if="$route.name =='Login'" />
            <Loginotpemail v-if="$route.name == 'Loginotpemail' || $route.name == 'Loginotpmobile' || $route.name == 'Loginotpgoogle'" :pathMatch="email" />
            <!-- <Loginotpmobile v-if="$route.name == 'Loginotpmobile'" :pathMatch="sms" />
            <Loginotpgoogle v-if="$route.name == 'Loginotpgoogle'" :pathMatch="google-auth" /> -->
            <Forgotpass v-if="$route.name == 'Forgotpass'" />
            <Forgotpassotp :pathMatch="email" v-if="$route.name == 'Forgotpassotpemail' || $route.name == 'Forgotpassotpsms' || $route.name == 'Forgotpassotpgoogleathenticator'" />
            <Forgotpassnew v-if="$route.name == 'Forgotpassnew'" />
            <div class="pics">
                <img v-if="$route.name == 'Login'" src="../../assets/forms/loginpic.svg" alt="">
                <img v-if="$route.name == 'Forgotpass' || $route.name == 'Forgotpassnew'" src="../../assets/forms/registerpic.svg" alt="">
                <img v-if="$route.name == 'Signup'" src="../../assets/forms/signuppic.svg" alt="">
                <img v-if="$route.name == 'Loginotpemail' || $route.name == 'Otpemail' || $route.name == 'Loginotpmobile' || $route.name == 'loginotpgoogle' || $route.name == 'Forgotpassotpemail' || $route.name == 'Forgotpassotpsms' || $route.name == 'Forgotpassotpgoogleathenticator' || $route.name == 'Loginotpgoogle'" src="../../assets/forms/undraw_authentication_fsn5.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import Signup from './Signup.vue';
import Otpemail from './Otpemail.vue';
import Login from './Login.vue';
import Success from './Success.vue';
import Loginotpemail from './Loginotpemail.vue';
// import Loginotpmobile from './Loginotpmobile.vue';
// import Loginotpgoogle from './Loginotpgoogle.vue';
import Forgotpass from './Forgotpass.vue';
import Forgotpassotp from './Forgotpassotp.vue';
import Forgotpassnew from './Forgotpassnew.vue';
import Navbar from '../public/Navbar.vue';
    export default {
        name : 'Forms',
        components : {
            Signup,
            Otpemail,
            Login,
            Success,
            Loginotpemail,
            // Loginotpmobile,
            // Loginotpgoogle,
            Forgotpass,
            Forgotpassotp,
            Forgotpassnew,
            Navbar,
        },
        data () {
            return {
                close : false ,
                email:''
            }
        },
        methods :{
            closeModal(){
                this.close=false;
                this.$router.push({name:'Dashboard'})
            }
        },
        mounted(){
            
        }
    }
</script>

<style lang="scss" scoped>
.pics{
    width: 50%;
    max-width: 577px;
    max-height: 377px;
}
.form-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.forms{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    // padding: 50px 0;
    align-items: center;
    justify-content: space-around;
    img{
        width: 100%;
    }
}

@media only screen and(max-width:900px){

    .forms{
        flex-direction: column;
        row-gap: 24px;
    }

}


</style>