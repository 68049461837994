<template>
    <div class="container"> 
        <div class="background">    
            <div class="steps">
                    <div class="text-container startparent">
                        <div class=" start">
                            <h3> {{state.lang.steps.title}} </h3>
                        </div>
                        <div :dir="state.lan=='Fa'?'rtl':'ltr'" class="signup">
                            <h6>{{state.lang.steps.signup}} </h6>
                            <p>{{state.lang.steps.signupBody}} </p>
                        </div>
                    </div>
                    <div :class="{'align-start':state.lan=='En'}" class="text-container validation">
                        <h6>{{state.lang.steps.validation}} </h6>
                        <p>{{state.lang.steps.validationBody}} </p>
                    </div>
                    <div :class="{'align-end':state.lan=='En'}" class="text-container charge">
                        <h6>{{state.lang.steps.wallet}} </h6>
                        <p>{{state.lang.steps.walletBody}} </p>
                    </div>
                    <div :class="{'align-start':state.lan=='En'}" class="text-container trading">
                        <h6>{{state.lang.steps.start}} </h6>
                        <p>{{state.lang.steps.startBody}} </p>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Steps',
        
    }
</script>

<style lang="scss" scoped>
.align-end{
    align-self: flex-end !important;
}
.align-start{
    align-self: flex-start !important;
}
.startparent{
    display: flex;
       direction: ltr;
       min-height: 200px;
       width: 100% !important;
       column-gap: 20px;
       justify-content: flex-end;

}
.start{
    height: 94px;
    margin-top: -20px;
    padding: 25px 100px; ;
    border: 2px dashed #FFD43D;
box-sizing: border-box;
border-radius: 4px;
text-align: center;
align-self: flex-start;

}
.signup{
    background: #FFFFFF;
box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    box-sizing: border-box;
    padding: 27px;
    height: 135px;
border-radius: 4px;
align-self: flex-end;
width: 52%;
height: 135px;
}
.validation{
        background: #F2F6FF;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
        // height: 135px;
    align-self: flex-end;
        box-sizing: border-box;
    padding: 27px;
}
.steps{
    min-height: 866px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.charge{
    width: 50%;
        height: 135px;
    background: #0077CC;
box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
border-radius: 4px;
color: var(--primary-color);
    box-sizing: border-box;
    padding: 27px;
}
.trading{
        align-self: flex-end;
            height: 145px;
    background: #FFD43D;
box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
border-radius: 4px;
    box-sizing: border-box;
    padding: 27px;
}
.background{
    background: url('../../assets/steps/Vector5.png');
    background-position: bottom;
    background-repeat: no-repeat;
    width: 90%;
}
p{
    font-weight: 500;
    font-size: clamp(12px,1vw,15px);
}
h6{
    font-weight: 700;
    font-size: clamp(14px,1vw,18px);
}
h3{
    font-weight: 700;
    font-size: clamp(24px,1vw,36px);
}
  .text-container{
    box-sizing: border-box;
    text-align: start;
        width: 50% ;
  }
  .container{
      padding: 100px 0px;
      justify-content: center;
  }
.steps-container{
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width:1200px){
    .container{
        padding: 100px 90px;
    }


}
@media only screen and (max-width:1000px){

    .background{
               background: none;
       background-repeat:no-repeat ;
       position: unset;
    }
    .text-container{
        width: 100%;
    }
    .startparent{
        flex-direction: column;
        row-gap: 20px;
        .signup{
            width: 100%;
        }
        .start{
            width: 100%;
            padding: 25px 0px;
        }
    }
    }
@media only screen and (max-width:400px){

    .container{
        padding:50px 50px ;
    }
}

</style>