<template>
    <div class="sidebar-size"> 
            <div class="sidebar-container">   
                <div class="sidebar-item">    
                    <div @click.prevent="reDirect(item.routername)" :class="{'active' : $route.name == item.routername}" class="item-container" v-for="(item,index) in sidebar" :key="index" >
                        <img @click.prevent="reDirect(item.routername)" :src=item.img alt="">
                        <a @click.prevent="reDirect(item.routername)" :href="item.addres">{{item.title}}</a>
                    </div>
                </div>
                <div @click.prevent="Exit" class="exit">
                        <img src="../../assets/icons/exit.svg" alt="">
                        <a @click.prevent="" href="">{{state.lang.sidebar.exit}}</a>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name : 'Sidebar',
        data () {
            return{
            sidebar : [
                {
                    img : require('../../assets/icons/dashboard.svg'),
                    title : this.state.lang.sidebar.dashboard,
                    addres: '/Dashboard',
                    routername: 'Dashboard',
                },
                {
                    img : require('../../assets/icons/activeorders.svg'),
                    title : this.state.lang.sidebar.activeOrders,
                    addres: '/dashboard/Activeorders',
                    routername: 'Activeorders',
                },
                {
                    img : require('../../assets/icons/account.svg'),
                    title : this.state.lang.sidebar.account,
                    addres: '/dashboard/Account',
                    routername: 'Account',
                },
                {
                    img : require('../../assets/icons/wallet.svg'),
                    title : this.state.lang.sidebar.wallet,
                    addres: '/Dashboard/Wallet',
                    routername: 'Wallet',
                },
                {
                    img : require('../../assets/icons/exchangewallet.svg'),
                    title : this.state.lang.sidebar.exchangeWallet,
                    addres: '/Dashboard/exchangeWallet',
                    routername: 'ExchangeWallet',
                },
                {
                    img : require('../../assets/icons/history.svg'),
                    title : this.state.lang.sidebar.history,
                    addres: '/Dashboard/History',
                    routername: 'History',
                },
                {
                    img : require('../../assets/icons//setting.svg'),
                    title : this.state.lang.sidebar.setting,
                    addres: '/Dashboard/Setting',
                    routername: 'Setting',
                },
                {
                    img : require('../../assets/icons/notification.svg'),
                    title : this.state.lang.sidebar.notifications,
                    addres: '/Dashboard/Notifications',
                    routername: 'Notifications',
                },
                {
                    img : require('../../assets/icons/invite-friends.svg'),
                    title : this.state.lang.sidebar.inviteFriends,
                    addres: '/Dashboard/Invitefriends',
                    routername: 'Invitefriends',
                },
                {
                    img : require('../../assets/icons/ticket.svg'),
                    title : this.state.lang.sidebar.tickets,
                    addres: '/Dashboard/Tickets',
                    routername: 'Tickets',
                },
            ]
            }
        },
        methods : {
            Exit(){
                this.$emit('close')
                
            },
            reDirect(addres){
                this.$router.push({name: addres})
            }
        }
    }
</script>

<style lang="scss" scoped>
.active{
    background: var(--secondary-color);
    img{
        background: white;
    }
    a{
        font-size: clamp(14px,2vw,18px);
        color: white;
        max-height: 48px;
    }
}
    img{
        background: var(--primary-color);
        box-sizing: border-box;
        padding: 2px;
        border-radius: 4px;
        width: 25px;
        height: 24px;
    }
.sidebar-size{

    height: 100%;
    width: 15%;
    position: relative;
    }
.sidebar-container{
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    width: 180px;
    height: 98%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
}
a{
    font-weight: 400;
    font-size: clamp(10px,1.5vw,14px);
    &:hover{
        transform: none;
    }
}
.sidebar-item{
    display: flex;
    flex-direction: column;
}
.exit{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    padding: 15px 19px;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    a{
        margin-top: 4px;
        text-align: center;
        color: rgba(4, 24, 54, 1);
    }
}
.item-container{
    box-sizing: border-box;
    padding: 15px 16px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // justify-content: center;
    cursor: pointer;

}
@media only screen and(max-width:1100px){
    .exit{
        justify-content: center;
    }
    .item-container{
        justify-content: center;
    }
    .sidebar-container{
        width: 45px;
    }
    .sidebar-size{
        width: 55px;
    }
    a{
            display:  none;
    
    }
    .active{

        a{
            font-size: clamp(10px,1vw,14px);
    
        }
    }
    
}
</style>