import { render, staticRenderFns } from "./Mainpage.vue?vue&type=template&id=29e55cfc&scoped=true&"
import script from "./Mainpage.vue?vue&type=script&lang=js&"
export * from "./Mainpage.vue?vue&type=script&lang=js&"
import style0 from "./Mainpage.vue?vue&type=style&index=0&id=29e55cfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e55cfc",
  null
  
)

export default component.exports