<template>
    <div class="signup">
        <h1>{{state.lang.signUp.title}}</h1>
        <div class="forms">
            <form @submit.prevent="send"  action="">
                <label for="">
                    <input @focus="toggleFocus" v-model="data.email" @input="data.email=$p2e(data.email)" class="email" name="email" type="text" required>
                    <span :class="[{'focus':(blanckspace.email)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.signUp.userName}}</span>
                </label>
                <div v-if="emailError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{emailError}}</p>
                </div>
                <label :class="[state.lan=='Fa' ? 'Vuepass-fa' : 'Vuepass-en' ]" @click="blanckspace.password = true" class="passlabel" for="">
                    <VuePassword
                            v-model="data.password"
                            id="password1"
                            @input="updateStrength(data.password)"
                            :strength="strength"
                            :type="passtype"
                        />
                    <!-- <input @focus="blanckspace.password = true" v-model="data.password" @input="data.password=$p2e(data.password)" :type="passtype" class="password" name="password"> -->
                    <span :class="[state.lan=='Fa' ? 'pass-span-fa' : 'pass-span-en',{'focus':(blanckspace.password)}]"> {{state.lang.signUp.passWord}} </span>
                    <!-- <img class="pass-span" @click="togglevisibality" src="../../assets/icons/seepassword.svg" alt=""> -->
                    <!-- <img class="pass-span" v-if="passtype=='text'" @click="passtype='password'"  src="../../assets/icons/seepassword.svg" alt=""> -->
                </label>
                <div v-if="passError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{passError}}</p>
                </div>
                <label for="">
                    <input @focus="blanckspace.invitecode = true" v-model="data.referralCode" @input="data.referralCode=$p2e(data.referralCode)"  class="invitecode" name="invitecode" type="text"> 
                    <span :class="[{'focus':(blanckspace.invitecode)},state.lan=='En'?'span-en':'span-fa']">{{state.lang.signUp.inviteCode}}</span>
                </label>
                <div class="checkbox-container">
                    <span @click="toggleCheck" :class="{'checked': termscheck}" class="checkbox" name="terms"></span>
                         <label for="terms">  {{state.lang.signUp.acceptTerms}}  </label>
                </div>
                <!-- <div class="recaptcha">
                     <img src="../../assets/forms/captcha-code2 1.png" alt="">
                </div> -->
                <!-- <label class="passlabel" for="">
                    <input @focus="blanckspace.recaptcha = true" v-model="data.recaptcha" type="text" name="recaptcha">
                    <span :class="{'focus':(blanckspace.recaptcha)}"> کد امنیتی </span>
                    <img src="../../assets/icons/iconoir_refresh.svg" alt="">
                </label> -->
                <button :disabled="!data.email || !data.password || !termscheck" class="submit">{{state.lang.signUp.signUpBtn}}</button>
                <button @click.prevent="check" class="googlelink">{{state.lang.signUp.googleBtn}}</button>
                <p class="loginpage">  {{state.lang.signUp.loginTitle}}  <a href="/login"> {{state.lang.signUp.loginLink}}</a>  </p>
                <a @click.prevent="$router.push({name:'Faq'})" href="/faq"> {{state.lang.signUp.Help}} </a>
            </form>
        </div>
    </div>
</template>

<script>
import VuePassword from 'vue-password';
    export default {    
        name : 'Signup',
        components:{
            VuePassword,
        },
        data () {
            return{
                passtype : 'password' ,
                strength:0,
                termscheck : false ,
                unfocus : true,
                blanckspace : {
                    email : false,
                    password : false,
                    invitecode : false,
                    recaptcha : false
                },
                data: {
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    referralCode: ''
                },
                emailError: '',
                passError: ''

            }
        },
        methods : {
            togglevisibality(){
                if(this.passtype=='password'){
                    this.passtype='text'
                }else{
                    this.passtype='password'
                }
            },
            toggleCheck () {
                this.termscheck = !this.termscheck
            },
            handleSubmit() {
                this.$router.push({ path: 'Otp-email' })
            },
            toggleFocus () {
                this.blanckspace.email=true ;
                this.unfocus=true;
            },
             async send() {
                await this.checkEmail()
                await this.check2Pass()
                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        await this.$router.push({ path: 'Otp-email' })
                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push({ name: 'Validation' })
                }
            },
             async check() {
                this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/get-google-token?browser=${this.data.browser}&osType=${this.data.osType}&deviceType=${this.data.deviceType}&code=${this.$route.query.code}`)
                    this.$setToken(res.token)
                    await this.$router.push({name:'Dashboard'})
                } catch (err) {
                    if (err.response.data.message === "GOOGLE_FAILURE") {
                        await this.$error('خطای ورود، به صفحه قبل منتقل میشوید', '')
                        this.$router.push({name: 'Login'})

                    }
                }
            },
            async check2Pass() {
                if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                    this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                    // this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    return Promise.reject('wrong pass : ' + this.data.password)
                }else{
                    this.passError = ''
                }
            },
            async checkEmail() {
                if (!this.$validEmail(this.data.email) || !this.data.email) {
                    this.emailError = 'ایمیل صحیح وارد نمایید'
                    // this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    return Promise.reject('repeat failed : ' + this.data.email)
                }else{
                    this.emailError = ''
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            updateStrength(pass){
                if (pass.match(/[a-z]+/) || pass.match(/[0-9]+/) || pass.match(/[A-Z]+/)){
                    this.strength=1;
                    console.log(this.passtype);
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[0-9]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/))){
                    this.strength=2;
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/))){
                    this.strength=3;
                }
                if (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)){
                    this.strength=4

                    }
                    if(pass==''){
                        this.strength=0
                    }
            }
        },
        mounted() {
            this.getData()
            if(this.$route.query.ref || localStorage.referralCode){
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        },
    }
</script>

<style lang="scss" scoped>
.span-en{
    bottom: 3px !important;
}
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
.loginpage{
    text-align: center;
    font-weight: 400;
    font-size: clamp(9px,1vw,13px);
        a{
                font-size: clamp(10px,1.5vw,12px);
    }
}
.googlelink{
    background: var(--primary-color);
    color: var(--secondary-color);
    border: 0.2px solid #0077cc59;
    font-weight: 700;
    font-size: clamp(10px,1vw,14px);
    cursor: pointer;
}
label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 5px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
.signup{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 20px;
    // max-width: 336px;
    row-gap: 24px;
    width: 30%;
    height: 90%;
}

.checkbox-container{
    display: flex;
    justify-content: row;
    column-gap: 10px;
    .checked{
        background: var(--secondary-color);
    }
    span{
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius:4px ;
    }
    label{
        width: 100%;
        font-weight: 400;
        font-size: clamp(10px,1vw,13px);
    }

}
.recaptcha{
    display: flex;
    justify-content: center;
    align-items: center;
}
.forms{

    form{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

}
.passlabel{
    img{
        position: absolute;
        bottom: 10px;
        left: 15px;
        cursor: pointer;
        max-height: 16px;
        max-width: 16px;
        width: 100%;
        height: 100%;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
@media only screen and(max-width:900px){

    .signup{
        width: 40%;
        
    }

}
@media only screen and(max-width:700px){
    

    .signup{
        width: 60%;
        
    }

}
@media only screen and(max-width:450px){

    .signup{
        width: 50%;
        width: 80%;
        
    }

}
    </style>